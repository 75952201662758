import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CatlogLayout from "../layouts/CatlogLayout";
import Connection from "../views/Catalog/Connection";
import CreateConnection from "../views/Catalog/Connection/Component/CreateConnection";
import SelectExisting from "../views/Catalog/Connection/Component/SelectExisting";
import SelectTable from "../views/Catalog/SelectTable";
import PrepareMetadata from "../views/Catalog/PrepareMetadata";
import SaveCatalog from "../views/Catalog/SaveCatalog";
import MainLayout from "../layouts/MainLayout";
import QuestionBoard from "../views/QuestionBoard";
import AnswerBoard from "../views/AnswerBoard";
import Setting from "../views/Setting";
import FAQ from "../views/FAQ";
import Logout from "../views/Authentication/Logout/Logout";
import Login from "../views/Authentication/Login";
import CallbackHandler from "../views/Authentication/CallbackHandler";
import AdminRoute from "./AdminRoute";
import SingleAnswerBoard from "../views/AnswerBoard/SingleAnswerBoard";
import ProtectedRoute from "./ProtectedRoute";
import UserRoute from "./UserRoute";
import UpdateSingleAnswerBoard from "../views/AnswerBoard/UpdateSingleAnswerBoard";
import CatalogManagement from "../views/CatalogManagement";
import DashboardManagement from "../views/DashboardManagement";
import UserManagement from "../views/UserManagement";
import SuperAdminRoute from "./SuperAdminRoute";

const RouterComponent = () => {
  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route element={<UserRoute />}>
            <Route element={<MainLayout />}>
              <Route path="/answer-board" element={<AnswerBoard />} />
              <Route path="/answer-board/:id" element={<SingleAnswerBoard />} />
            </Route>
          </Route>
          <Route element={<AdminRoute />}>
            <Route element={<MainLayout />}>
              <Route index element={<QuestionBoard />} />
              <Route path="/setting" element={<Setting />} />
              <Route path="/faq-page" element={<FAQ />} />
              <Route path="/answer-board" element={<AnswerBoard />} />
              <Route path="/answer-board/:id" element={<SingleAnswerBoard />} />
              <Route path="/update-single-answer-board/:id" element={<UpdateSingleAnswerBoard />} />
            </Route>
          </Route>
          <Route element={<SuperAdminRoute />}>
            <Route element={<MainLayout />}>
              <Route index element={<QuestionBoard />} />
              <Route path="/answer-board/:id" element={<SingleAnswerBoard />} />
              <Route path="/answer-board" element={<AnswerBoard />} />
              <Route path="/catalog-management" element={<CatalogManagement/>} />
              <Route path="/dashboard-management" element={<DashboardManagement/>} />
              <Route path="/user-management" element={<UserManagement/>} />
              <Route path="/setting" element={<Setting />} />
              <Route path="/faq-page" element={<FAQ />} />
              <Route path="/update-single-answer-board/:id" element={<UpdateSingleAnswerBoard />} />
            </Route>
            <Route path="/catalog" element={<CatlogLayout />}>
              <Route path="/catalog/connection" element={<Connection />}>
                <Route
                  path="/catalog/connection/create-connection"
                  element={<CreateConnection />}
                />
                <Route
                  path="/catalog/connection/select-existing"
                  element={<SelectExisting />}
                />
              </Route>
              <Route path="/catalog/select-table" element={<SelectTable />} />
              <Route
                path="/catalog/prepare-metadata"
                element={<PrepareMetadata />}
              />
              <Route path="/catalog/save" element={<SaveCatalog />} />
            </Route>
          </Route>
        </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/callback" element={<CallbackHandler />} />
      </Routes>
    </Router>
  );
};

export default RouterComponent;
