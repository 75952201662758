import {
  Autocomplete,
  Box,
  Icon,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ColorPicker from "../../utils/ColorPicker";
import IconMenu from "../../utils/icons";
import { useSelector, useDispatch } from "react-redux";
import { setUpdateObjectData, setWidget } from "../../redux/actions";
import { selectUpdateObjectData, selectWidget } from "../../redux/selectors";

const WidgetProperties = ({ update }) => {
  const dispatch = useDispatch();
  const UpdateObjectData = useSelector(selectUpdateObjectData);
  const [iconMenuAnchorEl, setIconMenuAnchorEl] = useState(null);
  const [iconColorPickerAnchorEl, setIconColorPickerAnchorEl] = useState(null);
  const [backgroundColorPickerAnchorEl, setBackgroundColorPickerAnchorEl] =
    useState(null);
  const widgetData = useSelector(selectWidget);
  const openIconMenuAnchorEl = Boolean(iconMenuAnchorEl);
  const openIconColorPickerAnchorEl = Boolean(iconColorPickerAnchorEl);
  const openBackgroundColorPickerAnchorEl = Boolean(
    backgroundColorPickerAnchorEl
  );
  const handleIconMenuClick = (event) => {
    setIconMenuAnchorEl(event.currentTarget);
  };
  const handleIconMenuClose = () => {
    setIconMenuAnchorEl(null);
  };
  const handleColorPickerClick = (colorType) => (event) => {
    if (colorType === "icon") {
      setIconColorPickerAnchorEl(event.currentTarget);
    } else if (colorType === "background") {
      setBackgroundColorPickerAnchorEl(event.currentTarget);
    }
  };
  const handleColorPickerClose = (colorType) => () => {
    if (colorType === "icon") {
      setIconColorPickerAnchorEl(null);
    } else if (colorType === "background") {
      setBackgroundColorPickerAnchorEl(null);
    }
  };
  const handleColorChange = (colorType) => (newColor) => {
    dispatch(
      update
        ? colorType === "icon"
          ? setUpdateObjectData({
              ObjectData: {
                ...UpdateObjectData.ObjectData,
                data: {
                  ...UpdateObjectData?.ObjectData.data,
                  result: {
                    ...UpdateObjectData?.ObjectData.data?.result,
                    widgetProp: {
                      ...UpdateObjectData?.ObjectData.data?.result?.widgetProp,
                      iconColor: newColor.hex,
                    },
                  },
                },
              },
            })
          : setUpdateObjectData({
              ObjectData: {
                ...UpdateObjectData.ObjectData,
                data: {
                  ...UpdateObjectData?.ObjectData.data,
                  result: {
                    ...UpdateObjectData?.ObjectData.data?.result,
                    widgetProp: {
                      ...UpdateObjectData?.ObjectData.data?.result?.widgetProp,
                      iconBackgroundColor: newColor.hex,
                    },
                  },
                },
              },
            })
        : colorType === "icon"
        ? setWidget({
            ...widgetData,
            iconColor: newColor.hex,
          })
        : setWidget({
            ...widgetData,
            iconBackgroundColor: newColor.hex,
          })
    );
  };
  const preFixOptions = [
    { label: "Dollar Symbol", value: "$ " },
    { label: "Rupee Symbol", value: "₹ " },
    { label: " Euro Symbol", value: "€ " },
    { label: "GBP Symbol", value: "£ " },
  ];
  const postFixOptions = [{ label: "Percent", value: " %" }];
  const DecimalPlaces = [
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
  ];
  const PercentageOption = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  return (
    <Box
      sx={{
        p: 1,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        gap: "20px",
      }}
    >
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        <Typography>Icon</Typography>
        <Menu
          anchorEl={iconMenuAnchorEl}
          open={openIconMenuAnchorEl}
          onClose={handleIconMenuClose}
          sx={{ height: "50%" }}
        >
          <IconMenu update={update} />
        </Menu>
        <Icon
          onClick={handleIconMenuClick}
          baseClassName="fas"
          className="fa-plus-circle"
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography>Icon Color</Typography>
        <Menu
          anchorEl={iconColorPickerAnchorEl}
          open={openIconColorPickerAnchorEl}
          onClose={handleColorPickerClose("icon")}
        >
          <MenuItem>
            <ColorPicker
              color={
                update
                  ? UpdateObjectData?.ObjectData?.data.result.widgetProp
                      .iconColor
                  : widgetData.iconColor
              }
              handleColorChange={handleColorChange("icon")}
            />
          </MenuItem>
        </Menu>
        <Box
          sx={{
            width: 20,
            height: 20,
            backgroundColor: update
              ? UpdateObjectData?.ObjectData?.data.result.widgetProp.iconColor
              : widgetData.iconColor,
          }}
          onClick={handleColorPickerClick("icon")}
        ></Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography>Background Color</Typography>
        <Menu
          anchorEl={backgroundColorPickerAnchorEl}
          open={openBackgroundColorPickerAnchorEl}
          onClose={handleColorPickerClose("background")}
        >
          <MenuItem>
            <ColorPicker
              color={
                update
                  ? UpdateObjectData?.ObjectData?.data.result.widgetProp
                      .iconBackgroundColor
                  : widgetData.iconBackgroundColor
              }
              handleColorChange={handleColorChange("background")}
            />
          </MenuItem>
        </Menu>
        <Box
          sx={{
            width: 20,
            height: 20,
            backgroundColor: update
              ? UpdateObjectData?.ObjectData?.data.result.widgetProp
                  .iconBackgroundColor
              : widgetData.iconBackgroundColor,
          }}
          onClick={handleColorPickerClick("background")}
        ></Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography>Prefix</Typography>
        <Autocomplete
          size="small"
          options={preFixOptions}
          value={
            update
              ? preFixOptions.find(
                  (option) =>
                    option.value ===
                    UpdateObjectData?.ObjectData?.data.result.widgetProp.preFix
                ) || null
              : preFixOptions.find(
                  (option) => option.value === widgetData.preFix
                ) || null
          }
          getOptionLabel={(option) => option.value}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select Value" />
          )}
          onChange={(event, newValue) => {
            if (newValue) {
              update
                ? dispatch(
                    setUpdateObjectData({
                      ObjectData: {
                        ...UpdateObjectData.ObjectData,
                        data: {
                          ...UpdateObjectData?.ObjectData.data,
                          result: {
                            ...UpdateObjectData?.ObjectData.data?.result,
                            widgetProp: {
                              ...UpdateObjectData?.ObjectData.data?.result
                                ?.widgetProp,
                              preFix: newValue.value,
                            },
                          },
                        },
                      },
                    })
                  )
                : dispatch(
                    setWidget({
                      ...widgetData,
                      preFix: newValue.value,
                    })
                  );
            } else {
              update
                ? dispatch(
                    setUpdateObjectData({
                      ObjectData: {
                        ...UpdateObjectData.ObjectData,
                        data: {
                          ...UpdateObjectData?.ObjectData.data,
                          result: {
                            ...UpdateObjectData?.ObjectData.data?.result,
                            widgetProp: {
                              ...UpdateObjectData?.ObjectData.data?.result
                                ?.widgetProp,
                              preFix: null,
                            },
                          },
                        },
                      },
                    })
                  )
                : dispatch(
                    setWidget({
                      ...widgetData,
                      preFix: null,
                    })
                  );
            }
          }}
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography>Postfix</Typography>
        <Autocomplete
          size="small"
          disabled={
            update
              ? UpdateObjectData?.ObjectData.data?.result.percentage === "yes"
              : widgetData.percentage === "yes"
          }
          options={postFixOptions}
          value={
            update
              ? postFixOptions.find(
                  (option) =>
                    option.value ===
                    UpdateObjectData?.ObjectData?.data.result.widgetProp.postFix
                ) || null
              : postFixOptions.find(
                  (option) => option.value === widgetData.postFix
                ) || null
          }
          getOptionLabel={(option) => option.value}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select Value" />
          )}
          onChange={(event, newValue) => {
            if (newValue) {
              update
                ? dispatch(
                    setUpdateObjectData({
                      ObjectData: {
                        ...UpdateObjectData.ObjectData,
                        data: {
                          ...UpdateObjectData?.ObjectData.data,
                          result: {
                            ...UpdateObjectData?.ObjectData.data?.result,
                            widgetProp: {
                              ...UpdateObjectData?.ObjectData.data?.result
                                ?.widgetProp,
                              postFix: newValue.value,
                            },
                          },
                        },
                      },
                    })
                  )
                : dispatch(
                    setWidget({
                      ...widgetData,
                      postFix: newValue.value,
                    })
                  );
            } else {
              update
                ? dispatch(
                    setUpdateObjectData({
                      ObjectData: {
                        ...UpdateObjectData.ObjectData,
                        data: {
                          ...UpdateObjectData?.ObjectData.data,
                          result: {
                            ...UpdateObjectData?.ObjectData.data?.result,
                            widgetProp: {
                              ...UpdateObjectData?.ObjectData.data?.result
                                ?.widgetProp,
                              postFix: null,
                            },
                          },
                        },
                      },
                    })
                  )
                : dispatch(
                    setWidget({
                      ...widgetData,
                      postFix: null,
                    })
                  );
            }
          }}
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography>Decimal places</Typography>
        <Autocomplete
          size="small"
          options={DecimalPlaces}
          getOptionLabel={(option) => option.value}
          value={
            update
              ? DecimalPlaces.find(
                  (option) =>
                    option.value ===
                    UpdateObjectData?.ObjectData?.data.result.widgetProp
                      .decimalPlaces
                ) || null
              : DecimalPlaces.find(
                  (option) => option.value === widgetData.decimalPlaces
                ) || null
          }
          renderInput={(params) => (
            <TextField {...params} placeholder="Select Value" />
          )}
          onChange={(event, newValue) => {
            if (newValue) {
              update
                ? dispatch(
                    setUpdateObjectData({
                      ObjectData: {
                        ...UpdateObjectData.ObjectData,
                        data: {
                          ...UpdateObjectData?.ObjectData.data,
                          result: {
                            ...UpdateObjectData?.ObjectData.data?.result,
                            widgetProp: {
                              ...UpdateObjectData?.ObjectData.data?.result
                                ?.widgetProp,
                              decimalPlaces: newValue.value,
                            },
                          },
                        },
                      },
                    })
                  )
                : dispatch(
                    setWidget({
                      ...widgetData,
                      decimalPlaces: newValue.value,
                    })
                  );
            } else {
              update
                ? dispatch(
                    setUpdateObjectData({
                      ObjectData: {
                        ...UpdateObjectData.ObjectData,
                        data: {
                          ...UpdateObjectData?.ObjectData.data,
                          result: {
                            ...UpdateObjectData?.ObjectData.data?.result,
                            widgetProp: {
                              ...UpdateObjectData?.ObjectData.data?.result
                                ?.widgetProp,
                              decimalPlaces: null,
                            },
                          },
                        },
                      },
                    })
                  )
                : dispatch(
                    setWidget({
                      ...widgetData,
                      decimalPlaces: null,
                    })
                  );
            }
          }}
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography>Percentage</Typography>
        <Autocomplete
          size="small"
          options={PercentageOption}
          getOptionLabel={(option) => option.value}
          value={
            update
              ? PercentageOption.find(
                  (option) =>
                    option.value ===
                    UpdateObjectData?.ObjectData?.data.result.widgetProp
                      .percentage
                ) || null
              : PercentageOption.find(
                  (option) => option.value === widgetData.percentage
                ) || null
          }
          renderInput={(params) => (
            <TextField {...params} placeholder="Select Value" />
          )}
          onChange={(event, newValue) => {
            if (newValue) {
              update
                ? dispatch(
                    setUpdateObjectData({
                      ObjectData: {
                        ...UpdateObjectData.ObjectData,
                        data: {
                          ...UpdateObjectData?.ObjectData.data,
                          result: {
                            ...UpdateObjectData?.ObjectData.data?.result,
                            widgetProp: {
                              ...UpdateObjectData?.ObjectData.data?.result
                                ?.widgetProp,
                              percentage: newValue.value,
                              postFix: null,
                            },
                          },
                        },
                      },
                    })
                  )
                : dispatch(
                    setWidget({
                      ...widgetData,
                      percentage: newValue.value,
                      postFix: null,
                    })
                  );
            } else {
              update
                ? dispatch(
                    setUpdateObjectData({
                      ObjectData: {
                        ...UpdateObjectData.ObjectData,
                        data: {
                          ...UpdateObjectData?.ObjectData.data,
                          result: {
                            ...UpdateObjectData?.ObjectData.data?.result,
                            widgetProp: {
                              ...UpdateObjectData?.ObjectData.data?.result
                                ?.widgetProp,
                              percentage: null,
                            },
                          },
                        },
                      },
                    })
                  )
                : dispatch(
                    setWidget({
                      ...widgetData,
                      percentage: null,
                    })
                  );
            }
          }}
        />
      </Box>
    </Box>
  );
};
export default WidgetProperties;
