import React, { useEffect, useState } from "react";
// import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import { Box, Hidden, IconButton, Tooltip, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { selectSideBarOpen } from "../../../redux/selectors";
import { setSideBarOpen } from "../../../redux/actions";
const Header = () => {
  const sideBarOpen = useSelector(selectSideBarOpen);
  const dispatch = useDispatch();
  const [title, setTitle] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const pathRegex = /^\/answer-board\/[a-zA-Z0-9]+$/;
  useEffect(() => {
    if (location.pathname === "/") {
      setTitle("Ask Business Question");
    }
    if (location.pathname === "/answer-board") {
      setTitle("Answer Board ");
    }
    if (location.pathname === "/setting") {
      setTitle("Setting");
    }
    if (location.pathname === "/faq-page") {
      setTitle("FAQ");
    }
    if (location.pathname === "/catalog-management") {
      setTitle("Catalog Management");
    }
    if (location.pathname === "/dashboard-management") {
      setTitle("Dashboard Management");
    }
    if (location.pathname === "/user-management") {
      setTitle("User Management");
    }
    if (pathRegex.test(location.pathname)) {
      setTitle("DASHBOARD");
    }
  }, [location]);
  const handleMenuToggle = () => {
    dispatch(setSideBarOpen(!sideBarOpen));
  };
  return (
    <div>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          // left: "0",
          zIndex: "9999999",
          width: "100%",
          backgroundColor: "#0C247B",
          display: "flex",
          height: "64px",
          px: 3,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* <Typography
          sx={{
            fontSize: { xs: "20px", md: "30px", color: "#fff" },
            fontWeight: "300",
          }}
        >
          {title}
        </Typography> */}
        <img
          style={{
            height: "80%",
          }}
          src={process.env.PUBLIC_URL + "/assets/images/Qnai-logo-png.png"}
          alt=""
        />
        <Box sx={{ display: "flex", gap: "10px" }}>
          {/* <Box sx={{ p: 1, color: "white" }}>
            <NotificationsNoneOutlinedIcon fontSize="medium" />
          </Box> */}
          <Box sx={{ p: 1, color: "white", cursor: "pointer" }}>
            <Tooltip
              title="Logout"
              sx={{ position: "absolute", zIndex: "99999" }}
            >
              <PowerSettingsNewOutlinedIcon
                onClick={() => {
                  navigate("/logout");
                }}
                fontSize="medium"
                sx={{mt:1.2}}
              />
            </Tooltip>
          </Box>
          <Hidden mdUp>
            <Box
              sx={{
                // backgroundColor: "#FFFAF1",
                p: 1,
                color: "white",
                display: { xs: "block", md: "none" },
              }}
            >
              {sideBarOpen ? (
                <CloseIcon fontSize="medium" onClick={handleMenuToggle} />
              ) : (
                <MenuIcon fontSize="medium" onClick={handleMenuToggle} />
              )}
            </Box>
          </Hidden>
        </Box>
      </Box>
    </div>
  );
};
export default Header;
