import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { updateDashboardRole } from "../../services/PermissionModule";
import { enqueueSnackbar } from "notistack";
import { getAllBoards } from "../../services";
import { setAllBoards } from "../../redux/actions";
import { useDispatch } from "react-redux";

const UserManagementButton = ({
  idpRole,
  setSelectedRole,
  selectedRole,
  role,
  board,
  email,
  dashboardUserRole,
}) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [roles, setRoles] = useState([
    "viewer",
    "editor",
    "co-creator",
    "creator",
  ]);
  console.log(board, "board");

  const currentUserIdpRole = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))?.roles[0]
    : "";
  useEffect(() => {
    if (currentUserIdpRole === "Super Admin") {
      if (idpRole === "admin") {
        setRoles(["viewer", "editor", "co-creator", "creator"]);
      } else if (idpRole === "user") {
        setRoles(["viewer"]);
      } else {
        setRoles(["creator"]);
      }
    } else if (dashboardUserRole === "creator") {
      if (idpRole === "admin") {
        setRoles(["viewer", "editor", "co-creator"]);
      } else if (idpRole === "user") {
        setRoles(["viewer"]);
      }
    } else if (dashboardUserRole === "co-creator") {
      if (idpRole === "admin") {
        setRoles(["viewer", "editor", "co-creator"]);
      } else if (idpRole === "user") {
        setRoles(["viewer"]);
      }
    }
    if (role) {
      const index = roles.indexOf(role);
      setSelectedRole({ value: role, index: index });
    }
  }, [idpRole]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const getAllBoardsAsync = async () => {
    const res = await getAllBoards();
    dispatch(setAllBoards(res?.data?.data?.result));
  };
  const handleMenuItemClick = async (event, index) => {
    const selectedRole = roles[index];
    let roletochange = "";
    let owneremail = "";

    if (selectedRole === "creator") {
      roletochange = "creator";
      owneremail = board?.createdBy?.email;
    } else {
      roletochange = selectedRole;
    }

    const reqdata = {
      boardId: board?._id,
      data: {
        email: email,
        roletochange: roletochange,
        owneremail: owneremail,
      },
    };

    const res = await updateDashboardRole(reqdata);

    console.log(res);
    if (res?.data?.success === true) {
      setSelectedRole({
        value: roles[index],
        index: index,
      });
      getAllBoardsAsync();
      enqueueSnackbar(res?.data?.data?.message, {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Somthing went wrong Please Try again Later", {
        variant: "error",
      });
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup ref={anchorRef} aria-label="Button group with a nested menu">
        <Button
          onClick={() => `${roles[selectedRole?.index]}`}
          sx={{
            border: "1px solid #DFDFDF",
            borderRadius: "4px",
            color: "#333",
            width: "125px",
          }}
        >
          {selectedRole ? roles[selectedRole?.index] : "Select the role"}
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={{
            border: "1px solid #DFDFDF",
            borderRadius: "4px",
          }}
        >
          <ArrowDropDownIcon sx={{ color: "#333" }} />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {roles.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedRole?.index}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default UserManagementButton;
