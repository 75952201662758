import "./App.css";
import RouterComponent from "./routes";
import { SnackbarProvider } from "notistack";


function App() {
  return (
    <>
      <SnackbarProvider autoHideDuration={3000} dense action maxSnack={3}>
        <RouterComponent />
      </SnackbarProvider>
    </>
  );
}

export default App;
