import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
// import "../../assets/Style/loader.css";

function Loader({height}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height:  height || "100%",
      }}
    >
      <CircularProgress />
    </div>
    // <>
    //   <div className="loader"></div>
    // </>
  );
}

export default Loader;
