import {
  AccordionDetails,
  Box,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import React, { useEffect, useState } from "react";
import SearchHeader from "../../../Component/SearchHeader";
import Chart from "../../../utils/Chart";
import {
  getAllCataloge,
  getCatalogDetails,
  getQuery,
  getSingleObject,
  runQuery,
  updateObject,
} from "../../../services";
import {
  setAllCatalog,
  setAskQuestion,
  setQueryResponse,
  setUpdateObjectData,
  setUseFromRedux,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import AgGrid from "../../../utils/AgGrid";
import {
  selectAllCatlog,
  selectAskQuestion,
  selectSelectedData,
  selectUpdateObjectData,
} from "../../../redux/selectors";
import Loader from "../../../utils/Loader";
import PivotUI from "../../../utils/piviot";
import { Accordion, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChartType from "../../../Component/ChartType";
import TitleProperties from "../../../Component/TitleProperties";
import WidgetProperties from "../../../Component/WidgetProperties";
import ChartProperties from "../../../Component/ChartProperties";
import { useNavigate, useParams } from "react-router-dom";
import CommonDialog from "../../../utils/Dialog";

const UpdateSingleAnswerBoard = () => {
  const UpdateObjectData = useSelector(selectUpdateObjectData);
  const AskQuestionData = useSelector(selectAskQuestion);
  const SelectedData = useSelector(selectSelectedData);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [pivotState, setPivotState] = useState();
  const [expanded, setExpanded] = useState(null);
  const [controller, setController] = useState(new AbortController());
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [isChartLoading, setIsChartLoading] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  let catalogDetails;
  const handleApiReq = () => {
    if (controller) {
      controller.abort();
      setController(new AbortController());
      setIsLoading(false);
      dispatch(setQueryResponse({ Response: {}, rowData: "", columnDef: "" }));
    }
  };

  // const getBoardsByIdAsync = async (id) => {
  //   const res = await getBoardsById(id);
  //   setBoardData(res?.data?.data?.result);
  //   console.log(res, "getBoardsByIdAsync");
  // };

  const queryRunHandler = async () => {
    setIsLoading(true);

    if (AskQuestionData.question !== "" || AskQuestionData.sql !== "") {
      let queryContent;
      if (AskQuestionData.askFromQuery) {
        queryContent = AskQuestionData.sql;
      } else {
        const reqData = {
          data: {
            message: AskQuestionData.question,
            table: AskQuestionData.catalog?.metaData?.table.name,
            catalogId: AskQuestionData.catalog._id,
            column: AskQuestionData.selectedColum,
          },
        };

        const queryRes = await getQuery(reqData);

        queryContent = queryRes?.data?.data?.result?.content;
        dispatch(
          setAskQuestion({
            sql: queryRes?.data?.data?.result?.content,
          })
        );
        dispatch(
          setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  query: queryRes?.data?.data?.result?.content,
                },
              },
            },
          })
        );
      }
      const data = {
        query: queryContent,
        connectionId: AskQuestionData.catalog?.connectionId,
        // filters: [],
        filters: SelectedData,
        catalogId: AskQuestionData.catalog._id,
      };

      const res = await runQuery(data, controller.signal);
      if (res.message !== "canceled") {
        dispatch(
          setUpdateObjectData({
            ObjectResponseData: {
              Response: res,
            },
          })
        );

        if (res?.response?.status === 500 || res?.response?.status === 400) {
          dispatch(
            setQueryResponse({
              rowData: "",
              columnDef: "",
            })
          );

          setIsLoading(false);
          // return alert("Inappropriate Question");
          if (AskQuestionData.askFromQuery) {
            alert("Inappropriate Query");
          } else {
            alert("Inappropriate prompt");
          }
          return;
        }
        console.log(res?.response?.status, "kkkkkkkkkk");
        const updateResultData = res?.data?.data?.result;
        if (updateResultData && updateResultData.length > 0) {
          const keys = Object.keys(updateResultData[0]);
          dispatch(
            setUpdateObjectData({
              numberOfProperties: keys.length,
            })
          );
          const column = keys?.map((item, index) => {
            return { field: item };
          });
          dispatch(
            setUpdateObjectData({
              ObjectResponseData: {
                Response: res,
                rowData: updateResultData,
                columnDef: column,
              },
            })
          );
        } else {
          dispatch(
            setUpdateObjectData({
              ObjectResponseData: {
                Response: res,
                rowData: [],
                columnDef: [],
              },
            })
          );
        }
        setExpanded(null);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      // alert("Enter Query");
      if (AskQuestionData.askFromQuery) {
        alert("Enter your query");
      } else {
        alert("Enter your prompt");
      }
    }
  };

  useEffect(() => {
    dispatch(setUseFromRedux(true));
    getSingleObjectAsync();
  }, []);

  const getSingleObjectAsync = async () => {
    const singleObjectRes = await getSingleObject(id);
    dispatch(
      setUpdateObjectData({
        ObjectData: singleObjectRes?.data,
      })
    );
    setPivotState(singleObjectRes?.data?.data?.result?.visualization?.pivot);
    setIsLoading(true);
    const resAllCataloge = await getAllCataloge();
    dispatch(setAllCatalog(resAllCataloge?.data?.data?.result));

    const catalogData = {
      data: {
        catalogId: singleObjectRes?.data?.data?.result.catalogId,
      },
    };

    const catalogRes = await getCatalogDetails(catalogData);
    catalogDetails = catalogRes.data.data.result;
    let queryContent;

    queryContent = singleObjectRes?.data?.data?.result.query;

    const data = {
      query: queryContent,
      connectionId: catalogRes?.data?.data?.result?.connectionId,
      filters: [],
      catalogId: singleObjectRes?.data?.data?.result.catalogId,
    };

    const res = await runQuery(data, controller.signal);
    console.log(res, "object data");
    if (res.message !== "canceled") {
      if (res?.response?.status === 500 || res?.response?.status === 400) {
        dispatch(
          setUpdateObjectData({
            ObjectResponseData: {
              rowData: "",
              columnDef: "",
            },
          })
        );

        // setIsLoading(false);
        // if (AskQuestionData.askFromQuery) {
        //   alert("Inappropriate Query");
        // } else {
        //   alert("Inappropriate prompt");
        // }
        return;
      }

      const keys = Object.keys(res?.data?.data?.result[0]);
      dispatch(
        setUpdateObjectData({
          numberOfProperties: keys.length,
        })
      );
      const column = keys?.map((item, index) => {
        return { field: item };
      });
      dispatch(
        setUpdateObjectData({
          ObjectResponseData: {
            Response: res,
            rowData: res?.data?.data?.result,
            columnDef: column,
          },
        })
      );
    }
    const catalog = resAllCataloge?.data?.data?.result?.find(
      (option) => option._id === singleObjectRes.data.data.result?.catalogId
    );


    if (
      singleObjectRes?.data?.data?.result?.question ===
      "Question is Asked using Query"
    ) {
      dispatch(
        setAskQuestion({
          ...AskQuestionData,
          question: "",
          sql: singleObjectRes?.data?.data?.result?.query,
          askFromQuery: true,
          catalog: catalog,
        })
      );
    } else {
      dispatch(
        setAskQuestion({
          ...AskQuestionData,
          question: singleObjectRes?.data?.data?.result?.question,
          sql: singleObjectRes?.data?.data?.result?.query,
          askFromQuery: false,
          catalog: catalog,
        })
      );
    }
    setIsLoading(false);
  };

  const updateObjectAsync = async () => {
    const updatedResult = {
      ...UpdateObjectData.ObjectData.data.result,
      visualization: {
        ...UpdateObjectData.ObjectData.data.result.visualization,
        pivot: pivotState,
      },
    };

    const reqData = {
      businessId: id,
      data: {
        ...updatedResult,
      },
    };

    const res = await updateObject(reqData);
    navigate(
      `/answer-board/${UpdateObjectData.ObjectData.data.result.boardId}`
    );
    console.log("res", res);
  };

  const confirmationDialogActions = [
    {
      label: "No",
      color: "primary",
      onClick: () => setConfirmationDialogOpen(false),
    },
    {
      label: "Yes",
      color: "warning",
      onClick: () => {
        updateObjectAsync();
        setConfirmationDialogOpen(false);
      },
    },
  ];

  return (
    <Box>
      <SearchHeader
        update={true}
        type={"update"}
        queryRunHandler={queryRunHandler}
        catalogDetails={catalogDetails}
      />
      {isLoading ? (
        <Box
          sx={{
            width: "80vw",
            height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
          <Button onClick={handleApiReq}>kill</Button>
        </Box>
      ) : (
        <>
          {UpdateObjectData?.ObjectData?.success === true ? (
            <Box
              sx={{
                display: "flex",
                mt: 5,
              }}
            >
              {UpdateObjectData?.ObjectResponseData?.rowData &&
              UpdateObjectData?.ObjectResponseData?.rowData?.length > 0 ? (
                <>
                  <Box className="data" sx={{ flex: 1 }}>
                    <Box
                      className="chart-container"
                      sx={{
                        minHeight: "60vh",
                        // width: "78vw",
                        overflow: "auto",
                        border: "1px solid #D3D3D3",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {UpdateObjectData?.ObjectData?.data.result.visualization
                        .type === "widget" ? (
                        ""
                      ) : (
                        <Typography
                          sx={{
                            width: "100%",
                            fontFamily: `${UpdateObjectData?.ObjectData?.data.result.titleProperties.font}`,
                            fontSize: `${UpdateObjectData?.ObjectData?.data.result.titleProperties.fontSize}px`,
                            color: `${UpdateObjectData?.ObjectData?.data.result.titleProperties.textColor}`,
                            textAlign: `${UpdateObjectData?.ObjectData?.data.result.titleProperties.textAlignment}`,
                            fontWeight: `${UpdateObjectData?.ObjectData?.data.result.titleProperties.bold}`,
                            fontStyle: `${UpdateObjectData?.ObjectData?.data.result.titleProperties.italic}`,
                            textDecoration: `${UpdateObjectData?.ObjectData?.data.result.titleProperties.underline}`,
                          }}
                        >
                          {UpdateObjectData?.ObjectData?.data.result
                            .titleProperties.text || "Title"}
                        </Typography>
                      )}
                      {UpdateObjectData?.ObjectData?.data.result.visualization
                        .type === "grid" ? (
                        <AgGrid
                          rowData={
                            UpdateObjectData?.ObjectResponseData?.rowData
                          }
                          columnDef={
                            UpdateObjectData?.ObjectResponseData?.columnDef
                          }
                        />
                      ) : UpdateObjectData?.ObjectData?.data.result
                          .visualization.type === "Pivot" ? (
                        <Box sx={{ width: "70%" }}>
                          <PivotUI
                            pivotState={pivotState}
                            setPivotState={setPivotState}
                            pivotData={
                              UpdateObjectData?.ObjectResponseData?.Response
                                ?.data?.data?.result
                            }
                            pivotToolbar={"toolbar"}
                          />
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            width:
                              UpdateObjectData?.ObjectData?.data.result
                                .visualization.type === "widget"
                                ? "25%"
                                : "70%",
                          }}
                        >
                          <Chart
                            response={
                              UpdateObjectData?.ObjectResponseData.Response
                            }
                            chartType={
                              UpdateObjectData?.ObjectData?.data.result
                                .visualization.type
                            }
                            widgetProp={
                              UpdateObjectData?.ObjectData?.data.result
                                .widgetProp
                            }
                            chartProperties={
                              UpdateObjectData?.ObjectData?.data.result
                                .chartProperties
                            }
                            height={"450px"}
                            isChartLoading={isChartLoading}
                            setIsChartLoading={setIsChartLoading}
                          />
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        mt: 1,
                        paddingY: "12px",
                        display: "flex",
                        justifyContent: "end",
                        width: "100%",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        sx={{ backgroundColor: "#5D5FEF", padding: "7px 15px" }}
                        onClick={() => setConfirmationDialogOpen(true)}
                      >
                        Update <CheckCircleRoundedIcon />
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    className="scrollbar"
                    sx={{
                      ml: 3,
                      flex: "0 0 14%",
                      overflowY: "auto",
                      maxHeight: "65vh",
                    }}
                  >
                    <Accordion
                      expanded={expanded === "panel"}
                      onChange={handleAccordionChange("panel")}
                      sx={{ border: "1px solid #dedede" }}
                    >
                      <AccordionSummary>
                        <Box
                          sx={{
                            border: "1px solid #DADADA",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#606060",
                              fontSize: "16px",
                              fontWeight: "600",
                              p: 1,
                            }}
                          >
                            Visualization
                          </Typography>
                          <ExpandMoreIcon />
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ChartType
                          numberOfProperties={
                            UpdateObjectData?.numberOfProperties
                          }
                          update={true}
                          resLength={
                            UpdateObjectData?.ObjectResponseData?.Response?.data
                              ?.data?.result?.length
                          }
                          setIsChartLoading={setIsChartLoading}
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleAccordionChange("panel1")}
                      sx={{ border: "1px solid #dedede" }}
                      // disabled={
                      //   UpdateObjectData?.ObjectData?.data.result.visualization
                      //     .type === "widget"
                      // }
                    >
                      <AccordionSummary>
                        <Box
                          sx={{
                            border: "1px solid #DADADA",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#606060",
                              fontSize: "16px",
                              fontWeight: "600",
                              p: 1,
                            }}
                          >
                            Title Properties
                          </Typography>
                          <ExpandMoreIcon />
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TitleProperties
                          update={true}
                          chartType={
                            UpdateObjectData?.ObjectData?.data.result
                              .visualization.type
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel2"}
                      onChange={handleAccordionChange("panel2")}
                      sx={{ border: "1px solid #dedede" }}
                      disabled={
                        UpdateObjectData?.ObjectData?.data.result.visualization
                          .type !== "widget"
                      }
                    >
                      <AccordionSummary>
                        <Box
                          sx={{
                            border: "1px solid #DADADA",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#606060",
                              fontSize: "16px",
                              fontWeight: "600",
                              p: 1,
                            }}
                          >
                            Widget Properties
                          </Typography>
                          <ExpandMoreIcon />
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <WidgetProperties update={true} />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handleAccordionChange("panel3")}
                      sx={{ border: "1px solid #dedede" }}
                      disabled={
                        UpdateObjectData?.ObjectData?.data.result.visualization
                          .type === "grid" ||
                        UpdateObjectData?.ObjectData?.data.result.visualization
                          .type === "widget" ||
                        UpdateObjectData?.ObjectData?.data.result.visualization
                          .type === "Pivot"
                      }
                    >
                      <AccordionSummary>
                        <Box
                          sx={{
                            border: "1px solid #DADADA",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#606060",
                              fontSize: "16px",
                              fontWeight: "600",
                              p: 1,
                            }}
                          >
                            Chart Properties
                          </Typography>
                          <ExpandMoreIcon />
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ChartProperties
                          update={true}
                          numberOfProperties={
                            UpdateObjectData?.numberOfProperties
                          }
                          updateQueryResponse={
                            UpdateObjectData?.ObjectData?.data?.result
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ mt: 3, textAlign: "center" }}
                  >
                    No data found
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            ""
          )}
        </>
      )}
      <CommonDialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        title="Update Board"
        content="Are you sure you want to Save the changes."
        actions={confirmationDialogActions}
      />
    </Box>
  );
};

export default UpdateSingleAnswerBoard;
