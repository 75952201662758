import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
const Setting = () => {
  const [gridApi, setGridApi] = useState(null);
  const [gridOptions, setGridOptions] = useState({
    columnDefs: [
      { headerName: "Year", field: "year", rowGroup: true },
      { headerName: "Month", field: "month", rowGroup: true },
      { headerName: "Date", field: "date" },
      { headerName: "Name", field: "name" },
      { headerName: "Age", field: "age" },
      { headerName: "Country", field: "country" },
      { headerName: "City", field: "city" },
    ],
    autoGroupColumnDef: {
      headerName: "Group",
      minWidth: 300,
      cellRendererParams: {
        suppressCount: true, // Do not show the count in the group header
      },
    },
    defaultColDef: {
      flex: 1,
      minWidth: 100,
    },
    rowData: [
      {
        year: 2022,
        month: "January",
        date: "2022-01-01",
        name: "John Doe",
        age: 30,
        country: "USA",
        city: "New York",
      },
      {
        year: 2022,
        month: "January",
        date: "2022-01-02",
        name: "Jane Doe",
        age: 25,
        country: "Canada",
        city: "Toronto",
      },
      {
        year: 2022,
        month: "February",
        date: "2022-02-01",
        name: "Bob Smith",
        age: 35,
        country: "UK",
        city: "London",
      },
      {
        year: 2022,
        month: "February",
        date: "2022-02-02",
        name: "Alice Johnson",
        age: 28,
        country: "Australia",
        city: "Sydney",
      },
    ],
    rowSelection: "multiple",
    groupSelectsChildren: true, // Allow selecting children when selecting a group row
  });

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const expandAll = () => {
    gridApi?.expandAll();
  };

  const collapseAll = () => {
    gridApi?.collapseAll();
  };

  return (
    <div style={{ width: "100%", height: "400px" }}>
      <div>
        <button onClick={expandAll}>Expand All</button>
        <button onClick={collapseAll}>Collapse All</button>
      </div>
      <AgGridReact onGridReady={onGridReady} gridOptions={gridOptions} />
    </div>
  );
};

export default Setting;
