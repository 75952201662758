// src/redux/reducers/index.js
const initialState = {
  catalogData: {
    connectionId: "",
    metaData: {
      table: null,
      columns: [],
      selectedFilters: [],
    },
    name: "",
  },

  dataBase: "",

  testConnection: false,

  connectionData: {
    name: "",
    host: "",
    port: "",
    database: "",
    username: "",
    password: "",
    driver: "",
  },

  allConnection: [],

  allCatlog: [],

  queryResponse: {
    Response: {},
    rowData: "",
    columnDef: "",
  },

  askQuestion: {
    catalog: null,
    columnsNames: [],
    selectedColum: [],
    question: "",
    sql: "",
    chartType: "grid",
    askFromQuery: false,
    numberOfProperties: "",
    colData:[],
    selectedCol:"",
  },

  selectedData: [],

  piviotData: {
    catalog: null,
    tableData: [],
  },

  allBoards: [],

  filter: [],

  widgetProp: {
    icon: "fas fa-hashtag",
    iconColor: "#000000",
    iconBackgroundColor: "#dedede",
    preFix: "",
    postFix: "",
    decimalPlaces: null,
    percentage: null,
  },

  titleProperties: {
    text: "Title",
    font: "Arial,sans-serif",
    fontSize: 20,
    textColor: "#000000",
    textAlignment: "Center",
    bold: "",
    italic: "",
    underline: "",
  },

  chartProperties: {
    chartColor: "#5470C6",
    chartColorSecond: "#76D8D7",
    VerticalScroll: false,
    HorizontalScroll: false,
    xLable: "",
    yLable: "",
    roundOff: null,
    DataLabel: false,
    fontSize: 12,
    textAlignment: "",
    bold: "",
    italic: "",
    font: "Arial,sans-serif",
    xAxis: "",
  },

  useFromRedux: null,

  sideBarOpen: true,

  updateObjectData: {
    ObjectData: {},
    ObjectResponseData: {
      Response: {},
      rowData: "",
      columnDef: "",
    },
    numberOfProperties: "",
  },

  userDetails: [],

  allUsers: [],
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CATALOG_DATA":
      return {
        ...state,
        catalogData: {
          ...state.catalogData,
          ...action.payload,
        },
      };

    case "SET_DATABASE":
      return {
        ...state,
        dataBase: action.payload,
      };
    case "SET_CONNECTION_DATA":
      return {
        ...state,
        connectionData: {
          ...state.connectionData,
          ...action.payload,
        },
      };

    case "SET_TEST_CONNECTION":
      return {
        ...state,
        testConnection: action.payload,
      };

    case "SET_ALL_CONNECTION":
      return {
        ...state,
        allConnection: action.payload,
      };
    case "SET_ALL_CATALOG":
      return {
        ...state,
        allCatlog: action.payload,
      };
    case "SET_QUERY_RESPONSE":
      return {
        ...state,
        queryResponse: {
          ...state.queryResponse,
          ...action.payload,
        },
      };
    case "SET_ASK_QUESTION":
      return {
        ...state,
        askQuestion: {
          ...state.askQuestion,
          ...action.payload,
        },
      };
    case "SET_PIVIOT_DATA":
      return {
        ...state,
        piviotData: {
          ...state.piviotData,
          ...action.payload,
        },
      };

    case "SET_ALL_BOARDS":
      return {
        ...state,
        allBoards: action.payload,
      };
    case "SET_FILTER":
      return {
        ...state,
        filter: action.payload,
      };
    case "SET_WIDGET":
      return {
        ...state,
        widgetProp: action.payload,
      };
    case "SET_TITLE_PROPERTIES":
      return {
        ...state,
        titleProperties: action.payload,
      };
    case "SET_CHART_PROPERTIES":
      return {
        ...state,
        chartProperties: action.payload,
      };
    case "SET_USE_FROM_REDUX":
      return {
        ...state,
        useFromRedux: action.payload,
      };
    case "SET_SIDE_BAR_OPEN":
      return {
        ...state,
        sideBarOpen: action.payload,
      };

    case "SET_UPDATE_OBJECT_DATA":
      return {
        ...state,
        updateObjectData: {
          ...state.updateObjectData,
          ...action.payload,
        },
      };

    case "SET_USER_DETAILS":
      return {
        ...state,
        userDetails: action.payload,
      };
    case "SET_ALL_USER":
      return {
        ...state,
        allUsers: action.payload,
      };
      case "SET_SELECTED_DATA":
      return {
        ...state,
        selectedData: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;
