import {
  AccordionDetails,
  Box,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import React, { useEffect, useState } from "react";
import SearchHeader from "../../Component/SearchHeader";
import Chart from "../../utils/Chart";
import { getAllCataloge, getQuery, runQuery } from "../../services";
import {
  setAllCatalog,
  setAskQuestion,
  setChartProperties,
  setQueryResponse,
  setTitleProperties,
  setUpdateObjectData,
  setUseFromRedux,
  setWidget,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import AgGrid from "../../utils/AgGrid";
import {
  selectAskQuestion,
  selectChartProperties,
  selectQueryResponse,
  selectSelectedData,
  selectTitleProperties,
  selectWidget,
} from "../../redux/selectors";
import Loader from "../../utils/Loader";
import SaveToAnswerBoard from "../../Component/Save";
import PivotUI from "../../utils/piviot";
import { Accordion, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChartType from "../../Component/ChartType";
import TitleProperties from "../../Component/TitleProperties";
import WidgetProperties from "../../Component/WidgetProperties";
import ChartProperties from "../../Component/ChartProperties";
import { useLocation } from "react-router-dom";

const QuestionBoard = () => {
  const QueryResponseData = useSelector(selectQueryResponse);
  const chartProperties = useSelector(selectChartProperties);
  const AskQuestionData = useSelector(selectAskQuestion);
  const SelectedData = useSelector(selectSelectedData);
  const widgetData = useSelector(selectWidget);
  const Title = useSelector(selectTitleProperties) || "";
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [pivotState, setPivotState] = useState();
  const [expanded, setExpanded] = useState(null);
  const [isChartLoading, setIsChartLoading] = useState(false);
  // const [selectedData, setselectedData] = useState([]);

  // const [QueryResponseData, setQueryResponseData] = useState(null);
  const [controller, setController] = useState(new AbortController());
  // const [loading, setLoading] = useState(false);
  const location = useLocation();
  const prevPath = location.state && location.state.prevPath;

  console.log("prevPath", prevPath);

  useEffect(() => {
    if (prevPath && prevPath.includes("update-single-answer-board")) {
      console.log("this startsahsdoiyhkj");
      dispatch(
        setUpdateObjectData({
          ObjectData: {},
          ObjectResponseData: {
            Response: {},
            rowData: "",
            columnDef: "",
          },
          numberOfProperties: "",
        })
      );
      dispatch(
        setAskQuestion({
          catalog: null,
          columnsNames: [],
          selectedColum: [],
          question: "",
          sql: "",
          chartType: "grid",
          askFromQuery: false,
          numberOfProperties: "",
        })
      );
    }
    dispatch(setUseFromRedux(true));
  }, []);

  useEffect(() => {
    setExpanded(null);
    getAllCatalogeAsync();
  }, []);

  const getAllCatalogeAsync = async () => {
    const res = await getAllCataloge();
    dispatch(setAllCatalog(res?.data?.data?.result));
  };

  const queryRunHandler = async () => {
    setIsLoading(true);
    dispatch(
      setAskQuestion({
        chartType: "grid",
      })
    );
    if (!AskQuestionData.catalog) {
      setIsLoading(false);
      alert("Select the catalog first");
      return;
    }
    if (
      AskQuestionData.question !== "" ||
      (AskQuestionData.sql !== "" && AskQuestionData.askFromQuery)
    ) {
      let queryContent;
      if (AskQuestionData.askFromQuery) {
        queryContent = AskQuestionData.sql;
      } else {
        const reqData = {
          data: {
            message: AskQuestionData.question,
            table: AskQuestionData.catalog?.metaData?.table.name,
            catalogId: AskQuestionData.catalog._id,
            column: AskQuestionData.selectedColum,
          },
        };

        const queryRes = await getQuery(reqData);

        queryContent = queryRes?.data?.data?.result?.content;
        dispatch(
          setAskQuestion({
            sql: queryRes?.data?.data?.result?.content,
          })
        );
      }
      const data = {
        query: queryContent,
        connectionId: AskQuestionData.catalog?.connectionId,
        // filters: selectedData,
        filters: SelectedData,
        catalogId: AskQuestionData.catalog._id,
      };

      const res = await runQuery(data, controller.signal);
      if (res.message !== "canceled") {
        dispatch(
          setQueryResponse({
            Response: res,
          })
        );

        if (res?.response?.status === 500 || res?.response?.status === 400) {
          dispatch(
            setQueryResponse({
              rowData: "",
              columnDef: "",
            })
          );
          setIsLoading(false);
          if (AskQuestionData.askFromQuery) {
            alert("Inappropriate Query");
          } else {
            alert("Inappropriate Question");
          }
          return;
        }

        const resultData = res?.data?.data?.result;
        if (resultData && resultData.length > 0) {
          const keys = Object.keys(resultData[0]);
          dispatch(
            setAskQuestion({
              numberOfProperties: keys.length,
            })
          );
          const column = keys.map((item) => ({ field: item }));
          dispatch(
            setQueryResponse({
              rowData: resultData,
              columnDef: column,
            })
          );
        } else {
          dispatch(
            setQueryResponse({
              rowData: [],
              columnDef: [],
            })
          );
        }

        dispatch(
          setWidget({
            icon: "fas fa-hashtag",
            iconColor: "#000000",
            iconBackgroundColor: "#dedede",
            preFix: "",
            postFix: "",
            decimalPlaces: null,
            percentage: null,
          })
        );
        dispatch(
          setChartProperties({
            chartColor: "#5470C6",
            chartColorSecond: "#76D8D7",
            VerticalScroll: false,
            HorizontalScroll: false,
            xLable: "",
            yLable: "",
            roundOff: null,
            DataLabel: false,
            fontSize: 12,
            textAlignment: "",
            bold: "",
            italic: "",
            font: "Arial,sans-serif",
            xAxis: "",
          })
        );
        dispatch(
          setTitleProperties({
            text: "Title",
            font: "Arial,sans-serif",
            fontSize: 20,
            textColor: "#000000",
            textAlignment: "Center",
            bold: "",
            italic: "",
            underline: "",
          })
        );
        setExpanded(null);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      if (AskQuestionData.askFromQuery) {
        alert("Enter your query");
      } else {
        alert("Enter your Question first");
      }
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const handleApiReq = () => {
    if (controller) {
      controller.abort();
      setController(new AbortController());
      setIsLoading(false);
      dispatch(setQueryResponse({ Response: {}, rowData: "", columnDef: "" }));
    }
  };
  console.log(
    QueryResponseData?.Response?.data?.data?.result,
    "QueryResponseData"
  );
  console.log(QueryResponseData?.rowData, "row");
  return (
    <Box>
      <SearchHeader
        queryRunHandler={queryRunHandler}
        handleApiReq={handleApiReq}
        isLoading={isLoading}
        // setselectedData={setselectedData}
      />
      {isLoading ? (
        <Box
          sx={{
            width: "80vw",
            height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
          {/* <Button onClick={handleApiReq}>kill</Button> */}
        </Box>
      ) : (
        <>
          {QueryResponseData?.Response?.status === 200 ? (
            <Box
              sx={{
                display: "flex",
                mt: 5,
              }}
            >
              {QueryResponseData?.rowData &&
              QueryResponseData?.rowData?.length > 0 ? (
                <>
                  <Box className="data" sx={{ flex: 1 }}>
                    <Box
                      className="chart-container"
                      sx={{
                        minHeight: "60vh",
                        // width: "78vw",
                        overflow: "auto",
                        border: "1px solid #D3D3D3",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {AskQuestionData.chartType === "widget" ? (
                        ""
                      ) : (
                        <Typography
                          sx={{
                            width: "100%",
                            fontFamily: `${Title.font}`,
                            fontSize: `${Title.fontSize}px`,
                            color: `${Title.textColor}`,
                            textAlign: `${Title.textAlignment}`,
                            fontWeight: `${Title.bold}`,
                            fontStyle: `${Title.italic}`,
                            textDecoration: `${Title.underline}`,
                          }}
                        >
                          {Title?.text || "Title"}
                        </Typography>
                      )}
                      {AskQuestionData.chartType === "grid" ? (
                        <AgGrid
                          rowData={QueryResponseData?.rowData}
                          columnDef={QueryResponseData?.columnDef}
                        />
                      ) : AskQuestionData.chartType === "Pivot" ? (
                        <Box sx={{ width: "70%" }}>
                          <PivotUI
                            pivotState={pivotState}
                            setPivotState={setPivotState}
                            pivotData={
                              QueryResponseData?.Response?.data?.data?.result
                            }
                            // pivotToolbar={toolbar}
                          />
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            width:
                              AskQuestionData.chartType === "widget"
                                ? "25%"
                                : "70%",
                          }}
                        >
                          <Chart
                            response={QueryResponseData?.Response}
                            chartType={AskQuestionData.chartType}
                            widgetProp={widgetData}
                            height={"450px"}
                            chartProperties={chartProperties}
                            isChartLoading={isChartLoading}
                            setIsChartLoading={setIsChartLoading}
                          />
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        mt: 1,
                        paddingY: "12px",
                        display: "flex",
                        justifyContent: "end",
                        width: "100%",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        sx={{ backgroundColor: "#5D5FEF", padding: "7px 15px" }}
                        onClick={() => setOpenSave(true)}
                      >
                        Save <CheckCircleRoundedIcon />
                      </Button>
                      <Modal open={openSave}>
                        <SaveToAnswerBoard
                          pivotState={pivotState}
                          setOpenSave={setOpenSave}
                        />
                      </Modal>
                    </Box>
                  </Box>
                  <Box
                    className="scrollbar"
                    sx={{
                      ml: 3,
                      flex: "0 0 19%",
                      overflowY: "auto",
                      maxHeight: "65vh",
                    }}
                  >
                    <Accordion
                      expanded={expanded === "panel"}
                      onChange={handleAccordionChange("panel")}
                      sx={{ border: "1px solid #dedede" }}
                    >
                      <AccordionSummary>
                        <Box
                          sx={{
                            border: "1px solid #DADADA",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#606060",
                              fontSize: "16px",
                              fontWeight: "600",
                              p: 1,
                            }}
                          >
                            Visualization
                          </Typography>
                          <ExpandMoreIcon />
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ChartType
                          resLength={
                            QueryResponseData?.Response.data.data.result.length
                          }
                          numberOfProperties={
                            AskQuestionData.numberOfProperties
                          }
                          setIsChartLoading={setIsChartLoading}
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleAccordionChange("panel1")}
                      sx={{ border: "1px solid #dedede" }}
                      // disabled={AskQuestionData.chartType === "widget"}
                    >
                      <AccordionSummary>
                        <Box
                          sx={{
                            border: "1px solid #DADADA",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#606060",
                              fontSize: "16px",
                              fontWeight: "600",
                              p: 1,
                            }}
                          >
                            Title Properties
                          </Typography>
                          <ExpandMoreIcon />
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TitleProperties
                          chartType={AskQuestionData.chartType}
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel2"}
                      onChange={handleAccordionChange("panel2")}
                      sx={{ border: "1px solid #dedede" }}
                      disabled={AskQuestionData.chartType !== "widget"}
                    >
                      <AccordionSummary>
                        <Box
                          sx={{
                            border: "1px solid #DADADA",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#606060",
                              fontSize: "16px",
                              fontWeight: "600",
                              p: 1,
                            }}
                          >
                            Widget Properties
                          </Typography>
                          <ExpandMoreIcon />
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <WidgetProperties />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handleAccordionChange("panel3")}
                      sx={{ border: "1px solid #dedede" }}
                      disabled={
                        AskQuestionData.chartType === "grid" ||
                        AskQuestionData.chartType === "widget" ||
                        AskQuestionData.chartType === "Pivot"
                      }
                    >
                      <AccordionSummary>
                        <Box
                          sx={{
                            border: "1px solid #DADADA",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#606060",
                              fontSize: "16px",
                              fontWeight: "600",
                              p: 1,
                            }}
                          >
                            Chart Properties
                          </Typography>
                          <ExpandMoreIcon />
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ChartProperties
                          numberOfProperties={
                            AskQuestionData.numberOfProperties
                          }
                          data={QueryResponseData?.Response}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ mt: 3, textAlign: "center" }}
                  >
                    No data found
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            ""
          )}
        </>
      )}
    </Box>
  );
};
export default QuestionBoard;
