import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import { Box, Card, CardContent, Tooltip, Typography } from "@mui/material";
import { idpRoleAccess, transformDataForPieChart } from "../utils";
import Loader from "../Loader";
import "echarts/theme/dark";
// import { setAskQuestion } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { setChartProperties } from "../../redux/actions";

const Chart = ({
  response,
  chartType,
  widgetProp,
  chartProperties,
  chartCardHeight,
  titleProperties,
  prop,
  selectedXkey,
  isChartLoading,
  setIsChartLoading,
}) => {
  const dispatch = useDispatch();
  const [key, setKey] = useState(0);
  const [loading, setLoading] = useState(true);
  const [option, setOption] = useState({});
  const [chartData, setChartData] = useState({
    xAxis: [],
    yAxis: [],
    zAxis: [],
  });
  // const chat =useSelector(selectChartProperties)
  console.log(chartProperties, "chartPropertieschartPropertieschartProperties");
  const navigate = useNavigate();
  const role = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).roles[0]
    : null;
  const numberOfProperties = response?.data?.data?.result[0] ? (
    Object.keys(response?.data?.data?.result[0]).length
  ) : (
    <> no data</>
  );
  console.log(
    response?.data?.data?.result,
    "chartDatachartDatachartDatachartDatachartData"
  );

  useEffect(() => {
    try {
      const result = response.data?.data?.result;
      if (result) {
        if (numberOfProperties === 3) {
          const [xAxis, yAxis, zAxis] = result.reduce(
            (acc, item) => {
              const [first, second, third] = Object.values(item);

              acc[1].push(
                typeof first === "string"
                  ? second
                  : typeof second === "number"
                  ? second.toFixed(2)
                  : third
              );
              acc[2].push(
                typeof second === "number"
                  ? chartProperties.roundOff
                    ? third.toFixed(2)
                    : third
                  : first
              );
              acc[0].push(
                typeof third === "number"
                  ? chartProperties.roundOff
                    ? first.toFixed(2)
                    : first
                  : second
              );

              return acc;
            },
            [[], [], []]
          );

          setChartData({ xAxis, yAxis, zAxis });
        } else if (numberOfProperties === 2) {
          const [xAxis, yAxis] = result.reduce(
            (acc, item) => {
              const [first, second] = Object.values(item);
              console.log(typeof second, typeof first);
              acc[0].push(typeof first === "string" ? first : second);
              if (chartProperties.roundOff) {
                acc[1].push(
                  typeof second === "number"
                    ? second.toFixed("2")
                    : first.toFixed("2")
                );
              } else {
                acc[1].push(typeof second === "number" ? second : first);
              }

              return acc;
            },
            [[], []]
          );
          setChartData({ xAxis, yAxis });
        } else if (numberOfProperties === 1) {
          const xAxis = result.map((item) => item[Object.keys(item)[0]]);
          setChartData({ xAxis });
        }
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  }, [response, chartProperties.roundOff]);

  useEffect(() => {
    console.log("upper", option);
    setOption({});
    console.log("herer", option);
    setKey((prevKey) => prevKey + 1);
  }, [chartType]);

  useEffect(() => {
    const result = response.data?.data?.result[0];
    const AllResult = response.data?.data?.result;
    let xAxisVal;
    let xAxis;
    console.log(result, "resultresultresultresult");
    if (result) {
      for (const key in result) {
        if (typeof result[key] === "string") {
          xAxis = key;
          xAxisVal = AllResult?.map((entry) => entry[key]);
          break;
        }
      }
    }
    // let legend = AllResult?.length
    //   ? Object.keys(result).filter((axis) => xAxis !== axis)
    //   : [];

    let legend = [];

    if (AllResult?.length) {
      legend = Object.keys(result).filter(
        (axis) => typeof result[axis] !== "string"
      );
    }
    const formatLegendName = (legendName) => {
      return legendName.charAt(0).toUpperCase() + legendName.slice(1);
    };
    if (numberOfProperties === 3) {
      if (chartType !== "pie") {
        if (chartType === "Hbar") {
          setOption({
            grid: {
              bottom: "17%",
              containLabel: true,
            },
            yAxis: {
              type: "category",
              // data: chartData.xAxis,
              data: chartProperties?.xAxis?.values
                ? chartProperties?.xAxis?.values
                : chartData.xAxis,
              name: chartProperties?.yLable,
              nameLocation: "center",
              nameGap: 170,
            },
            xAxis: {
              type: "value",
              name: chartProperties?.xLable,
              nameLocation: "center",
              nameGap: 30,
            },
            tooltip: {
              trigger: "axis",
            },
            legend: {
              data: legend,
              textStyle: {
                fontSize: chartProperties?.fontSize,
                fontWeight: chartProperties?.bold ? "bold" : "normal",
                fontStyle: chartProperties?.italic ? "italic" : "normal",
                fontFamily: chartProperties?.font,
              },
              right: chartProperties.textAlignment === "Right" ? 0 : "auto",
              left: chartProperties.textAlignment === "Left" ? 0 : "auto",
            },
            dataZoom: [
              {
                show: chartProperties?.HorizontalScroll,
                height: 15,
              },
              {
                show: chartProperties?.VerticalScroll,
                yAxisIndex: 0,
                filterMode: "empty",
                width: 15,
                left: "95%",
              },
              {
                type: "inside",
                filterMode: "none",
                yAxisIndex: 0,
              },
            ],
            series: [
              {
                name: legend[0],
                type: chartType === "Hbar" && "bar",
                data: chartData?.zAxis,
                label: {
                  show: chartProperties?.DataLabel,
                },
                itemStyle: {
                  color: chartProperties?.chartColor,
                },
              },
              {
                name: legend[1],
                type: chartType === "Hbar" && "bar",
                // yAxisIndex: 1,
                data: chartData?.yAxis,
                label: {
                  show: chartProperties?.DataLabel,
                },
                itemStyle: {
                  color: chartProperties?.chartColorSecond,
                },
              },
            ],
          });
        } else if (chartType === "LineStackBar") {
          setOption({
            grid: {
              bottom: "15%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              data: chartProperties?.xAxis?.values
                ? chartProperties?.xAxis?.values
                : chartData.xAxis,
              // data: chartData?.xAxis,
              name: chartProperties?.xLable,
              nameLocation: "center",
              nameGap: 30,
            },
            yAxis: {
              type: "value",
              name: chartProperties?.yLable,
              // nameLocation: "end",
              // nameGap: 25,
              nameLocation: "center",
              nameGap: 80,
            },

            tooltip: {
              trigger: "axis",
            },
            legend: {
              data: legend,
              formatter: formatLegendName,
              textStyle: {
                fontSize: chartProperties?.fontSize,
                fontWeight: chartProperties?.bold ? "bold" : "normal",
                fontStyle: chartProperties?.italic ? "italic" : "normal",
                fontFamily: chartProperties?.font,
              },
              right: chartProperties.textAlignment === "Right" ? 0 : "auto",
              left: chartProperties.textAlignment === "Left" ? 0 : "auto",
            },
            dataZoom: [
              {
                show: chartProperties?.HorizontalScroll,
                height: 15,
              },
              {
                show: chartProperties?.VerticalScroll,
                yAxisIndex: 0,
                filterMode: "empty",
                width: 15,
                left: "95%",
              },
              {
                type: "inside",
                filterMode: "none",
              },
            ],
            series: [
              {
                name: legend[0],
                type: "line",
                data: chartData?.zAxis,
                label: {
                  show: chartProperties?.DataLabel,
                },
                itemStyle: {
                  color: chartProperties?.chartColor,
                },
              },
              {
                name: legend[1],
                type: "bar",
                // yAxisIndex: 1,
                data: chartData?.yAxis,
                label: {
                  show: chartProperties?.DataLabel,
                  // position: "top",
                },
                itemStyle: {
                  // color: chartProperties?.chartColor
                  //   ? chartProperties?.chartColor
                  //   : chartProperty.chartColor,
                  color: chartProperties?.chartColorSecond,
                },
              },
            ],
          });
        } else if (chartType === "Aline") {
          setOption({
            grid: {
              containLabel: true,
            },
            xAxis: {
              type: "category",
              // data: chartData?.xAxis,
              data: chartProperties?.xAxis?.values
                ? chartProperties?.xAxis?.values
                : chartData.xAxis,
              name: chartProperties?.xLable,
              nameLocation: "center",
              nameGap: 30,
            },
            yAxis: {
              type: "value",
              name: chartProperties?.yLable,
              nameLocation: "center",
              nameGap: 80,
            },

            tooltip: {
              trigger: "axis",
            },
            legend: {
              data: legend,
              formatter: formatLegendName,

              textStyle: {
                fontSize: chartProperties?.fontSize,
                fontWeight: chartProperties?.bold ? "bold" : "normal",
                fontStyle: chartProperties?.italic ? "italic" : "normal",
                fontFamily: chartProperties?.font,
              },
              right: chartProperties.textAlignment === "Right" ? 0 : "auto",
              left: chartProperties.textAlignment === "Left" ? 0 : "auto",
            },
            dataZoom: [
              {
                show: chartProperties?.HorizontalScroll,
                height: 15,
              },
              {
                show: chartProperties?.VerticalScroll,
                yAxisIndex: 0,
                filterMode: "empty",
                width: 15,
                left: "95%",
              },
              {
                type: "inside",
                filterMode: "none",
              },
            ],
            series: [
              {
                name: legend[0],
                type: chartType === "Aline" && "line",
                data: chartData?.yAxis,
                areaStyle: {
                  color: "#A5E1F8",
                },
                label: {
                  show: chartProperties?.DataLabel,
                },
                itemStyle: {
                  color: chartProperties?.chartColor,
                },
              },
              {
                name: legend[1],
                type: chartType === "Aline" && "line",
                // yAxisIndex: 1,
                data: chartData?.zAxis,
                areaStyle: {
                  color: "#BCEAA7",
                },
                label: {
                  show: chartProperties?.DataLabel,
                },
                itemStyle: {
                  color: chartProperties?.chartColorSecond,
                },
              },
            ],
          });
          // } else if (chartType === "TreeMap") {
          //   const transformData = (inputData) => {
          //     const groupedData = {};

          //     inputData.forEach((item) => {
          //       const { channels, website, sum: revenue } = item;

          //       if (!groupedData[channels]) {
          //         groupedData[channels] = {
          //           name: channels,
          //           value: 0,
          //           children: [],
          //         };
          //       }

          //       groupedData[channels].value += revenue;

          //       groupedData[channels].children.push({
          //         name: website,
          //         value: revenue,
          //       });
          //     });

          //     return Object.values(groupedData);
          //   };
          //   const transformedData = transformData(response?.data?.data?.result);
          //   console.log("transformData", transformedData);
          //   setOption({
          //     series: [
          //       {
          //         type: chartType === "TreeMap" && "treemap",
          //         data: transformedData,
          //       },
          //     ],
          //     xAxis: {
          //       show: false,
          //     },
          //     yAxis: {
          //       show: false,
          //     },
          //   })
          // } else {
          setOption({
            grid: {
              containLabel: true,
            },
            xAxis: {
              type: "category",
              // data: chartData?.xAxis,
              data: chartProperties?.xAxis?.values
                ? chartProperties?.xAxis?.values
                : chartData.xAxis,
              name: chartProperties?.xLable,
              nameLocation: "center",
              nameGap: 30,
            },
            yAxis: {
              type: "value",
              name: chartProperties?.yLable,
              nameLocation: "center",
              nameGap: 80,
            },

            tooltip: {
              trigger: "axis",
            },
            legend: {
              data: legend,
              formatter: formatLegendName,

              textStyle: {
                fontSize: chartProperties?.fontSize,
                fontWeight: chartProperties?.bold ? "bold" : "normal",
                fontStyle: chartProperties?.italic ? "italic" : "normal",
                fontFamily: chartProperties?.font,
              },
              right: chartProperties.textAlignment === "Right" ? 0 : "auto",
              left: chartProperties.textAlignment === "Left" ? 0 : "auto",
            },
            dataZoom: [
              {
                show: chartProperties?.HorizontalScroll,
                height: 15,
              },
              {
                show: chartProperties?.VerticalScroll,
                yAxisIndex: 0,
                filterMode: "empty",
                width: 15,
                left: "95%",
              },
              {
                type: "inside",
                filterMode: "none",
              },
            ],
            series: [
              {
                name: legend[0],
                // name: chartProperties?.yLable,
                type: chartType,
                data: chartData?.zAxis,
                label: {
                  show: chartProperties?.DataLabel,
                },
                itemStyle: {
                  color: chartProperties?.chartColor,
                },
              },
              {
                name: legend[1],
                // name: chartProperties?.yLable,
                type: chartType,
                data: chartData?.yAxis,
                label: {
                  show: chartProperties?.DataLabel,
                },
                itemStyle: {
                  color: chartProperties?.chartColorSecond,
                },
              },
            ],
          });
        }
      }
    } else if (numberOfProperties === 2) {
      if (chartType === "pie") {
        setOption({
          title: {
            left: "center",
          },
          tooltip: {
            trigger: "item",
          },
          series: [
            {
              name: "value",
              type: chartType,
              radius: "50%",
              data: transformDataForPieChart(chartData.xAxis, chartData.yAxis),
              label: {
                show: chartProperties?.DataLabel,
                position: "outside",
              },
            },
          ],
        });
      } else if (chartType === "Hbar") {
        setOption({
          grid: {
            bottom: "19%",
            containLabel: true,
          },
          title: {
            left: "center",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            data: legend,
            formatter: formatLegendName,

            // bottom: 0,
            // left: "center",
            textStyle: {
              fontSize: chartProperties?.fontSize,
              fontWeight: chartProperties?.bold ? "bold" : "normal",
              fontStyle: chartProperties?.italic ? "italic" : "normal",
              fontFamily: chartProperties?.font,
            },
            right: chartProperties.textAlignment === "Right" ? 0 : "auto",
            left: chartProperties.textAlignment === "Left" ? 0 : "auto",
          },
          xAxis: {
            type: "value",
            name: chartProperties?.xLable,
            nameLocation: "center",
            nameGap: 40,
            show: true,
          },
          yAxis: {
            type: "category",
            // data: chartData.xAxis,
            data: chartProperties?.xAxis?.values
              ? chartProperties?.xAxis?.values
              : chartData.xAxis,
            name: chartProperties?.yLable,
            nameLocation: "center",
            nameGap: 170,
            show: true,

            // axisLabel: {
            //   interval: 0,
            //   rotate: 30
            // },
          },
          dataZoom: [
            {
              show: chartProperties?.HorizontalScroll,
              height: 15,
            },
            {
              show: chartProperties?.VerticalScroll,
              yAxisIndex: 0,
              filterMode: "empty",
              width: 15,
              left: "95%",
            },
            {
              type: "inside",
              filterMode: "none",
              yAxisIndex: 0,
            },
          ],
          series: [
            {
              name: legend[0],
              type: chartType === "Hbar" && "bar",
              data: chartData.yAxis,
              label: {
                show: chartProperties?.DataLabel,
                // position: "right",
              },
              itemStyle: {
                color: chartProperties?.chartColor,
              },
            },
          ],
        });
      } else if (chartType === "Aline") {
        setOption({
          grid: {
            containLabel: true,
          },
          title: {
            left: "center",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            data: legend,
            formatter: formatLegendName,

            // bottom: 0,
            // left: "center",
            textStyle: {
              fontSize: chartProperties?.fontSize,
              fontWeight: chartProperties?.bold ? "bold" : "normal",
              fontStyle: chartProperties?.italic ? "italic" : "normal",
              fontFamily: chartProperties?.font,
            },
            right: chartProperties.textAlignment === "Right" ? 0 : "auto",
            left: chartProperties.textAlignment === "Left" ? 0 : "auto",
          },
          yAxis: {
            type: "value",
            name: chartProperties?.yLable,
            nameLocation: "center",
            nameGap: 80,
            show: true,
          },
          xAxis: {
            type: "category",
            // data: chartData.xAxis,
            data: chartProperties?.xAxis?.values
              ? chartProperties?.xAxis?.values
              : chartData.xAxis,
            name: chartProperties?.xLable,
            nameLocation: "center",
            nameGap: 30,
            show: true,
          },
          dataZoom: [
            {
              show: chartProperties?.HorizontalScroll,
              height: 15,
            },
            {
              show: chartProperties?.VerticalScroll,
              yAxisIndex: 0,
              filterMode: "empty",
              width: 15,
              left: "95%",
            },
            {
              type: "inside",
              filterMode: "none",
            },
          ],
          series: [
            {
              name: legend[0],
              type: chartType === "Aline" && "line",
              data: chartData.yAxis,
              areaStyle: {},
              label: {
                show: chartProperties?.DataLabel,
              },
              itemStyle: {
                color: chartProperties?.chartColor,
              },
            },
          ],
        });
      } else if (chartType === "TreeMap") {
        const data = response?.data?.data?.result?.map((val) => {
          const keys = Object.keys(val);

          let name = "";
          let value = 0;
          for (const key in val) {
            const currentValue = val[key];

            if (typeof currentValue === "string") {
              name = currentValue;
            }

            if (typeof currentValue === "number") {
              value = currentValue;
            }
          }

          return {
            name,
            value,
          };
        });
        setOption({
          tooltip: {
            trigger: "item",
          },
          series: [
            {
              type: chartType === "TreeMap" && "treemap",
              data: data,
              label: {
                show: chartProperties?.DataLabel,
              },
            },
          ],
          // xAxis: {
          //   show: false,
          // },
          // yAxis: {
          //   show: false,
          // },
        });
      } else {
        setOption({
          grid: {
            containLabel: true,
          },
          title: {
            left: "center",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            data: legend,
            formatter: formatLegendName,

            // bottom: 0,
            // left: "center",
            textStyle: {
              fontSize: chartProperties?.fontSize,
              fontWeight: chartProperties?.bold ? "bold" : "normal",
              fontStyle: chartProperties?.italic ? "italic" : "normal",
              fontFamily: chartProperties?.font,
            },
            right: chartProperties.textAlignment === "Right" ? 0 : "auto",
            left: chartProperties.textAlignment === "Left" ? 0 : "auto",
          },
          yAxis: {
            type: "value",
            name: chartProperties?.yLable,
            nameLocation: "center",
            nameGap: 80,
            show: true,
          },
          xAxis: {
            type: "category",
            // data: chartData.xAxis,
            data: chartProperties?.xAxis?.values
              ? chartProperties?.xAxis?.values
              : chartData.xAxis,
            name: chartProperties?.xLable,
            nameLocation: "center",
            nameGap: 30,
            show: true,
          },
          dataZoom: [
            {
              show: chartProperties?.HorizontalScroll,
              height: 15,
              bottom: 13,
              xAxisIndex: 0,
            },
            {
              show: chartProperties?.VerticalScroll,
              yAxisIndex: 0,
              filterMode: "empty",
              width: 15,
              left: "95%",
            },
            {
              type: "inside",
              filterMode: "none",
            },
          ],
          series: [
            {
              name: legend[0],
              type: chartType,
              data: chartData.yAxis,
              label: {
                show: chartProperties?.DataLabel,
              },
              itemStyle: {
                color: chartProperties?.chartColor,
              },
            },
          ],
        });
      }
    } else if (numberOfProperties === 1) {
      setOption({
        grid: {
          // bottom: '15%',
          containLabel: true,
        },
        series: [
          {
            type: "gauge",
            detail: { formatter: "{value}" },
            data: [{ value: 75, name: chartData.xAxis }],
            label: {
              show: chartProperties?.DataLabel,
            },
            itemStyle: {
              color: chartProperties?.chartColor,
            },
          },
        ],
      });
    } else {
      // const result = response.data?.data?.result[0];
      // const AllResult = response.data?.data?.result;
      // let xAxisVal;
      // let xAxis;
      // if (result) {
      //   for (const key in result) {
      //     if (typeof result[key] === "string") {
      //       xAxis = key;
      //       xAxisVal = AllResult?.map((entry) => entry[key]);
      //       break;
      //     }
      //   }
      // }
      // let legend = AllResult?.length
      //   ? Object.keys(result).filter((axis) => xAxis !== axis)
      //   : [];

      if (chartType === "StackLine") {
        setOption({
          grid: {
            bottom: "13%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            nameLocation: "center",
            nameGap: 30,
            // data: xAxisVal || [],
            data: chartProperties?.xAxis?.values
              ? chartProperties?.xAxis?.values
              : xAxisVal,
            name: chartProperties?.xLable,
          },
          yAxis: {
            type: "value",
            name: chartProperties?.yLable,
            nameLocation: "center",
            nameGap: 80,
          },

          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: legend,
            formatter: formatLegendName,

            textStyle: {
              fontSize: chartProperties?.fontSize,
              fontWeight: chartProperties?.bold ? "bold" : "normal",
              fontStyle: chartProperties?.italic ? "italic" : "normal",
              fontFamily: chartProperties?.font,
            },
            right: chartProperties.textAlignment === "Right" ? 0 : "auto",
            left: chartProperties.textAlignment === "Left" ? 0 : "auto",
          },
          dataZoom: [
            {
              show: chartProperties?.HorizontalScroll,
              height: 15,
              bottom: 13,
              xAxisIndex: 0,
            },
            {
              show: chartProperties?.VerticalScroll,
              yAxisIndex: 0,
              filterMode: "empty",
              width: 15,
              left: "95%",
            },
            {
              type: "inside",
              filterMode: "none",
            },
          ],
          series: AllResult?.length
            ? Object.keys(AllResult[0])?.map((key) => ({
                name: key,
                type: "line",
                // stack: "Total",
                data: AllResult?.map((obj) => {
                  // if (chartProperties.roundOff) {
                  //   const value = parseFloat(obj[key]);
                  //   return isNaN(value) ? obj[key] : value.toFixed(2);
                  // }
                  // if (chartProperties?.roundOff) {
                  //   const value = obj[key];
                  //   if (!isNaN(Number(value))) {
                  //     return value ? value?.toFixed(2) : value;
                  //   } else {
                  //     return value;
                  //   }
                  // } else {
                  //   return obj[key];
                  // }
                  if (chartProperties?.roundOff) {
                    const value = obj[key];
                    const numValue = Number(value);
                    if (!isNaN(numValue)) {
                      return value ? numValue.toFixed(2) : value;
                    } else {
                      return value;
                    }
                  } else {
                    return obj[key];
                  }
                }),
                label: {
                  show: chartProperties?.DataLabel,
                  position: "outside",
                },
              }))
            : [],
        });
      } else if (chartType === "StackBar") {
        setOption({
          grid: {
            bottom: "13%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: true,
            nameLocation: "center",
            nameGap: 30,
            // data: xAxisVal || [],
            data: chartProperties?.xAxis?.values
              ? chartProperties?.xAxis?.values
              : xAxisVal,
            name: chartProperties?.xLable,
          },
          yAxis: {
            type: "value",
            name: chartProperties?.yLable,
            nameLocation: "center",
            nameGap: 80,
          },

          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: legend,
            formatter: formatLegendName,

            textStyle: {
              fontSize: chartProperties?.fontSize,
              fontWeight: chartProperties?.bold ? "bold" : "normal",
              fontStyle: chartProperties?.italic ? "italic" : "normal",
              fontFamily: chartProperties?.font,
            },
            right: chartProperties.textAlignment === "Right" ? 0 : "auto",
            left: chartProperties.textAlignment === "Left" ? 0 : "auto",
          },
          dataZoom: [
            {
              show: chartProperties?.HorizontalScroll,
              height: 15,
              bottom: 13,
              xAxisIndex: 0,
            },
            {
              show: chartProperties?.VerticalScroll,
              yAxisIndex: 0,
              filterMode: "empty",
              width: 15,
              left: "95%",
            },
            {
              type: "inside",
              filterMode: "none",
            },
          ],
          series: AllResult?.length
            ? Object.keys(AllResult[0]).map((key) => ({
                name: key,
                type: "bar",
                // stack: "Total",
                data: AllResult.map((obj) => {
                  // if (chartProperties.roundOff) {
                  //   const value = parseFloat(obj[key]);
                  //   return isNaN(value) ? obj[key] : value.toFixed(2);
                  // } else {
                  //   return obj[key];
                  // }
                  // if (chartProperties?.roundOff) {
                  //   const value = obj[key];
                  //   if (!isNaN(Number(value))) {
                  //     return value ? value?.toFixed(2) : value;
                  //   } else {
                  //     return value;
                  //   }
                  // } else {
                  //   return obj[key];
                  // }
                  if (chartProperties?.roundOff) {
                    const value = obj[key];
                    const numValue = Number(value);
                    if (!isNaN(numValue)) {
                      return value ? numValue.toFixed(2) : value;
                    } else {
                      return value;
                    }
                  } else {
                    return obj[key];
                  }
                }),
                label: {
                  show: chartProperties?.DataLabel,
                  position: "outside",
                },
              }))
            : [],
        });
      } else if (chartType === "ClusterBar") {
        setOption({
          grid: {
            bottom: "13%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: true,
            nameLocation: "center",
            nameGap: 30,
            // data: xAxisVal || [],
            data: chartProperties?.xAxis?.values
              ? chartProperties?.xAxis?.values
              : xAxisVal,
            name: chartProperties?.xLable,
          },
          yAxis: {
            type: "value",
            name: chartProperties?.yLable,
            nameLocation: "center",
            nameGap: 80,
          },

          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: legend,
            formatter: formatLegendName,
            textStyle: {
              fontSize: chartProperties?.fontSize,
              fontWeight: chartProperties?.bold ? "bold" : "normal",
              fontStyle: chartProperties?.italic ? "italic" : "normal",
              fontFamily: chartProperties?.font,
            },
            right: chartProperties.textAlignment === "Right" ? 0 : "auto",
            left: chartProperties.textAlignment === "Left" ? 0 : "auto",
          },
          dataZoom: [
            {
              show: chartProperties?.HorizontalScroll,
              height: 15,
              bottom: 13,
              xAxisIndex: 0,
            },
            {
              show: chartProperties?.VerticalScroll,
              yAxisIndex: 0,
              filterMode: "empty",
              width: 15,
              left: "95%",
            },
            {
              type: "inside",
              filterMode: "none",
            },
          ],
          series: AllResult?.length
            ? Object.keys(AllResult[0]).map((key) => ({
                name: key,
                type: "bar",
                // stack: false,
                data: AllResult.map((obj) => {
                  // if (chartProperties.roundOff) {
                  //   const value = parseFloat(obj[key]);
                  //   return isNaN(value) ? obj[key] : value.toFixed(2);
                  // } else {
                  //   return obj[key];
                  // }
                  // if (chartProperties?.roundOff) {
                  //   const value = obj[key];
                  //   if (!isNaN(Number(value))) {
                  //     return value ? value?.toFixed(2) : value;
                  //   } else {
                  //     return value;
                  //   }
                  // } else {
                  //   return obj[key];
                  // }
                  if (chartProperties?.roundOff) {
                    const value = obj[key];
                    const numValue = Number(value);
                    if (!isNaN(numValue)) {
                      return value ? numValue.toFixed(2) : value;
                    } else {
                      return value;
                    }
                  } else {
                    return obj[key];
                  }
                }),
                label: {
                  show: chartProperties?.DataLabel,
                  position: "outside",
                },
              }))
            : [],
        });
      }
    }
  }, [chartType, chartData, chartProperties]);
  console.log(option, "option");
  const preFix = widgetProp?.preFix;
  const postFix = widgetProp?.postFix;
  const decimalPlaces = widgetProp?.decimalPlaces;
  const percentage = widgetProp?.percentage === "yes";

  const formatValue = (val) => {
    let formattedValue = val;
    if (percentage && decimalPlaces !== null) {
      formattedValue = val * 100;
      return `${formattedValue.toFixed(decimalPlaces)}%`;
    } else if (decimalPlaces !== null) {
      formattedValue = parseFloat(val).toFixed(decimalPlaces);
    } else if (percentage) {
      formattedValue = val * 100;
      return `${formattedValue}%`;
    }
    return formattedValue;
  };
  const formattedXAxis = formatValue(chartData?.xAxis);
  console.log(loading, "loading");

  if (!response) {
    return <Loader />;
  }
  const loadingOption = {
    text: "loading...",
    color: "#4413c2",
    textColor: "#270240",
    maskColor: "rgba(194, 88, 86, 0.3)",
    zlevel: 0,
  };
  function onChartReady(echarts) {
    setTimeout(function () {
      echarts.hideLoading();
      setIsChartLoading(false);
    }, 0);
  }

  return (
    <Box sx={{ height: "100%" }}>
      {numberOfProperties === 1 ? (
        <Box
          className="card-bg"
          sx={{
            height: "100%",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <Card
              sx={{
                boxShadow: 1,
                borderRadius: "4px",
                width: "100%",
                height: "100%",
                padding: 0,
                display: "flex",
                // alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardContent
                className="drag-handle"
                sx={{
                  cursor: "move",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: "0px",
                }}
              >
                <Box
                  sx={{
                    width: "50px",
                    height: "50px",
                    fontSize: "24px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                    backgroundColor:
                      widgetProp?.iconBackgroundColor || "#DEDEDE",
                  }}
                >
                  <i
                    style={{
                      color: widgetProp?.iconColor || "#000000",
                    }}
                    className={widgetProp?.icon || "fas fa-hashtag"}
                  ></i>
                </Box>
                <Box
                  sx={{
                    overflow: "hidden",
                    marginLeft: "10px",
                  }}
                >
                  <Tooltip title={titleProperties?.text}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        padding: "0px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      color="textSecondary"
                    >
                      {titleProperties?.text}
                    </Typography>
                  </Tooltip>
                  <Tooltip title={chartData?.xAxis}>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        padding: "0px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {preFix} {formattedXAxis.toLocaleString()} {postFix}
                    </Typography>
                  </Tooltip>
                </Box>
              </CardContent>

              {prop?.id && !prop?.mode && (
                <Box
                  sx={{
                    cursor: "pointer",
                    paddingTop: "10px",
                    position: "absolute",
                    top: "5px",
                    right: "15px",
                    position: "absolute",
                    top: "5px",
                    right: "15px",
                  }}
                >
                  {idpRoleAccess(role) && (
                    <EditIcon
                      sx={{ fontSize: "17px" }}
                      onClick={() =>
                        navigate(`/update-single-answer-board/${prop?.id}`)
                      }
                    />
                  )}
                </Box>
              )}
            </Card>
          </Box>
        </Box>
      ) : (
        <Box>
          <ReactECharts
            key={key}
            onChartReady={onChartReady}
            showLoading={isChartLoading}
            option={option}
            style={{ height: chartCardHeight || "550px", width: "100%" }}
            loadingOption={loadingOption}
          />
        </Box>
      )}
    </Box>
  );
};
export default Chart;
