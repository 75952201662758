export const selectCatalogData = (state) => {
  return state.catalogData;
};

export const selectDataBase = (state) => {
  return state.dataBase;
};

export const selectConnectionData = (state) => {
  return state.connectionData;
};

export const selectTestConnection = (state) => {
  return state.testConnection;
};

export const selectAllConnection = (state) => {
  return state.allConnection;
};

export const selectAllCatlog = (state) => {
  return state.allCatlog;
};

export const selectQueryResponse = (state) => {
  return state.queryResponse;
};

export const selectAskQuestion = (state) => {
  return state.askQuestion;
};

export const selectPiviotData = (state) => {
  return state.piviotData;
};

export const selectAllBoards = (state) => {
  return state.allBoards;
};

export const selectFilter = (state) => {
  return state.filter;
};

export const selectWidget = (state) => {
  return state.widgetProp;
};

export const selectTitleProperties = (state) => {
  return state.titleProperties;
};

export const selectChartProperties = (state) => {
  return state.chartProperties;
};

export const selectUseFromRedux = (state) => {
  return state.useFromRedux;
};

export const selectSideBarOpen = (state) => {
  return state.sideBarOpen;
};

export const selectUpdateObjectData = (state) => {
  return state.updateObjectData;
};

export const selectUserDetails = (state) => {
  return state.userDetails;
};

export const selectAllUsers = (state) => {
  return state.allUsers;
};

export const selectSelectedData = (state) => {
  return state.selectedData;
};