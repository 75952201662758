import { Box } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { setFilter } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { selectFilter } from "../../redux/selectors";
import { modifyDataType } from "../ModifyDataType";

const DatePicker = ({ elm }) => {
  const FilterData = useSelector(selectFilter);
  const dispatch = useDispatch();

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    FilterData.forEach((filter) => {
      if (filter.colName === elm.column) {
        setDateRange([
          {
            startDate: filter.val[0] ? new Date(filter.val[0]) : new Date(),
            endDate: filter.val[1] ? new Date(filter.val[1]) : new Date(),
            key: "selection",
          },
        ]);
      }
    });
  }, []);
  

  const staticRanges = [
    {
      label: "Today",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
    },
    {
      label: "Yesterday",
      range: () => {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
        const startDate = new Date(endDate);
        startDate.setHours(0, 0, 0, 0);
        return { startDate, endDate };
      },
    },
    {
      label: "Last 7 Days",
      range: () => {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(endDate.getDate() - 6);
        return { startDate, endDate };
      },
    },
    {
      label: "Last 30 Days",
      range: () => {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(endDate.getDate() - 29);
        return { startDate, endDate };
      },
    },
    {
      label: "This Month",
      range: () => {
        const endDate = new Date();
        const startDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          1
        );
        return { startDate, endDate };
      },
    },
  ];

  const handleDateRangeChange = (item) => {
    if (item && item.selection) {
      setDateRange([item.selection]);
    }
    formattedDateRange([item.selection]);
  };

  

  const formattedDateRange = (selectedDate) => {

    const date = [];
    const formattedDate = {
      fromDate: moment(selectedDate[0].startDate).format("YYYY-MM-DD"),
      endDate: moment(selectedDate[0].endDate).format("YYYY-MM-DD"),
    };
    date.push(formattedDate.fromDate);
    if (formattedDate.fromDate !== formattedDate.endDate) {
      date.push(formattedDate.endDate);
    }

    //dispatch
    const existingFilterIndex = FilterData.findIndex(
      (filter) => filter.colName === elm.column
    );

    if (existingFilterIndex !== -1) {
      const updatedFilterData = [...FilterData];
      updatedFilterData[existingFilterIndex].val = date;
      updatedFilterData[existingFilterIndex].type = modifyDataType(elm.dataType) === "Date" && formattedDate.fromDate !== formattedDate.endDate ? "range" : "";

      dispatch(setFilter(updatedFilterData));
    } else {
      dispatch(
        setFilter([
          ...FilterData,
          {
            colName: elm.column,
            type: modifyDataType(elm.dataType) === "Date" && formattedDate.fromDate !== formattedDate.endDate ? "range" : "",
            val: date,
          },
        ])
      );
    }
  };
  return (
    <Box>
      <DateRangePicker ranges={dateRange} onChange={handleDateRangeChange} />
    </Box>
  );
};

export default DatePicker;
