import {
  Box,
  Button,
  Checkbox,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  selectAllCatlog,
  selectAskQuestion,
  selectSelectedData,
  selectUpdateObjectData,
} from "../../redux/selectors";
import {
  getBoardsById,
  getCatalogDetails,
  getColumns,
  getSingleObject,
} from "../../services";
import CommonDialog from "../../utils/Dialog";
import {
  setAllBoards,
  setAskQuestion,
  setQueryResponse,
  setSelectedData,
  setUpdateObjectData,
} from "../../redux/actions";
import "./style.css";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-github";
import DatePicker from "../../utils/DataPicker";
import { CalendarMonth } from "@mui/icons-material";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import { modifyDataType } from "../../utils/ModifyDataType";

const SearchHeader = ({
  queryRunHandler,
  type,
  update,
  handleApiReq,
  isLoading,
  catalogDetails,
  // setselectedData,
}) => {
  const listRef = useRef(null);
  const AllCatlogList = useSelector(selectAllCatlog);
  const AskQuestionData = useSelector(selectAskQuestion);
  const SelectedData = useSelector(selectSelectedData);
  const UpdateObjectData = useSelector(selectUpdateObjectData);
  const [showOptions, setShowOptions] = useState(false);
  const [searchInput, setSearchInput] = useState();
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [selectedCatalog, setSelectedCatalog] = useState(null);
  const [catalogChangeDialogOpen, setCatalogChangeDialogOpen] = useState(false);
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [anchorElColumn, setAnchorElColumn] = useState(null);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  // const [selectedCol, setselectedCol] = useState();
  // const [colData, setcolData] = useState();

  // useEffect(()=>{
  //   console.log(catalogDetails);
  //   handleCatalogChange(catalogDetails)
  // })

  useEffect(() => {
    if (update && AskQuestionData?.catalog) {
      handleCatalogChange(AskQuestionData?.catalog);
    }
    dispatch(setAskQuestion({ ...AskQuestionData, selectedCol: "" }));
    dispatch(setSelectedData([]));
  }, [update, AskQuestionData?.catalog]);

  const handleDateRangeChange = (item) => {
    if (item && item.selection) {
      setDateRange([item.selection]);
    }
    formattedDateRange([item.selection]);
  };

  const formattedDateRange = (selectedDate) => {
    const date = [];
    const formattedDate = {
      fromDate: moment(selectedDate[0].startDate).format("YYYY-MM-DD"),
      endDate: moment(selectedDate[0].endDate).format("YYYY-MM-DD"),
    };
    date.push(formattedDate.fromDate);
    if (formattedDate.fromDate !== formattedDate.endDate) {
      date.push(formattedDate.endDate);
    }

    dispatch(
      setSelectedData([
        {
          colName: AskQuestionData?.selectedCol?.name,
          type:
            modifyDataType(AskQuestionData?.selectedCol?.type) === "Date" &&
            formattedDate.fromDate !== formattedDate.endDate
              ? "range"
              : "",
          val: date,
        },
      ])
    );
  };

  const handleCalendarButtonClick = (event) => {
    setAnchorElCalendar(event.currentTarget);
  };

  const handleColumnButtonClick = (event) => {
    setAnchorElColumn(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElCalendar(null);
    setAnchorElColumn(null);
  };
  const isCalendarPopoverOpen = Boolean(anchorElCalendar);
  const isColumnPopoverOpen = Boolean(anchorElColumn);

  const dispatch = useDispatch();
  console.log(update, "checking update in");

  useEffect(() => {
    if (AskQuestionData?.catalog?.recentQueries) {
      const messages = AskQuestionData?.catalog?.recentQueries
        .filter(
          (msg) => msg && msg.message && !filteredOptions.includes(msg.message)
        )
        .map((msg) => msg.message);
      setFilteredOptions([...messages]);
    }
  }, [AskQuestionData?.catalog?.recentQueries]);

  useEffect(() => {
    if (AskQuestionData?.askFromQuery) {
      setSearchInput(AskQuestionData?.sql);
    } else {
      setSearchInput(AskQuestionData?.question);
    }
  }, []);

  const handleCatalogChange = async (value) => {
    // debugger
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    if (value) {
      dispatch(
        setAskQuestion({
          catalog: value,
        })
      );
      console.log(value, "valueeee");
      const data = {
        data: {
          catalogId: value?._id,
        },
      };

      const res = await getCatalogDetails(data);
      dispatch(
        setAskQuestion({
          columnsNames: res?.data?.data?.columns,
        })
      );

      const columnData = {
        tablename: res?.data?.data?.result?.metaData?.table?.name,
        id: res?.data?.data?.result?.connectionId,
      };

      const colRes = await getColumns(columnData);
      // setcolData(colRes?.data?.data?.result);
      dispatch(setAskQuestion({ colData: colRes?.data?.data?.result }));
    } else {
      dispatch(
        setAskQuestion({
          catalog: null,
        })
      );
      dispatch(
        setAskQuestion({
          columnsNames: [],
        })
      );
    }
    dispatch(
      setAskQuestion({
        question: "",
        sql: "",
      })
    );
    setSearchInput("");
    dispatch(
      setQueryResponse({
        Response: "",
      })
    );
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    if (update) {
      dispatch(
        setUpdateObjectData({
          ObjectData: {
            ...UpdateObjectData.ObjectData,
            data: {
              ...UpdateObjectData?.ObjectData.data,
              result: {
                ...UpdateObjectData?.ObjectData.data?.result,
                question: input,
              },
            },
          },
        })
      );
    }

    setSearchInput(input);
    const messages = AskQuestionData.catalog.recentQueries
      .filter(
        (msg) => msg && msg.message
        // && !filteredOptions.includes(msg.message)
      )
      .map((msg) => msg.message);

    const filtered = messages.filter((option) =>
      option?.toLowerCase().includes(input?.toLowerCase())
    );

    dispatch(
      setAskQuestion({
        [AskQuestionData.askFromQuery ? "sql" : "question"]: input,
      })
    );

    setFilteredOptions(filtered);
  };

  const editorRef = useRef(null);

  const selected = (result) => {
    const inputElement = document.getElementById("searchInput");
    if (!inputElement) {
      const selectionRange = editorRef.current.editor.getSelectionRange();
      const session = editorRef.current.editor.getSession();
      const prefix = session.getDocument().getTextRange({
        start: { row: 0, column: 0 },
        end: selectionRange.start,
      });
      const suffix = session.getDocument().getTextRange({
        start: selectionRange.end,
        end: { row: session.getDocument().getLength(), column: 0 },
      });
      const words = result.split(/\s+/);
      let updatedQuery;
      if (words.length === 1) {
        const prefixWords = prefix.split(/\s+/);
        const lastWordOfPrefix = prefixWords[prefixWords.length - 1];
        const isLastWordInResult = result.startsWith(lastWordOfPrefix);
        if (isLastWordInResult) {
          updatedQuery =
            prefix.substring(0, prefix.lastIndexOf(lastWordOfPrefix)) +
            result +
            suffix;
        } else {
          updatedQuery = prefix + result + suffix;
        }
      }
      // const updatedQuery = prefix + result + suffix;
      if (!AskQuestionData.askFromQuery) {
        dispatch(setAskQuestion({ question: updatedQuery }));
      } else {
        dispatch(setAskQuestion({ sql: updatedQuery }));
      }
      setSearchInput(updatedQuery);
      session.replace(selectionRange, result);
      editorRef.current.editor.focus();
    } else {
      const selectionStart = inputElement.selectionStart;
      const selectionEnd = inputElement.selectionEnd;
      const prefix = AskQuestionData.askFromQuery
        ? AskQuestionData.sql.substring(0, selectionStart)
        : AskQuestionData.question.substring(0, selectionStart);
      const suffix = AskQuestionData.askFromQuery
        ? AskQuestionData.sql.substring(selectionEnd)
        : AskQuestionData.question.substring(selectionEnd);
      const words = result.split(/\s+/);
      let updatedQuery;
      if (words.length > 1) {
        updatedQuery = result;
      } else {
        const prefixWords = prefix.split(/\s+/);
        const lastWordOfPrefix = prefixWords[prefixWords.length - 1];
        const isLastWordInResult = result.startsWith(lastWordOfPrefix);
        if (isLastWordInResult) {
          updatedQuery =
            prefix.substring(0, prefix.lastIndexOf(lastWordOfPrefix)) + result;
        } else {
          updatedQuery = prefix + result + suffix;
        }
      }
      if (!AskQuestionData.askFromQuery) {
        dispatch(setAskQuestion({ question: updatedQuery }));
      } else {
        dispatch(setAskQuestion({ sql: updatedQuery }));
      }
      setSearchInput(updatedQuery);
    }
    if (
      AskQuestionData.columnsNames.includes(result) &&
      !AskQuestionData.selectedColum.includes(result)
    ) {
      dispatch(
        setAskQuestion({
          selectedColum: [...AskQuestionData.selectedColum, result],
        })
      );
    }
  };

  const sqlChange = (newValue) => {
    if (update) {
      dispatch(
        setUpdateObjectData({
          ObjectData: {
            ...UpdateObjectData.ObjectData,
            data: {
              ...UpdateObjectData?.ObjectData.data,
              result: {
                ...UpdateObjectData?.ObjectData.data?.result,
                query: newValue,
              },
            },
          },
        })
      );
    }
    dispatch(
      setAskQuestion({
        [AskQuestionData.askFromQuery ? "sql" : "question"]: newValue,
      })
    );
    setSearchInput(newValue);
  };

  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();

      const totalOptions = filteredOptions.length;
      let newIndex =
        e.key === "ArrowDown"
          ? (selectedOptionIndex + 1) % totalOptions
          : (selectedOptionIndex - 1 + totalOptions) % totalOptions;

      setSelectedOptionIndex(newIndex);

      const selectedResult = filteredOptions[newIndex];
      setSearchInput(selectedResult);
      dispatch(
        setAskQuestion({
          question: selectedResult,
        })
      );
    } else if (e.key === "Enter") {
      e.preventDefault();
      const selectedResult = filteredOptions[selectedOptionIndex];
      if (selectedResult) {
        queryRunHandler();
        setShowOptions(false);
      }
    } else if (e.key === "Enter" && e.ctrlKey) {
      queryRunHandler();
    }
  };

  useEffect(() => {
    if (selectedOptionIndex !== -1 && listRef.current) {
      const items = listRef.current.querySelectorAll("li");
      items.forEach((item, index) => {
        if (item.innerText === filteredOptions[selectedOptionIndex]) {
          item.classList.add("selected");
          item.scrollIntoView({ behavior: "smooth", block: "nearest" });
        } else {
          item.classList.remove("selected");
        }
      });
    }
  }, [selectedOptionIndex]);

  const confirmationDialogActions = [
    {
      label: "No",
      color: "primary",
      onClick: () => setConfirmationDialogOpen(false),
    },
    {
      label: "Yes",
      color: "warning",
      onClick: () => {
        // handleConfirmDialogClose(true);
        setConfirmationDialogOpen(false);
        setSearchInput("");
        dispatch(
          setQueryResponse({
            Response: "",
          })
        );
        dispatch(
          setAskQuestion({
            question: "",
            sql: "",
            askFromQuery: !AskQuestionData.askFromQuery,
          })
        );
      },
    },
  ];
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked && !AskQuestionData.askFromQuery) {
      setConfirmationDialogOpen(true);
    } else if (!isChecked && AskQuestionData.askFromQuery) {
      setConfirmationDialogOpen(true);
    } else {
      dispatch(
        setAskQuestion({ question: "", sql: "", askFromQuery: isChecked })
      );
    }
  };

  // const confirmCatalogChange = async (value) => {
  //   if (value && AskQuestionData.catalog) {
  //     setCatalogChangeDialogOpen(true);
  //     setSelectedCatalog(value);
  //   } else {
  //     handleCatalogChange(value);
  //   }
  // };

  const confirmCatalogChange = async (value) => {
    if (!searchInput) {
      handleCatalogChange(value);
    } else {
      setCatalogChangeDialogOpen(true);
      setSelectedCatalog(value);
    }
  };

  const catalogChangeDialogActions = [
    {
      label: "No",
      color: "primary",
      onClick: () => setCatalogChangeDialogOpen(false),
    },
    {
      label: "Yes",
      color: "warning",
      onClick: () => {
        setCatalogChangeDialogOpen(false);
        handleCatalogChange(selectedCatalog);
      },
    },
  ];

  useEffect(() => {
    if (AskQuestionData.catalog === null) {
      setSearchInput("");
    }
  }, [AskQuestionData.catalog]);

  // update New code

  // const getCatalogDetailsAsync = async () => {
  //   const data = {
  //     data: {
  //       catalogId: UpdateObjectData?.ObjectData?.data?.result?.catalogId,
  //     },
  //   };

  //   const res = await getCatalogDetails(data);
  //   dispatch(
  //     setAskQuestion({
  //       columnsNames: res?.data?.data?.columns,
  //     })
  //   );
  // };

  const handleColumnClick = (result) => {
    console.log(result, "result");
    dispatch(
      setAskQuestion({
        selectedCol: result,
      })
    );
  };
  // if(update){
  //   handleCatalogChange(catalogDetails)

  //   }

  const buttonDisabled = !(
    // typeof AskQuestionData?.selectedCol === "object" && SelectedData?.length &&
    searchInput
  )

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            width: { xs: "100%", md: "40%", lg: "50%" },
          }}
        >
          <Typography
            sx={{ color: "#424242", fontSize: "20px", fontWeight: "500" }}
          >
            {type === "update"
              ? "Update Business Question"
              : "Ask Business Question"}
          </Typography>

          <Tooltip
            title={
              "Choose your catalog, then simply type your business question as a prompt or query to receive a visualized answer based on your dataset."
            }
          >
            <HelpOutlineIcon sx={{ color: "#B6B6B6" }} />
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "space-between", md: "flex-end" },
            alignItems: "center",
            // bgcolor:"pink",
            width: { xs: "100%", md: "60%", lg: "50%" },
            gap: { xs: "0" },
          }}
        >
          <Box
            sx={{
              width: { xs: "50%", md: "50%", lg: "40%", xl: "30%" },
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkbox
              disabled={update}
              checked={AskQuestionData.askFromQuery}
              onChange={handleCheckboxChange}
            />
            {/* <Typography>Ask Using Query</Typography> */}
            <Typography
              disabled={update}
              onClick={() =>
                update
                  ? ""
                  : handleCheckboxChange({
                      target: {
                        checked: !AskQuestionData.askFromQuery,
                      },
                    })
              }
              style={{ cursor: "pointer" }}
            >
              Ask Using Query
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: "50%", md: "50%", lg: "50%", xl: "30%" },
            }}
          >
            <Autocomplete
              id="catalog"
              options={AllCatlogList ? [...AllCatlogList].reverse() : ""}
              getOptionLabel={(option) => option?.name}
              value={
                // update
                // ? AllCatlogList?.find(
                //       (option) =>
                //         option._id ===
                //         UpdateObjectData?.ObjectData?.data?.result?.catalogId
                //         )
                //   :
                AskQuestionData?.catalog
              }
              onChange={(event, value) => confirmCatalogChange(value)}
              // disabled={update}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Catalog"
                  variant="standard"
                />
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: "30px",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box>
            <Box
              sx={{
                mt: 2,
                px: "4px",
                borderRadius: "20px",
                border: AskQuestionData?.askFromQuery ? "" : "1px solid grey",
              }}
            >
              <Box sx={{ display: "flex", overflow: "hidden" }}>
                {!AskQuestionData?.askFromQuery ? (
                  <IconButton sx={{ p: "10px" }} aria-label="menu">
                    <SearchIcon fontSize="large" />
                  </IconButton>
                ) : (
                  ""
                )}
                {AskQuestionData?.askFromQuery ? (
                  <>
                    <AceEditor
                      id="editor"
                      ref={editorRef}
                      aria-label="editor"
                      readOnly={update ? "" : !AskQuestionData.catalog}
                      mode="mysql"
                      theme="github"
                      name="blah2"
                      fontSize={16}
                      minLines={15}
                      maxLines={7}
                      width="100%"
                      showPrintMargin={false}
                      showGutter
                      placeholder="Write your Query here..."
                      editorProps={{ $blockScrolling: true }}
                      setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                      }}
                      value={
                        update
                          ? UpdateObjectData?.ObjectData?.data?.result?.query
                          : searchInput
                      }
                      onChange={sqlChange}
                      onFocus={() => {
                        setShowOptions(true);
                      }}
                      onBlur={() => {
                        setShowOptions(false);
                      }}
                      // onKeyDown={(e) => {
                      //   console.log(e);
                      //   if (e.ctrlKey && (e.keyCode === 13 || e.which === 13)) { // Checking for Ctrl + Enter
                      //     queryRunHandler();
                      //   }
                      // }}
                      showLineNumbers
                    />
                  </>
                ) : (
                  <InputBase
                    id="searchInput"
                    autoComplete="off"
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search..."
                    // disabled={update ? "" : !AskQuestionData.catalog}
                    onClick={() =>
                      !AskQuestionData.catalog &&
                      document.getElementById("catalog")?.focus()
                    }
                    onChange={(e) => handleInputChange(e)}
                    value={
                      update
                        ? UpdateObjectData?.ObjectData?.data?.result?.question
                        : searchInput
                    }
                    onFocus={() => {
                      setShowOptions(true);
                    }}
                    onBlur={() => {
                      setShowOptions(false);
                    }}
                    onKeyDown={handleKeyDown}
                  />
                )}
                {isLoading && searchInput ? (
                  <Button
                    sx={{
                      width: "90px",
                      color: "black",
                      bgcolor: "#C7C8CC",
                      borderRadius: "20px",
                      marginLeft: "20px",
                      fontSize: "15px",
                      padding: "6px 20px",
                      margin: "10px",
                    }}
                    onClick={handleApiReq}
                  >
                    Kill
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "90px",
                      color: "black",
                      bgcolor: "#C7C8CC",
                      borderRadius: "20px",
                      marginLeft: "20px",
                      fontSize: "15px",
                      padding: "6px 20px",
                      margin: "10px",
                    }}
                    onClick={queryRunHandler}
                    disabled={buttonDisabled}
                  >
                    {!AskQuestionData?.askFromQuery ? "Go" : "Run Query"}
                  </Button>
                )}
              </Box>
              {showOptions && (
                <Box
                  sx={{
                    // position: "absolute",
                    backgroundColor: "#EEF2F6",
                    color: "#000",
                    border: "1px solid #D4D4D4",
                    maxHeight: "200px",
                    overflow: "auto",
                    // zIndex: "99",
                    borderRadius: "20px",
                    // marginRight: "30px",
                    px: 1,
                    pt: 1,
                  }}
                >
                  <Box>
                    Table Name -
                    <Button
                      sx={{
                        color: "black",
                        border: "1px solid black",
                        borderRadius: "50px",
                        fontSize: "10px",
                        m: 0,
                        ml: 1,
                        py: 0,
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        selected(
                          AskQuestionData?.catalog?.metaData?.table?.name
                        );
                      }}
                    >
                      {AskQuestionData?.catalog?.metaData?.table?.name}
                    </Button>
                  </Box>
                  <ul
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "5px",
                      borderBottom: " 1px solid #DDE1E6",
                    }}
                  >
                    {AskQuestionData?.columnsNames.map((result, index) => (
                      <li
                        key={index}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          selected(result);
                        }}
                        style={{
                          display: "flex",
                          padding: "0px 10px",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid black",
                          borderRadius: "50px",
                          margin: "8px 0",
                          cursor: "pointer",
                        }}
                      >
                        <Typography sx={{ fontSize: "15px" }}>
                          {result}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                  <ul style={{ listStyle: "none", padding: 0 }} ref={listRef}>
                    {!AskQuestionData?.askFromQuery &&
                      filteredOptions.map((result, index) =>
                        result ? (
                          <li
                            key={index}
                            onMouseDown={(e) => {
                              e.preventDefault();
                              selected(result);
                            }}
                            style={{
                              padding: "10px",
                              cursor: "pointer",
                              transition: "background-color 0.2s ease",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <AccessTimeIcon sx={{ marginRight: "15px" }} />
                            {result}
                          </li>
                        ) : (
                          ""
                        )
                      )}
                  </ul>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ mt: 2 }}>
            <Button
              sx={{
                width: "180px",
                color: "black",
                bgcolor: "#C7C8CC",
                borderRadius: "20px",
                marginLeft: "20px",
                fontSize: "15px",
                padding: "6px 20px",
                margin: "10px",
              }}
              onClick={(event) => {
                if (!AskQuestionData.catalog) {
                  document.getElementById("catalog")?.focus();
                } else {
                  handleColumnButtonClick(event);
                }
              }}
            >
              Column Name
            </Button>
            <Popover
              open={isColumnPopoverOpen}
              anchorEl={anchorElColumn}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={{ mt: 0.5 }}
            >
              <Box p={2} sx={{ width: "200px" }}>
                <ul
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "5px",
                  }}
                >
                  {/* {colData */}
                  {AskQuestionData?.colData
                    ?.filter((result) => modifyDataType(result.type) === "Date")
                    ?.map((result, index) => (
                      <li
                        key={index}
                        //  onClick={(e) => setselectedCol(result)}
                        onClick={(e) => handleColumnClick(result)}
                        style={{
                          display: "flex",
                          padding: "0px 10px",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid black",
                          borderRadius: "50px",
                          margin: "8px 0",
                          cursor: "pointer",
                          backgroundColor:
                            //  selectedCol?.name === result.name ? "#C7C8CC" : "",
                            AskQuestionData?.selectedCol?.name === result.name
                              ? "#C7C8CC"
                              : "",
                        }}
                      >
                        <Typography sx={{ fontSize: "15px" }}>
                          {result.name}
                        </Typography>
                      </li>
                    ))}
                </ul>
              </Box>
            </Popover>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Tooltip title="Select Period">
              <Button
                // disabled={!AskQuestionData?.selectedCol}
                sx={{
                  // width: "200px",
                  color: "black",
                  bgcolor: "#C7C8CC",
                  borderRadius: "20px",
                  marginLeft: "20px",
                  fontSize: "15px",
                  padding: "6px 20px",
                  margin: "10px",
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  if (!AskQuestionData.catalog) {
                    document.getElementById("catalog")?.focus();
                  } else {
                    handleCalendarButtonClick(event);
                  }
                }}
              >
                <CalendarMonth />
              </Button>
            </Tooltip>
            <Popover
              open={isCalendarPopoverOpen}
              anchorEl={anchorElCalendar}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Box p={2}>
                <DateRangePicker
                  ranges={dateRange}
                  onChange={handleDateRangeChange}
                />
              </Box>
            </Popover>
          </Box>
        </Box>
      </Box>
      <CommonDialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        title="Confirmation Dialog"
        content={
          AskQuestionData.askFromQuery
            ? "You are switching to the Ask Question mode, are you sure to switch?"
            : "You are switching to the SQL Query mode, are you sure to switch?"
        }
        actions={confirmationDialogActions}
      />
      <CommonDialog
        open={catalogChangeDialogOpen}
        onClose={() => setCatalogChangeDialogOpen(false)}
        title="Confirmation Dialog"
        content="Modifying the catalog will result in the deletion of all applied changes."
        actions={catalogChangeDialogActions}
      />
    </Box>
  );
};

export default SearchHeader;
