import html2canvas from "html2canvas";

export const exportAsImage = (objectId, title) => {
  const objectElement = document.getElementById(objectId);
  if (!objectElement) return;

  const computedStyle = window.getComputedStyle(objectElement);
  const width = parseFloat(computedStyle.getPropertyValue("width"));
  const height = parseFloat(computedStyle.getPropertyValue("height"));

  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");

  html2canvas(objectElement).then((renderedCanvas) => {
    ctx.drawImage(renderedCanvas, 0, 0);

    ctx.fillStyle = "#000";
    ctx.font = " 30px Arial";
    ctx.textAlign = "center";
    ctx.fillText(title, canvas.width / 2, 50);

    const imageDataUrl = canvas.toDataURL("image/png");

    const link = document.createElement("a");
    link.href = imageDataUrl;
    link.download = `${objectId}.png`;
    link.click();
  });
};
