import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import SearchIcon from "@mui/icons-material/Search";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import GridOnIcon from "@mui/icons-material/GridOn";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AgGrid from "../../../utils/AgGrid";
import { useNavigate } from "react-router-dom";
import { getColumns } from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import { selectCatalogData } from "../../../redux/selectors";
import { setCatalogData } from "../../../redux/actions";
import Loader from "../../../utils/Loader";
const PrepareMetadata = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState();
  const [columnDef, setColumnDef] = useState();
  const catalogData = useSelector(selectCatalogData);
  const [allColumns, setAllColumns] = useState([]);
  const [originalColumns, setOriginalColumns] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);

  useEffect(() => {
    getMetadataAsync();
  }, []);
  const getMetadataAsync = async () => {
    setIsLoadingFilter(true)
    const data = {
      tablename: catalogData?.metaData?.table?.name,
      id: catalogData?.connectionId,
    };
    const res = await getColumns(data);
    dispatch(
      setCatalogData({
        ...catalogData,
        metaData: {
          ...catalogData.metaData,
          columns: res?.data?.data?.result,
        },
      })
    );
    const columns = res?.data?.data?.result;
    setAllColumns(columns);
    setOriginalColumns(columns);
    setRowData(columns);
    const keys = Object.keys(columns[0]);
    const column = keys?.map((item, index) => {
      return { field: item };
    });
    setColumnDef(column);
    setIsLoadingFilter(false)
  };
  const handleNext = () => {
    navigate("/catalog/save");
  };
  const handlePre = () => {
    navigate("/catalog/select-table");
  };
  const handleSearchInputChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);
    if (input.trim() === "") {
      setAllColumns(originalColumns);
    } else {
      const filtered = originalColumns?.filter((col) =>
        col?.name?.toLowerCase().includes(input.toLowerCase())
      );
      setAllColumns(filtered);
    }
  };

  const handleCheckboxChange = (colName) => {
    const isSelected = catalogData?.metaData?.selectedFilters?.includes(colName);
    let updatedFilters;

    if (isSelected) {
      updatedFilters = catalogData.metaData.selectedFilters.filter(
        (filter) => filter !== colName
      );
    } else {
      updatedFilters = [...catalogData.metaData.selectedFilters, colName];
    }

    dispatch(
      setCatalogData({
        ...catalogData,
        metaData: {
          ...catalogData.metaData,
          selectedFilters: updatedFilters,
        },
      })
    );
  };

  if(isLoadingFilter){
    return <Loader/>
  }
  return (
    <>
      <Box
        sx={{
          // position: "fixed",
          top: "90px",
          width: "100%",
          height: { xs: "90vh", sm: "80vh" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <GridOnIcon fontSize="large" sx={{ color: "#0071E1" }} />
            <Typography
              sx={{
                color: "#2C2C2C",
                fontWeight: "300",
                fontSize: "35px",
                color: "#2C2C2C",
              }}
            >
              Select Filters
            </Typography>
          </Box>
        </Box>
        <Box sx={{ py: 2, display: "flex", height: "85%" }}>
          <Box sx={{ px: 4, width: "25%" }}>
            <FormControl sx={{ mt: 1 }} variant="standard" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                Coloumn Name
              </InputLabel>
              <Input
                id="outlined-adornment-password"
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e)}
                endAdornment={
                  <InputAdornment position="end" sx={{ pr: 2 }}>
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <SearchIcon sx={{ color: "#4487BD" }} />
                    </IconButton>
                  </InputAdornment>
                }
                label="Connection Name"
              />
            </FormControl>
            {allColumns.length === 0 && (
              <Typography variant="body1" sx={{ mt: 2, color: "grey" }}>
                No column found
              </Typography>
            )}
            <Box
              sx={{
                mt: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Accordion
                sx={{ boxShadow: "none", width: "100%" }}
                defaultExpanded
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  sx={{
                    // width: "130px",
                    p: 0,
                    pl: 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/database.png"}
                    alt="Database"
                  />
                  <Typography
                    sx={{
                      ml: 1,
                      color: "#000",
                      fontWeight: 400,
                      fontSize: "15px",
                    }}
                  >
                    {catalogData?.metaData?.table?.name}
                  </Typography>
                </AccordionSummary>
                <Box
                  sx={{
                    maxHeight: "50vh",
                    overflow: "auto",
                  }}
                >
                  {allColumns?.map((col) => (
                    <AccordionDetails sx={{ p: 0 }} key={col?.name}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#F0F0F0",
                          px: 3,
                          py: 0.5,
                        }}
                      >
                        <Checkbox
                          onChange={() => handleCheckboxChange(col.name)}
                          checked={catalogData?.metaData?.selectedFilters?.includes(
                            col.name
                          )}
                        />
                        <GridOnIcon
                          fontSize="small"
                          sx={{ color: "#5D5FEF" }}
                        />
                        <InputLabel
                          sx={{ ml: 1, color: "#000", cursor: "pointer" }}
                          onClick={() => handleCheckboxChange(col.name)}
                        >
                          {col?.name}
                        </InputLabel>
                      </Box>
                    </AccordionDetails>
                  ))}
                </Box>
              </Accordion>
            </Box>
          </Box>
          <Divider sx={{ backgroundColor: "#E8E8E8", width: "1.5px" }} />
          <Box sx={{ px: 4, width: "75%" }}>
            <AgGrid rowData={rowData} columnDef={columnDef} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          padding: "20px 22px",
          display: "flex",
          justifyContent: "end",
          gap: "10px",
        }}
      >
        <Button
          onClick={handlePre}
          variant="outlined"
          size="small"
          sx={{ color: "#5D5FEF", borderColor: "#5D5FEF", py: 0.6 }}
        >
          Previous&nbsp;
          <CancelRoundedIcon />
        </Button>
        <Button
          disabled={catalogData?.metaData?.selectedFilters.length === 0}
          onClick={handleNext}
          variant="contained"
          size="small"
          sx={{ backgroundColor: "#5D5FEF", py: 0.6 }}
        >
          Save Next&nbsp;
          <CheckCircleRoundedIcon />
        </Button>
      </Box>
    </>
  );
};
export default PrepareMetadata;
