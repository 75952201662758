import { apiEndPointsConfig } from "../apiEndPointsConfig";
import { axiosInstance } from "../axiosInstance";

export const getUsersForDashboard = async (data) => {
  try {
    const res = await axiosInstance.post(
      `${apiEndPointsConfig.getUsersForDashboard}`,
      data
    );
    console.log("All Boards");
    return res;
  } catch (err) {
    return err;
  }
};

export const getDetails = async (id) => {
  try {
    const res = await axiosInstance.get(`${apiEndPointsConfig.getDetails}`);
    console.log("User Get Details");
    return res;
  } catch (err) {
    return err;
  }
};

export const getUsersForCatalog = async (data) => {
  try {
    const res = await axiosInstance.post(
      `${apiEndPointsConfig.getUsersForCatalog}`,
      data
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const revokeCatalogAccess = async (reqData) => {
  try {
    const res = await axiosInstance.post(
      `${apiEndPointsConfig.revokeCatalogAccess}`,
      reqData
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const shareCatalogAccess = async (data) => {
  try {
    const res = await axiosInstance.post(
      `${apiEndPointsConfig.shareCatalogAccess}`,
      data
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getCatalogForUser = async (data) => {
  try {
    const res = await axiosInstance.post(
      `${apiEndPointsConfig.getCatalogForUser}`,
      data
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getDashboardsForUser = async (data) => {
  try {
    const res = await axiosInstance.post(
      apiEndPointsConfig.getDashboardsForUser,
      data
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const shareBoardAccess = async (data) => {
  try {
    const res = await axiosInstance.post(
      `${apiEndPointsConfig.shareBoardAccess}`,
      data
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const revokeDashboardAccess = async (reqData) => {
  try {
    const res = await axiosInstance.post(
      `${apiEndPointsConfig.revokeDashboardAccess}`,
      reqData
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getUserRoleForDashboard = async (data) => {
  try {
    const res = await axiosInstance.post(
      `${apiEndPointsConfig.getUserRoleForDashboard}`,
      data
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const updateDashboardRole = async (data) => {
  try {
    const res = await axiosInstance.post(
      `${apiEndPointsConfig.updateDashboardRole}`,
      data
    );
    return res;
  } catch (error) {
    return error;
  }
};
