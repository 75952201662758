import {
  Autocomplete,
  Box,
  Button,
  Menu,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ColorPicker from "../../utils/ColorPicker";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import { useDispatch, useSelector } from "react-redux";
import { setTitleProperties, setUpdateObjectData } from "../../redux/actions";
import {
  selectTitleProperties,
  selectUpdateObjectData,
} from "../../redux/selectors";
const Input = styled(MuiInput)`
  width: 42px;
`;
const TitleProperties = ({ update, chartType }) => {
  const dispatch = useDispatch();
  const TitleDetails = useSelector(selectTitleProperties);
  const UpdateObjectData = useSelector(selectUpdateObjectData);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleColorChange = (newColor) => {
    const updatedTitleProperties = {
      ...TitleDetails,
      textColor: newColor.hex || "#000000",
    };
    update
      ? dispatch(
          setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  titleProperties: {
                    ...UpdateObjectData?.ObjectData.data?.result
                      ?.titleProperties,
                    textColor: newColor.hex || "#000000",
                  },
                },
              },
            },
          })
        )
      : dispatch(setTitleProperties(updatedTitleProperties));
  };
  const fontOptions = [
    { label: "Roboto", value: "Roboto, sans-serif" },
    { label: "Times New Roman", value: "Times New Roman, serif" },
    { label: "Arial Black", value: "Arial Black, sans-serif" },
    { label: "Courier New", value: "Courier New, monospace" },
    { label: "Georgia", value: "Georgia, serif" },
    { label: "Verdana", value: "Verdana, sans-serif" },
    { label: "Cambria", value: "Cambria, serif" },
    { label: "Comic Sans MS", value: "Comic Sans MS, cursive" },
    { label: "Trebuchet MS", value: "Trebuchet MS, sans-serif" },
    { label: "Impact", value: "Impact, sans-serif" },
    { label: "Open Sans", value: "Open Sans, sans-serif" },
    { label: "Brush Script", value: "Brush Script, cursive" },
    { label: "Consolas", value: "Consolas, monospace" },
    { label: "Futura", value: "Futura, sans-serif" },
    { label: "Lato", value: "Lato, sans-serif" },
    { label: "Raleway", value: "Raleway, sans-serif" },
  ];
  const alignmentOptions = [
    { label: "Left", value: "Left" },
    { label: "Center", value: "Center" },
    { label: "Right", value: "Right" },
  ];
  const handleFontChange = (newValue) => {
    const updatedTitleProperties = {
      ...TitleDetails,
      font: newValue || "Arial,sans-serif",
    };
    update
      ? dispatch(
          setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  titleProperties: {
                    ...UpdateObjectData?.ObjectData.data?.result
                      ?.titleProperties,
                    font: newValue || "Arial,sans-serif",
                  },
                },
              },
            },
          })
        )
      : dispatch(setTitleProperties(updatedTitleProperties));
  };
  const handleTextChange = (newValue) => {
    const updatedTitleProperties = {
      ...TitleDetails,
      text: newValue,
    };
    update
      ? dispatch(
          setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  titleProperties: {
                    ...UpdateObjectData?.ObjectData.data?.result
                      ?.titleProperties,
                    text: newValue,
                  },
                },
              },
            },
          })
        )
      : dispatch(setTitleProperties(updatedTitleProperties));
  };
  const handleSliderChange = (event, newValue) => {
    let adjustedValue = newValue;
    if (newValue > 40) {
      adjustedValue = 40;
    }
    const updatedTitleProperties = {
      ...TitleDetails,
      fontSize: adjustedValue,
    };

    update
      ? dispatch(
          setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  titleProperties: {
                    ...UpdateObjectData?.ObjectData.data?.result
                      ?.titleProperties,
                    fontSize: adjustedValue,
                  },
                },
              },
            },
          })
        )
      : dispatch(setTitleProperties(updatedTitleProperties));
  };

  const handleAlignmentChange = (newValue) => {
    const updatedTitleProperties = {
      ...TitleDetails,
      textAlignment: newValue || "Center",
    };
    update
      ? dispatch(
          setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  titleProperties: {
                    ...UpdateObjectData?.ObjectData.data?.result
                      ?.titleProperties,
                    textAlignment: newValue || "Center",
                  },
                },
              },
            },
          })
        )
      : dispatch(setTitleProperties(updatedTitleProperties));
  };
  const handleBoldChange = (newValue) => {
    // setIsBold(newValue);
    const updatedTitleProperties = {
      ...TitleDetails,
      bold: newValue === true ? "bold" : "",
    };
    update
      ? dispatch(
          setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  titleProperties: {
                    ...UpdateObjectData?.ObjectData.data?.result
                      ?.titleProperties,
                    bold: newValue === true ? "bold" : "",
                  },
                },
              },
            },
          })
        )
      : dispatch(setTitleProperties(updatedTitleProperties));
  };
  const handleItallicChange = (newValue) => {
    setIsItalic(newValue);
    const updatedTitleProperties = {
      ...TitleDetails,
      italic: newValue === true ? "italic" : "",
    };
    update
      ? dispatch(
          setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  titleProperties: {
                    ...UpdateObjectData?.ObjectData.data?.result
                      ?.titleProperties,
                    italic: newValue === true ? "italic" : "",
                  },
                },
              },
            },
          })
        )
      : dispatch(setTitleProperties(updatedTitleProperties));
  };
  const handleUnderlineChange = (newValue) => {
    setIsUnderline(newValue);
    const updatedTitleProperties = {
      ...TitleDetails,
      underline: newValue === true ? "underline" : "",
    };
    update
      ? dispatch(
          setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  titleProperties: {
                    ...UpdateObjectData?.ObjectData.data?.result
                      ?.titleProperties,
                    underline: newValue === true ? "underline" : "",
                  },
                },
              },
            },
          })
        )
      : dispatch(setTitleProperties(updatedTitleProperties));
  };
  const handleBlur = () => {
    let fontSize = TitleDetails.fontSize;
    if (TitleDetails.fontSize < 0) {
      fontSize = 0;
    } else if (TitleDetails.fontSize > 40) {
      fontSize = 40;
    }

    const updatedTitleProperties = {
      ...TitleDetails,
      fontSize: fontSize,
    };

    update
      ? dispatch(
          setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  titleProperties: {
                    ...UpdateObjectData?.ObjectData.data?.result
                      ?.titleProperties,
                    fontSize: fontSize,
                  },
                },
              },
            },
          })
        )
      : dispatch(setTitleProperties(updatedTitleProperties));
  };

  return (
    <Box
      sx={{
        p: 1,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        width: "100%",
        gap: "20px",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Typography>Text</Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          placeholder="Title"
          value={
            update
              ? UpdateObjectData?.ObjectData?.data.result.titleProperties.text
              : TitleDetails.text
          }
          onChange={(e) => {
            handleTextChange(e.target.value);
          }}
          inputProps={{ maxLength: chartType === "widget" ? 24 : 64 }}
        />
      </Box>
      {chartType !== "widget" ? (
        <>
          <Box sx={{ width: "100%" }}>
            <Typography>Font</Typography>
            <Autocomplete
              size="small"
              options={fontOptions}
              value={
                update
                  ? fontOptions.find(
                      (option) =>
                        option.value ===
                        UpdateObjectData?.ObjectData?.data.result
                          .titleProperties.font
                    )
                  : fontOptions.find(
                      (option) => option.value === TitleDetails.font
                    )
              }
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} />}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleFontChange(newValue.value);
                } else {
                  handleFontChange(null);
                }
              }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography>Font Size</Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider
                max={'40'}
                  value={
                    update
                      ? UpdateObjectData?.ObjectData?.data.result
                          .titleProperties.fontSize
                      : TitleDetails.fontSize
                  }
                  onChange={handleSliderChange}
                  aria-labelledby="input-slider"
                />
              </Grid>
              <Grid item>
                <Input
                  value={
                    update
                      ? UpdateObjectData?.ObjectData?.data.result
                          .titleProperties.fontSize
                      : TitleDetails.fontSize
                  }
                  size="small"
                  onChange={(e) => {
                    console.log(e.target.value);
                    if (e.target.value <= 40) {
                      update
                        ? dispatch(
                            setUpdateObjectData({
                              ObjectData: {
                                ...UpdateObjectData.ObjectData,
                                data: {
                                  ...UpdateObjectData?.ObjectData.data,
                                  result: {
                                    ...UpdateObjectData?.ObjectData.data
                                      ?.result,
                                    titleProperties: {
                                      ...UpdateObjectData?.ObjectData.data
                                        ?.result?.titleProperties,
                                      fontSize: e.target.value,
                                    },
                                  },
                                },
                              },
                            })
                          )
                        : dispatch(
                            setTitleProperties({
                              ...TitleDetails,
                              fontSize: e.target.value,
                            })
                          );
                    }
                  }}
                  onBlur={handleBlur}
                  inputProps={{
                    step: 2,
                    min: 0,
                    max: 40,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          {update ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "8px",
                flexWrap: "wrap",
              }}
            >
              <Box>
                <Button
                  variant={
                    UpdateObjectData?.ObjectData?.data.result.titleProperties
                      ?.bold
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() =>
                    handleBoldChange(
                      !UpdateObjectData?.ObjectData?.data.result.titleProperties
                        ?.bold
                    )
                  }
                >
                  B
                </Button>
              </Box>
              <Box>
                <Button
                  variant={
                    UpdateObjectData?.ObjectData?.data.result.titleProperties
                      ?.italic
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() =>
                    handleItallicChange(
                      !UpdateObjectData?.ObjectData?.data.result.titleProperties
                        .italic
                    )
                  }
                >
                  I
                </Button>
              </Box>
              <Box>
                <Button
                  variant={
                    UpdateObjectData?.ObjectData?.data.result.titleProperties
                      ?.underline
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() =>
                    handleUnderlineChange(
                      !UpdateObjectData?.ObjectData?.data.result.titleProperties
                        ?.underline
                    )
                  }
                >
                  U
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "8px",
                flexWrap: "wrap",
              }}
            >
              <Box>
                <Button
                  variant={TitleDetails?.bold ? "contained" : "outlined"}
                  onClick={() => handleBoldChange(!TitleDetails?.bold)}
                >
                  B
                </Button>
              </Box>
              <Box>
                <Button
                  variant={
                    isItalic || TitleDetails?.italic ? "contained" : "outlined"
                  }
                  onClick={() => handleItallicChange(!isItalic)}
                >
                  I
                </Button>
              </Box>
              <Box>
                <Button
                  variant={
                    isUnderline || TitleDetails?.underline
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => handleUnderlineChange(!isUnderline)}
                >
                  U
                </Button>
              </Box>
            </Box>
          )}
          <Box sx={{ width: "100%" }}>
            <Typography>Text Color</Typography>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <ColorPicker
                color={
                  update
                    ? UpdateObjectData?.ObjectData?.data.result.titleProperties
                        .textColor
                    : TitleDetails?.textColor
                }
                handleColorChange={handleColorChange}
              />
            </Menu>
            <Box
              sx={{
                width: 20,
                height: 20,
                backgroundColor: update
                  ? UpdateObjectData?.ObjectData?.data.result.titleProperties
                      .textColor
                  : TitleDetails?.textColor,
              }}
              onClick={handleClick}
            ></Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography>Text Alignment</Typography>
            <Autocomplete
              size="small"
              options={alignmentOptions}
              value={
                update
                  ? alignmentOptions.find(
                      (option) =>
                        option.value ===
                        UpdateObjectData?.ObjectData?.data.result
                          .titleProperties.textAlignment
                    )
                  : alignmentOptions.find(
                      (option) => option.label === TitleDetails.textAlignment
                    )
              }
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} />}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAlignmentChange(newValue.value);
                } else {
                  handleAlignmentChange(null);
                }
              }}
            />
          </Box>{" "}
        </>
      ) : (
        ""
      )}
    </Box>
  );
};
export default TitleProperties;
