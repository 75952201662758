import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Drawer,
  Button,
  FormControlLabel,
  Switch,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  UpdateBoard,
  filterCategory,
  getAllBoards,
  getAllObject,
  getBoardsById,
  updateObject,
} from "../../../services";
import {
  selectAllBoards,
  selectAllUsers,
  selectChartProperties,
  selectFilter,
} from "../../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllBoards,
  setChartProperties,
  setFilter,
  setUseFromRedux,
} from "../../../redux/actions";
import ChartApiLayerComponent from "../../../utils/Chart/chartLayer";
import Loader from "../../../utils/Loader";
import "../../../App.css";
import { Responsive, WidthProvider } from "react-grid-layout";
import Filters from "../../../Component/Filters";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TypeofFilter from "../../../Component/Filters/Components/TypeofFilter";
import CommonDialog from "../../../utils/Dialog";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { exportAsPDF } from "../../../utils/ExportPdf";
import { exportAsImage } from "../../../utils/ExportImage";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ManagementButton from "../../../utils/ManagementButton";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { idpRoleAccess } from "../../../utils/utils";
import {
  getUserRoleForDashboard,
  getUsersForDashboard,
  revokeDashboardAccess,
  shareBoardAccess,
} from "../../../services/PermissionModule";
import CloseIcon from "@mui/icons-material/Close";
import UserCard from "../../../Component/UserCard";
import { enqueueSnackbar } from "notistack";

import FileDownloadIcon from "@mui/icons-material/FileDownload";

const ReactGridLayout = WidthProvider(Responsive);

const SingleAnswerBoard = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AllBoardsData = useSelector(selectAllBoards);
  const chartProperties = useSelector(selectChartProperties);
  const FilterData = useSelector(selectFilter);
  const [forceRerender, setForceRerender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allObject, setAllObject] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [viewMode, setViewMode] = useState(true);
  const [openClearFilterDialog, setOpenClearFilterDialog] = useState(false);
  const [chartCardHeight, setChartCardHeight] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [previousChartCardHeights, setPreviousChartCardHeights] = useState([]);
  const [menuAnswerBoardId, setmenuAnswerBoardId] = useState({
    id: "",
    index: "",
    chartProperties: null,
    object: null,
  });
  const [gridApi, setGridApi] = useState(null);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [changeViewMode, setChangeViewMode] = useState(false);
  const [shareDialog, setShareDialog] = useState(false);
  const [dashboardUserRole, setDashboardUserRole] = useState();
  const allUsersData = useSelector(selectAllUsers);
  const [selectedUser, setSelectedUser] = useState();
  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const [usersForDashboard, setUsersForDashboard] = useState();
  const [boardData, setBoardData] = useState();
  const [selectedRole, setSelectedRole] = useState(null);
  const [filterLabel, setFilterLabel] = useState([]);
  const [valueObject, setValueObject] = useState([]);
  const [catalogId, setCatalogId] = useState(null);

  const role = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).roles[0]
    : null;

  const allUsers =
    role === "Super Admin"
      ? allUsersData
      : allUsersData.filter((user) => !user.roles.includes("Super Admin"));

  const open = Boolean(anchorEl);
  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const forceUpdate = React.useCallback(() => {
    setForceRerender((prev) => !prev);
  }, []);

  useEffect(() => {
    dispatch(setUseFromRedux(false));

    dispatch(
      setChartProperties({
        ...chartProperties,
        VerticalScroll: false,
        HorizontalScroll: false,
        DataLabel: false,
      })
    );
  }, []);

  useEffect(() => {
    getAllObjectAsync(id);
    getBoardsByIdAsync(id);
  }, [forceRerender]);

  const getBoardsByIdAsync = async (id) => {
    const res = await getBoardsById(id);
    setBoardData(res?.data?.data?.result);
    console.log(res, "getBoardsByIdAsync");
  };

  const getAllObjectAsync = async (id) => {
    setIsLoading(true);
    const res = await getAllObject(id);
    setAllObject(res?.data?.data?.result);
    console.log(res?.data?.data?.result);
    const req = {
      data: {
        catalogId: res?.data?.data?.result[0]?.catalogId,
      },
    };
    setCatalogId(res?.data?.data?.result[0]?.catalogId);
    const filterDataRes = await filterCategory(req);
    setFilterData(filterDataRes?.data?.data?.ans);

    setIsLoading(false);
  };

  useEffect(() => {
    const z = [...filterLabel];
    const y = z.map((item) => ({
      colName: item.colName,
      val: item.val,
    }));
    setValueObject(y);
  }, [filterLabel]);

  useEffect(() => {
    getAllBoardsAsync();
  }, [forceRerender]);

  const getAllBoardsAsync = async () => {
    setIsLoading(true);
    const res = await getAllBoards();
    dispatch(setAllBoards(res?.data?.data?.result));
    const filt = res?.data?.data?.result.filter((board) => board._id === id);
    setFilterLabel(filt[0].filters);
    dispatch(setFilter(filt[0].filters));
    const data = {
      boardId: id,
      email: JSON.parse(localStorage.getItem("user"))?.email,
    };
    if (JSON.parse(localStorage.getItem("user")).roles[0] !== "Super Admin") {
      const resRole = await getUserRoleForDashboard(data);
      setDashboardUserRole(resRole?.data?.data?.result?.role);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      const newCardHeights = allObject?.map((_, i) => {
        const cardElement = document.getElementById(`card-${i}`);
        console.log(cardElement, "cardElement");
        return cardElement ? cardElement?.offsetHeight - 80 : 0;
      });
      console.log(newCardHeights, "newCardHeights");
      setChartCardHeight(newCardHeights);
    }, 1000);
  }, [allObject]);

  const handleOpenClearFilterDialog = () => {
    setOpenClearFilterDialog(true);
  };

  const handleCloseClearFilterDialog = () => {
    setOpenClearFilterDialog(false);
  };

  const onLayoutChange = (newLayout) => {
    localStorage.setItem("reactGridLayout", JSON.stringify(newLayout));
  };

  const onDragStop = async (layout, oldItem, newItem) => {
    const businessId = allObject[newItem.i]._id;
    const data = {
      businessId: businessId,
      data: {
        layout: {
          x: newItem.x,
          y: newItem.y,
          w: newItem.w,
          h: newItem.h,
        },
      },
    };
    try {
      const apiResponse = await updateObject(data);
      console.log("API Response:", apiResponse);
    } catch (error) {
      console.error("Error in API call:", error);
    }
  };

  const onResizeStop = async (layout, oldItem, newItem) => {
    const newCardHeights = allObject?.map((_, i) => {
      const cardElement = document.getElementById(`card-${i}`);
      console.log(cardElement, "cardElement");
      return cardElement ? cardElement.offsetHeight - 80 : 0;
    });
    console.log(newCardHeights, "assaassaasassaasasasasasasas");
    setChartCardHeight(newCardHeights);

    const businessId = allObject[newItem.i]._id;
    const data = {
      businessId: businessId,
      data: {
        layout: {
          x: newItem.x,
          y: newItem.y,
          w: newItem.w,
          h: newItem.h,
        },
      },
    };
    try {
      const apiResponse = await updateObject(data);
      console.log("API Response:", apiResponse);
    } catch (error) {
      console.error("Error in API call:", error);
    }
  };

  const handleApplyClick = async () => {
    const data = {
      boardId: id,
      data: {
        filters: FilterData,
      },
    };

    const apiResponse = await UpdateBoard(data);
    console.log("API Response:", apiResponse);
    // const b = [...FilterData];
    // setFilterLabel([...b]);
    toggleDrawer();
    forceUpdate();
  };

  if (isLoading === true) {
    return <Loader height="80vh" />;
  }

  const toggleDrawer = (i) => {
    setExpandedIndex(i);
    setIsOpen((prev) => !prev);
  };

  const handleAccordionChange = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };
  const handleClearFilter = async () => {
    const data = {
      boardId: id,
      data: {
        filters: [],
      },
    };

    const apiResponse = await UpdateBoard(data);
    console.log("API Response:", apiResponse);
    dispatch(setFilter([]));
    forceUpdate();
  };

  const clearFilterDialogActions = [
    {
      label: "Cancel",
      color: "primary",
      onClick: handleCloseClearFilterDialog,
    },
    {
      label: "Clear All",
      color: "warning",
      onClick: () => {
        handleClearFilter();
        handleCloseClearFilterDialog();
      },
    },
  ];

  const clearFilterDialogContent = (
    <div>Do you confirm your decision to clear all the filters?</div>
  );

  const handleHideObject = async (id) => {
    const data = {
      businessId: id.id,
      data: {
        hide: true,
      },
    };
    const api = await updateObject(data);
    forceUpdate();
    handleCloseHideObjectDialog();
    console.log(api, "lllllllllllll");
  };

  const handleToggleFullscreen = (id) => {
    console.log(id, "kkkkkkkkkk");

    const targetElement = document.getElementById(id.id);
    if (targetElement && targetElement.requestFullscreen) {
      targetElement.requestFullscreen();
    }
    setPreviousChartCardHeights([...chartCardHeight]);
    const newChartCardHeight = [...chartCardHeight];
    newChartCardHeight[id.index] = 850;
    setChartCardHeight(newChartCardHeight);
  };

  document.addEventListener("fullscreenchange", () => {
    if (!document.fullscreenElement) {
      setChartCardHeight(previousChartCardHeights);
    }
  });

  const handleOpenChangeViewModeDialog = () => {
    setChangeViewMode(true);
  };
  const handleCloseChangeViewModeDialog = () => {
    setChangeViewMode(false);
  };
  const confirmChangeViewModeDialogActions = [
    {
      label: "Cancel",
      color: "primary",
      onClick: handleCloseChangeViewModeDialog,
    },
    {
      label: "Change View Mode",
      color: "warning",
      onClick: () => {
        setViewMode(!viewMode);
        handleCloseChangeViewModeDialog();
      },
    },
  ];
  const handleOpenHideObjectDialog = () => {
    setOpenConfirmationDialog(true);
  };
  const handleCloseHideObjectDialog = () => {
    setOpenConfirmationDialog(false);
  };
  const hideObjectActions = [
    {
      label: "No",
      color: "primary",
      onClick: handleCloseHideObjectDialog,
    },
    {
      label: "Yes",
      color: "warning",
      onClick: () => handleHideObject(menuAnswerBoardId),
    },
  ];
  const handleShareClick = async (boardId) => {
    setShareDialog(true);
    setLoadingDashboard(true);
    const data = {
      boardId: boardId,
    };
    const res = await getUsersForDashboard(data);
    setUsersForDashboard(res?.data?.data);
    setLoadingDashboard(false);
  };

  const revokeDashboardAccessAsync = async (email, role) => {
    // setLoadingDashboard(true);

    const reqData = {
      boardId: id,
      data: {
        email: email,
        role: role,
      },
    };

    const res = await revokeDashboardAccess(reqData);
    if (res?.data?.success === true) {
      enqueueSnackbar(`Dashboard revoked successfully`, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(`Something went wrong Please Try  again later!`, {
        variant: "error",
      });
    }
    handleShareClick(id);
    // setLoadingDashboard(false);
  };

  const handleCloseDialog = () => {
    setSelectedRole(null);
    setShareDialog(false);
  };

  const shareDashboardAccessAsync = async () => {
    if (!selectedUser || !selectedRole) {
      enqueueSnackbar(
        "Please select a user and role before sharing the dashboard.",
        {
          variant: "error",
        }
      );
      return;
    }
    const data = {
      boardId: id,
      data: {
        email: selectedUser?.email,
        role: selectedRole?.value,
        ownerEmail:
          selectedRole?.value === "creator"
            ? boardData?.createdBy?.email
            : null,
      },
    };

    const res = await shareBoardAccess(data);
    console.log("res", res);
    if (res?.data?.success === true) {
      enqueueSnackbar(
        `Dashboard Shared Successfully to ${selectedUser?.name}`,
        {
          variant: "success",
        }
      );
      setSelectedRole(null);
      setSelectedUser(null);
      setShareDialog(false);
    } else {
      enqueueSnackbar(`${res?.response.data?.err?.message}`, {
        variant: "error",
      });
      setSelectedRole(null);
      setSelectedUser(null);
    }
  };
  const shareDialogContent = (
    <Box
      sx={{
        borderRadius: "9px",
        display: "flex",
        gap: "20px",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Share your dashboard with</Typography>
        <CloseIcon onClick={() => setShareDialog(false)} cursor="pointer" />
      </Box>
      <Box sx={{ display: "flex", gap: "30px", mt: 2 }}>
        <Autocomplete
          sx={{ width: "350px" }}
          limitTags={2}
          id="tags-standard"
          value={selectedUser}
          options={
            allUsers
              ? allUsers.map((user) => ({
                  id: user.id,
                  name: user.name,
                  email: user.email,
                  profilePic: user.profilePic,
                  role: user.roles[0],
                }))
              : []
          }
          getOptionLabel={(option) => option.name}
          onChange={(e, value) => {
            setSelectedUser(value);
            setSelectedRole({
              value: "viewer",
              index: "0",
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Add People"
            />
          )}
          renderOption={(props, option) => (
            <Box {...props} p={1}>
              <UserCard
                name={option.name}
                email={option?.email}
                profile={option?.profilePic}
              />
            </Box>
          )}
        />

        <ManagementButton
          setSelectedRole={setSelectedRole}
          selectedRole={selectedRole}
          idpRole={selectedUser?.role}
          dashboardUserRole={dashboardUserRole}
        />
      </Box>
      <Box>
        <Typography variant="subtitle2">Creator</Typography>
        <UserCard
          showCreatorButton={
            JSON.parse(localStorage.getItem("user")).roles[0] === "Super Admin"
              ? JSON.parse(localStorage.getItem("user")).email ===
                boardData?.createdBy?.email
                ? false
                : true
              : false
          }
          idpRole={
            allUsersData.find(
              (userData) => userData.email === boardData?.createdBy?.email
            )?.roles[0]
          }
          name={boardData?.createdBy?.name}
          email={boardData?.createdBy?.email}
          profile={boardData?.createdBy?.profilePic}
          role={"creator"}
          board={boardData}
          onClickDelete={() =>
            revokeDashboardAccessAsync(boardData?.createdBy?.email, "creator")
          }
        />
      </Box>
      <Box>
        {usersForDashboard?.result.length !== 0 ? (
          <Typography>Already Shared with</Typography>
        ) : (
          ""
        )}
      </Box>
      <Box
        sx={{
          maxHeight: "170px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "5px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        }}
      >
        {loadingDashboard ? (
          <Loader />
        ) : usersForDashboard?.result ? (
          usersForDashboard?.result.map((user, index) => (
            <UserCard
              idpRole={
                allUsersData?.find((userData) => userData.email === user.email)
                  ?.roles[0]
              }
              key={index}
              name={user?.name}
              email={user?.email}
              profile={user?.profilePic}
              role={user.role}
              board={boardData}
              showCreatorButton={true}
              dashboardUserRole={dashboardUserRole}
              onClickDelete={() =>
                revokeDashboardAccessAsync(user.email, user.role)
              }
            />
          ))
        ) : (
          ""
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "24px",
          marginTop: "30px",
        }}
      >
        <Button
          sx={{
            color: "#343434",
            borderRadius: "20px",
            fontSize: "14px",
            padding: "6px 20px",
            backgroundColor: "#FFF",
            fontFamily: "Inter,sans-serif",
            textTransform: "capitalize",
            border: "1px solid #989898",
          }}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          sx={{
            color: "#FFF",
            borderRadius: "20px",
            fontSize: "14px",
            padding: "6px 20px",
            backgroundColor: "#3A5396",
            fontFamily: "Inter,sans-serif",
            textTransform: "capitalize",
            ":hover": {
              backgroundColor: "#3A5396",
              color: "#FFF",
            },
          }}
          onClick={shareDashboardAccessAsync}
        >
          Share
        </Button>
      </Box>
    </Box>
  );

  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const exportToCsv = () => {
    if (gridApi) {
      const title = menuAnswerBoardId.object?.titleProperties?.text || "export";
      const fileName = `${title}.csv`;
      const params = {
        skipHeader: false,
        skipFooters: true,
        skipGroups: true,
        fileName: fileName,
      };
      gridApi.exportDataAsCsv(params);
    }
  };

  return (
    <>
      <Box
        sx={{
          // height: "88vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            alignItems: { xs: "flex-start", md: "center" },
            justifyContent: "space-between",
            width: "100%",
            mb: "10px",
            flexDirection: { xs: "column", md: "row" },
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              // width: "100%",
              mb: "10px",
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "25px",
                fontWeight: "500",
                ml: "12px",
              }}
            >
              {AllBoardsData?.map((board) => board._id === id && board.name)}
            </Typography>
            {/* <ErrorOutlineOutlinedIcon sx={{ color: "#B6B6B6" }} /> */}
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              display: "flex",
              justifyContent: { xs: "flex-start", md: "flex-end" },
              ml: { xs: "12px", md: "0" },
            }}
          >
            {idpRoleAccess(role) && dashboardUserRole !== "viewer" && (
              <Box sx={{ display: "flex", gap: "30px" }}>
                {dashboardUserRole !== "viewer" &&
                  dashboardUserRole !== "editor" && (
                    <Button
                      variant="outlined"
                      startIcon={<GroupAddIcon />}
                      onClick={() => handleShareClick(id)}
                    >
                      Share
                    </Button>
                  )}
                <Box>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={handleOpenChangeViewModeDialog}
                        checked={viewMode}
                      />
                    }
                    label="View Mode"
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <Drawer
          anchor="right"
          open={isOpen}
          onClose={toggleDrawer}
          sx={{
            "& .MuiDrawer-paperAnchorRight": {
              margin: 2,
              width: { xs: "90vw", md: "33vw" },
              height: { xs: "85vh", md: "90vh" },
              borderRadius: "8px",
              border: "1px solid #DFE3E7",
              background: "#FFF",
              boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.12)",
              top: "60px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              height: "40px",
              padding: "0px 16px",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: " 1px solid #DDE1E6",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Filters
            </Typography>
            {/* <Button
              onClick={handleClearFilter}
              variant="text"
              className="clear-filter"
            >
              Clear all
            </Button> */}
          </Box>
          {filterData &&
            filterData?.map((elm, i) => (
              <Box key={i}>
                <Accordion
                  expanded={i === expandedIndex}
                  onChange={() => handleAccordionChange(i)}
                >
                  {console.log(elm, "arpitaaaaaaaaaaaaaaa")}
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{elm.column}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ backgroundColor: "#e0e0e05e" }}>
                    <TypeofFilter catalogId={catalogId} elm={elm} />
                  </AccordionDetails>
                </Accordion>
              </Box>
            ))}
          <Box display="flex" justifyContent="end" p={2}>
            <Button
              sx={{ mr: "10px" }}
              variant="contained"
              color="primary"
              onClick={handleApplyClick}
            >
              Add Filters
            </Button>
            <Button variant="outlined" onClick={toggleDrawer}>
              Cancel
            </Button>
          </Box>
        </Drawer>
        <Grid container>
          <Grid
            item
            sm={12}
            sx={{ marginTop: "5px", display: "flex", gap: "5px" }}
          >
            <Box>
              <Box pl={1.5}>
                <Grid container rowGap={1}>
                  {filterData?.map((elm, i) => {
                    const isFilterAvailable = filterLabel?.some(
                      (filter) => filter.colName === elm.column
                    );
                    const value = valueObject?.find(
                      (filter) => filter.colName === elm.column
                    );

                    const result = value ? value.val : "";

                    return isFilterAvailable ? (
                      <Filters
                        key={i}
                        title={elm.column}
                        onClick={() => toggleDrawer(i)}
                        value={result}
                        dataType={elm.dataType}
                        forceUpdate={forceUpdate}
                      />
                    ) : null;
                  })}
                  <Filters
                    key={"new"}
                    title={"Add Filter"}
                    onClick={() => toggleDrawer()}
                  />
                  {filterLabel?.length === 0 ? null : (
                    <Box
                      className="filter-box"
                      component={Button}
                      sx={{
                        color: "unset",
                        fontWeight: "400",
                        bgcolor: "#fff",
                        marginRight: "13px",
                      }}
                      onClick={handleOpenClearFilterDialog}
                    >
                      <div className="flexee">
                        <div className="title">Clear All</div>
                      </div>
                    </Box>
                  )}
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ width: "100%", mt: 2 }}>
          <Box id="full">
            <ReactGridLayout
              className="layout"
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }}
              cols={{ lg: 16, md: 12, sm: 6, xs: 2 }}
              rowHeight={50}
              isResizable={viewMode ? false : true}
              isDraggable={viewMode ? false : true}
              onLayoutChange={onLayoutChange}
              onDragStop={onDragStop}
              onResizeStop={onResizeStop}
              draggableHandle=".drag-handle"
            >
              {allObject?.map((elm, i) =>
                elm.hide !== true ? (
                  elm?.visualization?.type === "widget" ? (
                    <Box
                      sx={{
                        border: "none",
                        backgroundColor: "transparent",
                        justifyContent: "center",
                        height: "100%",
                      }}
                      key={i}
                      {...(elm?.layout
                        ? {
                            "data-grid": {
                              x: elm?.layout?.x,
                              y: elm?.layout?.y,
                              w: elm?.layout?.w,
                              h: elm?.layout?.h,
                              // isResizable: true,
                            },
                          }
                        : {
                            "data-grid": {
                              x: (i * 2) % 12,
                              y: Math.floor(i / 6),
                              w: 3,
                              h: 2,
                              // isResizable: true,
                            },
                          })}
                    >
                      <Box p={1} sx={{ width: "100%", height: "100%" }}>
                        <ChartApiLayerComponent
                          catalog={elm?.catalogId}
                          query={elm?.query}
                          chartType={elm?.visualization?.type}
                          pivotState={elm?.visualization?.pivot}
                          pivotToolbar={elm?.visualization?.pivotToolbar}
                          FilterData={FilterData}
                          // title={elm?.title}
                          widgetProp={elm?.widgetProp}
                          titleProperties={elm?.titleProperties}
                          chartProperties={elm?.chartProperties}
                          prop={{ id: elm?._id, mode: viewMode }}
                          onGridReady={onGridReady}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
                      key={i}
                      {...(elm?.layout
                        ? {
                            "data-grid": {
                              x: elm?.layout?.x,
                              y: elm?.layout?.y,
                              w: elm?.layout?.w,
                              h: elm?.layout?.h,
                            },
                          }
                        : {
                            "data-grid": {
                              x: (i * 2) % 12,
                              y: Math.floor(i / 6),
                              w: 5,
                              h: 5,
                            },
                          })}
                      id={elm?._id}
                    >
                      <Card
                        key={i}
                        id={`card-${i}`}
                        sx={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <CardContent sx={{ height: "100%" }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              component="div"
                              color="text.secondary"
                              className="drag-handle"
                              // height="50px"
                              sx={{
                                cursor: "move",
                                fontFamily: elm?.titleProperties?.font,
                                fontSize: `${elm?.titleProperties?.fontSize}px`,
                                color: elm?.titleProperties?.textColor,
                                textAlign: elm?.titleProperties?.textAlignment,
                                fontWeight: elm?.titleProperties?.bold,
                                fontStyle: elm?.titleProperties?.italic,
                                textDecoration: elm?.titleProperties?.underline,
                                width: "90%", // Take full width
                                overflowWrap: "break-word", // Allow text to wrap
                                wordWrap: "break-word", // For long words
                                maxHeight: "3em", // Limit height to 3 lines before scrolling
                                display: "-webkit-box",
                                "-webkit-line-clamp": 3,
                                "-webkit-box-orient": "vertical",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {elm?.titleProperties?.text}
                            </Typography>
                            <>
                              <Box
                                id={`card-${i}r`}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                <Tooltip title="Account settings">
                                  <IconButton
                                    onClick={(event) => {
                                      setmenuAnswerBoardId({
                                        id: elm?._id,
                                        index: i,
                                        chartProperties: elm?.chartProperties,
                                        object: elm,
                                      });
                                      setAnchorEl(event.currentTarget);
                                    }}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={
                                      open ? "account-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                  >
                                    <MoreVertOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </>
                          </Box>
                          {elm?.visualization?.type === "grid" ? (
                            <Box
                              id={`card-${i}i`}
                              p={1}
                              sx={{
                                width: "100%",
                                height: "100%",
                                overflow: "auto",
                              }}
                            >
                              <ChartApiLayerComponent
                                catalog={elm?.catalogId}
                                // chartCardHeight={chartCardHeight}
                                query={elm?.query}
                                chartType={elm?.visualization?.type}
                                pivotState={elm?.visualization?.pivot}
                                pivotToolbar={elm?.visualization?.pivotToolbar}
                                FilterData={FilterData}
                                widgetProp={elm?.widgetProp}
                                titleProperties={elm?.titleProperties}
                                chartProperties={elm?.chartProperties}
                                onGridReady={onGridReady}
                              />
                            </Box>
                          ) : (
                            <Box
                              id={`card-${i}i`}
                              p={1}
                              sx={{ width: "100%", height: "100%" }}
                            >
                              <ChartApiLayerComponent
                                chartCardHeight={chartCardHeight[i]}
                                catalog={elm?.catalogId}
                                query={elm?.query}
                                chartType={elm?.visualization?.type}
                                pivotState={elm?.visualization?.pivot}
                                pivotToolbar={elm?.visualization?.pivotToolbar}
                                FilterData={FilterData}
                                widgetProp={elm?.widgetProp}
                                titleProperties={elm?.titleProperties}
                                chartProperties={elm?.chartProperties}
                              />
                            </Box>
                          )}
                        </CardContent>
                      </Card>
                    </Box>
                  )
                ) : null
              )}
            </ReactGridLayout>
          </Box>
        </Box>
      </Box>
      <CommonDialog
        open={openClearFilterDialog}
        onClose={handleCloseClearFilterDialog}
        title="Clear Filters"
        content={clearFilterDialogContent}
        actions={clearFilterDialogActions}
      />
      <CommonDialog
        open={changeViewMode}
        onClose={handleCloseChangeViewModeDialog}
        title="Change View Mode"
        content={
          viewMode
            ? "Please confirm to deactivate the View Mode?"
            : "Please confirm to activate the View Mode?"
        }
        actions={confirmChangeViewModeDialogActions}
      />

      <CommonDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        title="Confirmation dialog"
        content={"Do you confirm your intention to hide the chart?"}
        actions={hideObjectActions}
      />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem onClick={() => handleToggleFullscreen(menuAnswerBoardId)}>
          <ListItemIcon>
            <LaunchOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Fullscreen
        </MenuItem>
        {idpRoleAccess(role) && !viewMode && (
          <>
            <MenuItem onClick={handleOpenHideObjectDialog}>
              <ListItemIcon>
                <VisibilityOffOutlinedIcon fontSize="small" />
              </ListItemIcon>
              Hide Object
            </MenuItem>
            <MenuItem
              onClick={() =>
                navigate(`/update-single-answer-board/${menuAnswerBoardId.id}`)
              }
            >
              <ListItemIcon>
                <EditNoteIcon fontSize="small" />
              </ListItemIcon>
              Edit
            </MenuItem>
          </>
        )}
        {menuAnswerBoardId.object?.visualization?.type !== "grid" &&
          menuAnswerBoardId.object?.visualization?.type !== "Pivot" && (
            <>
              <MenuItem
                onClick={() => {
                  exportAsPDF(
                    `card-${menuAnswerBoardId.index}i`,
                    menuAnswerBoardId.object.title
                  );
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <PictureAsPdfIcon fontSize="small" />
                </ListItemIcon>
                Export as PDF
              </MenuItem>
              <MenuItem
                onClick={() => {
                  exportAsImage(
                    `card-${menuAnswerBoardId.index}i`,
                    menuAnswerBoardId.object.title
                  );
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <ImageIcon fontSize="small" />
                </ListItemIcon>
                Export as Image
              </MenuItem>
            </>
          )}
        {menuAnswerBoardId.object?.visualization?.type === "grid" && (
          <>
            <MenuItem
              onClick={() => {
                exportToCsv();
                handleClose();
              }}
            >
              <ListItemIcon>
                <FileDownloadIcon />
              </ListItemIcon>
              Export as CSV
            </MenuItem>
          </>
        )}
      </Menu>
      <CommonDialog
        open={shareDialog}
        onClose={handleCloseDialog}
        content={shareDialogContent}
        actions={[]}
      />
    </>
  );
};

export default SingleAnswerBoard;
