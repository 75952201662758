import React, { useEffect, useRef } from "react";
import * as FlexmonsterReact from "react-flexmonster";
import "./style.css";
import { licenseKey } from "../../configs/envConfig";
const PivotUI = ({ pivotData, pivotState, setPivotState, pivotToolbar }) => {
  const flexmonsterRef = useRef(null);
  const handleReportChange = () => {
    if (flexmonsterRef.current) {
      const currentReport = flexmonsterRef.current.flexmonster.getReport();
      const filteredReport = {
        slice: currentReport.slice,
        options: currentReport.options,
        formats: currentReport.formats,
      };
      setPivotState(filteredReport);
    }
  };
  const customizeToolbar = (toolbar) => {
    if (toolbar) {
      let tabs = toolbar.getTabs();
      toolbar.getTabs = function () {
        tabs = tabs.filter(
          (tab) =>
            tab.id !== "fm-tab-connect" &&
            tab.id !== "fm-tab-open" &&
            tab.id !== "fm-tab-save" &&
            tab.id !== "fm-tab-share" &&
            tab.id !== "fm-tab-grid" &&
            tab.id !== "fm-tab-charts" &&
            tab.id !== "fm-tab-format" &&
            tab.id !== "fm-tab-options" &&
            tab.id !== "fm-tab-fields" &&
            tab.id !== "fm-tab-fullscreen"
        );
        console.log(tabs);
        return tabs;
      };
      console.log(toolbar.getTabs);
    }
  };
  return (
    <div>
      <FlexmonsterReact.Pivot
        ref={flexmonsterRef}
        componentFolder="https://cdn.flexmonster.com/"
        height={550}
        toolbar={true}
        report={{
          dataSource: {
            type: "json",
            data: pivotData,
          },
          ...pivotState,
        }}
        shareReportConnection={{
          url: "https://olap.flexmonster.com:9500",
        }}
        licenseKey={licenseKey}
        beforetoolbarcreated={(toolbar) => {
          if (pivotState && Object.keys(pivotState).length) {
            customizeToolbar(toolbar);
          }
          toolbar.showShareReportTab = true;
        }}
        reportchange={handleReportChange}
      />
    </div>
  );
};
export default PivotUI;