import React, { useEffect } from "react";
import {
  Autocomplete,
  Box,
 
  TextField,
  Typography,
} from "@mui/material";
import {
  selectAllConnection,
  selectCatalogData,
  selectDataBase,
} from "../../../../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  setCatalogData,
  setDataBase,
  setTestConnection,
} from "../../../../../redux/actions";

const SelectExisting = () => {
  const dispatch = useDispatch();
  const allConnections = useSelector(selectAllConnection);
  const catalogData = useSelector(selectCatalogData);
  const dataBase = useSelector(selectDataBase);
  // useEffect(() => {
  //   dispatch(setDataBase(""));
  // }, []);
  const getSelectedConnection = () => {
    return allConnections.find((connection) => connection._id === catalogData.connectionId);
  };
  useEffect(() => {
    const selectedConnection = getSelectedConnection();
    if (selectedConnection) {
      dispatch(setTestConnection(true));
      dispatch(setDataBase(selectedConnection.database));
    }
  }, [catalogData.connectionId, allConnections]);
  return (
    <Box
      sx={{
        width: "100%",
        // pl:1
      }}
    >
      <Typography
        sx={{
          fontSize: "23px",
          color: "#2C2C2C",
          fontWeight: "300",
          mt: 3,
        }}
      >
        Selection From Existing
      </Typography>
      <Box
        sx={{
          width: { xs: "100%", sm: "80%", md: "50%", lg: "30%" },
          mt: 3,
        }}
      >
        <Autocomplete
          options={allConnections}
          getOptionLabel={(option) => option.name}
          // value={
          //   allConnections.find(
          //     (connection) => connection.database === catalogData.connectionId
          //   )?.name
          // }
          value={getSelectedConnection()}
          onChange={(e, v) => {
            if (v) {
              dispatch(
                setCatalogData({
                  ...catalogData,
                  connectionId: v?._id,
                })
              );
              dispatch(setTestConnection(true));
              dispatch(setDataBase(v?.database));
            } else {
              dispatch(
                setCatalogData({
                  connectionId: "",
                  metaData: {
                    table: null,
                    columns: [],
                  },
                  name: "",
                })
              );
              dispatch(setDataBase(""));

              dispatch(setTestConnection(false));
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Connection"
              variant="standard"
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default SelectExisting;
