import React, { useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";


const AgGrid = ({ rowData, columnDef,onGridReady }) => {


  const defaultCondef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
    flex: 1,
    minWidth: 200,
  };

  return (
    <div className="ag-theme-alpine" style={{ width: "100%", height: "62vh"}}>
      
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDef}
        pagination={true}
        paginationPageSize={10}
        enableColResize={true}
        defaultColDef={defaultCondef}
        onGridReady={onGridReady}
      />
    </div>
  );
};

export default AgGrid;
