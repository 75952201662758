import { Navigate, Outlet } from "react-router-dom";

const SuperAdminRoute = () => {
  const role = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user"))?.roles[0] : null;

  return role === "Super Admin" ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/" replace />
  );

};

export default SuperAdminRoute;
