import { useEffect, useState } from "react";
import { getCatalogDetails, runQuery } from "../../services";
import { Box, Typography } from "@mui/material";
import Chart from ".";
import AgGrid from "../AgGrid";
import Loader from "../Loader";
import PivotUI from "../piviot";
import { useCallback } from "react";
const ChartApiLayerComponent = ({
  chartType,
  query,
  catalog,
  FilterData,
  pivotState,
  pivotToolbar,
  // title,
  widgetProp,
  titleProperties,
  chartProperties,
  chartCardHeight,
  reload,
  prop,
  onGridReady,
}) => {
  const [rowData, setRowData] = useState();
  const [columnDef, setColumnDef] = useState();
  const [response, setResponse] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [forceRerender, setForceRerender] = useState(false);
  const [isChartLoading, setIsChartLoading] = useState(false);
  // const [gridApi, setGridApi] = useState(null);

  // const onGridReady = (params) => {
  //   setGridApi(params.api);
  // };
  useEffect(() => {
    getDataAsync();
  }, [forceRerender]);

  const forceUpdate = useCallback(() => {
    setForceRerender((prev) => !prev);
  }, []);
  useEffect(() => {
    if (reload) {
      forceUpdate();
    }
  }, []);

  const getDataAsync = async () => {
    setIsLoading(true);
    const req = {
      data: {
        catalogId: catalog,
      },
    };

    const CatalogRes = await getCatalogDetails(req);
    const data = {
      query: query,
      connectionId: CatalogRes?.data?.data?.result?.connectionId,
      catalogId: catalog,
      filters: FilterData ? FilterData : [],
    };
    const res = await runQuery(data);
    setResponse(res);
    if (res?.response?.status === 500 || res?.response?.status === 400) {
      setIsLoading(false);
      setRowData("");
      setColumnDef("");
    }
    if (res?.data?.data?.result.length !== 0) {
      setRowData(res?.data?.data?.result);
      const keys = Object.keys(res?.data?.data?.result[0]);

      const column = keys?.map((item, index) => {
        return { field: item };
      });
      setIsLoading(false);
      setColumnDef(column);
    } else {
      setIsLoading(false);
    }
  };

  if (isLoading === true) {
    return <Loader />;
  }
  return (
    <>
      {isLoading === true ? (
        <Loader />
      ) : response?.data?.data?.result.length !== 0 ? (
        <Box sx={{ width: "100%", height: "100%", overflow: "auto" }}>
          {chartType === "grid" ? (
            <AgGrid
              // isLoading={isLoading}
              columnDef={columnDef}
              rowData={rowData}
              onGridReady={onGridReady}
            />
          ) : chartType === "Pivot" ? (
            <PivotUI
              // chartCardHeight={chartCardHeight}
              pivotData={response?.data?.data?.result}
              pivotState={pivotState}
              pivotToolbar={pivotToolbar}
            />
          ) : (
            <Chart
              response={response}
              chartType={chartType}
              chartCardHeight={chartCardHeight}
              // title={title}
              widgetProp={widgetProp}
              titleProperties={titleProperties}
              chartProperties={chartProperties}
              prop={prop}
              isChartLoading={isChartLoading}
              setIsChartLoading={setIsChartLoading}
            />
          )}
        </Box>
      ) : (
        <Typography>No Data Found</Typography>
      )}
    </>
  );
};

export default ChartApiLayerComponent;
