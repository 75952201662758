export const setCatalogData = (payload) => ({
  type: "SET_CATALOG_DATA",
  payload,
});

export const setDataBase = (payload) => ({
  type: "SET_DATABASE",
  payload,
});

export const setConnectionData = (payload) => ({
  type: "SET_CONNECTION_DATA",
  payload,
});

export const setTestConnection = (payload) => ({
  type: "SET_TEST_CONNECTION",
  payload,
});

export const setAllConnection = (payload) => ({
  type: "SET_ALL_CONNECTION",
  payload,
});

export const setAllCatalog = (payload) => ({
  type: "SET_ALL_CATALOG",
  payload,
});

export const setQueryResponse = (payload) => ({
  type: "SET_QUERY_RESPONSE",
  payload,
});

export const setAskQuestion = (payload) => ({
  type: "SET_ASK_QUESTION",
  payload,
});

export const setPiviotData = (payload) => ({
  type: "SET_PIVIOT_DATA",
  payload,
});

export const setAllBoards = (payload) => ({
  type: "SET_ALL_BOARDS",
  payload,
});

export const setFilter = (payload) => ({
  type: "SET_FILTER",
  payload,
});

export const setWidget = (payload) => ({
  type: "SET_WIDGET",
  payload,
});
export const setTitleProperties = (payload) => ({
  type: "SET_TITLE_PROPERTIES",
  payload,
});
export const setChartProperties = (payload) => ({
  type: "SET_CHART_PROPERTIES",
  payload,
});

export const setUseFromRedux = (payload) => ({
  type: "SET_USE_FROM_REDUX",
  payload,
});

export const setSideBarOpen = (payload) => ({
  type: "SET_SIDE_BAR_OPEN",
  payload,
});

export const setUpdateObjectData = (payload) => ({
  type: "SET_UPDATE_OBJECT_DATA",
  payload,
});

export const setUserDetails = (payload) => ({
  type: "SET_USER_DETAILS",
  payload,
});

export const setAllUsers = (payload) => ({
  type: "SET_ALL_USER",
  payload,
});

export const setSelectedData = (payload) => ({
  type: "SET_SELECTED_DATA",
  payload,
});