import {
  Box,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
// import PivotTableChartRoundedIcon from "@mui/icons-material/PivotTableChartRounded";
import CodeIcon from "@mui/icons-material/Code";
import { useDispatch, useSelector } from "react-redux";
import { selectSideBarOpen } from "../../../redux/selectors";
import { setSideBarOpen } from "../../../redux/actions";
const SideBar = () => {
  const sideBarOpen = useSelector(selectSideBarOpen);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const handleToggle = () => {
    dispatch(setSideBarOpen(!sideBarOpen));
  };

  const role = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).roles[0]
    : null;

  const isItemSelected = (path) => location.pathname === path;

  return (
    <Box>
      <Drawer
        id="sidebar"
        variant="permanent"
        anchor="left"
        open={sideBarOpen}
        sx={{
          transition: "all 1s ease",
          display: { xs: sideBarOpen ? "block" : "none", md: "block" },
          position: "absolute",
          zIndex: 1200,
          [`& .MuiDrawer-paper`]: {
            top: 0,
            maxHeight: "100vh",
            width: {
              xs: "50%",
              sm: "40%",
              md: sideBarOpen ? "200px" : "100px",
            },
          },
        }}
        // ref={sidebarRef}
      >
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // width: "100px",
            backgroundColor: "#FFFFFF",
            height: "100vh",
            marginTop: "3rem",
            overflowX: "hidden",
            padding: "12px",
          }}
        >
          <Hidden mdDown>
            <Tooltip title={sideBarOpen ? "Collapse" : "Expand"}>
              <ListItem
                onClick={handleToggle}
                sx={{ justifyContent: "center", cursor: "pointer" }}
              >
                <Box>
                  <IconButton
                  // onClick={handleToggle}
                  // sx={{ position: "absolute", right: "0", top: "0" }}
                  >
                    <CodeIcon />
                  </IconButton>
                </Box>
              </ListItem>
            </Tooltip>
          </Hidden>
          <Tooltip title={"Ask Business Question"}>
            <ListItem
              disabled={role === "user"}
              onClick={() => {
                if (role !== "user") {
                  navigate("/" ,{ state: { prevPath: location.pathname } });
                }
              }}
              sx={{
                my: 2,
                cursor: "pointer",
                // width: "72px",
                height: "64.456px",
                borderRadius: "15px",
                padding: "5px",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                // flexDirection: "column",
                ":hover": {
                  backgroundColor: "#0C247B",
                  color: "#FFFFFF",
                  "& .MuiListItemIcon-root, & .MuiListItemText-root": {
                    color: "#FFFFFF",
                  },
                },
                ...(isItemSelected("/") && {
                  backgroundColor: "#0C247B",
                  color: "#FFFFFF",
                }),
              }}
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ":hover": {
                    backgroundColor: "#0C247B",
                    color: "#FFFFFF",
                  },
                  ...(isItemSelected("/") && {
                    backgroundColor: "#0C247B",
                    color: "#FFFFFF",
                  }),
                }}
              >
                <DonutSmallOutlinedIcon fontSize="large" />
              </ListItemIcon>
              {sideBarOpen && <ListItemText primary={"Ask Question"} />}
            </ListItem>
          </Tooltip>
          <Tooltip title={"Answer Board"}>
            <ListItem
              onClick={() => {
                navigate("/answer-board");
              }}
              sx={{
                my: 2,
                cursor: "pointer",
                height: "64.456px",
                borderRadius: "15px",
                padding: "5px",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                ":hover": {
                  backgroundColor: "#0C247B",
                  color: "#FFFFFF",
                  "& .MuiListItemIcon-root, & .MuiListItemText-root": {
                    color: "#FFFFFF",
                  },
                },
                ...(isItemSelected("/answer-board") && {
                  backgroundColor: "#0C247B",
                  color: "#FFFFFF",
                }),
              }}
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ":hover": {
                    backgroundColor: "#0C247B",
                    color: "#FFFFFF",
                  },
                  ...(isItemSelected("/answer-board") && {
                    backgroundColor: "#0C247B",
                    color: "#FFFFFF",
                  }),
                }}
              >
                <FolderCopyOutlinedIcon fontSize="large" />
              </ListItemIcon>
              {sideBarOpen && <ListItemText primary={"Answer Board"} />}
            </ListItem>
          </Tooltip>
          <Tooltip title={"Connection"}>
            <ListItem
              disabled={role === "user" || role === "admin"}
              onClick={() => {
                if (role !== "user" || role !== "admin") {
                  navigate("/catalog/connection/create-connection");
                }
              }}
              sx={{
                my: 2,
                cursor: "pointer",
                // width: "72px",
                height: "64.456px",
                borderRadius: "15px",
                padding: "5px",
                justifyContent: "center",
                display: role === "user" || role === "admin" ? "none" : "flex",
                alignItems: "center",
                // flexDirection: "column",
                // alignItems: "center",
                ":hover": {
                  backgroundColor: "#0C247B",
                  color: "#FFFFFF",
                  "& .MuiListItemIcon-root, & .MuiListItemText-root": {
                    color: "#FFFFFF",
                  },
                },
              }}
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ":hover": {
                    backgroundColor: "#0C247B",
                    color: "#FFFFFF",
                  },
                }}
              >
                <StorageOutlinedIcon fontSize="large" />
              </ListItemIcon>
              {sideBarOpen && <ListItemText primary={"Connection"} />}
            </ListItem>
          </Tooltip>
          <Tooltip title={"Catalog Management"}>
            <ListItem
              onClick={() => {
                navigate("/catalog-management");
              }}
              sx={{
                my: 2,
                cursor: "pointer",
                // width: "72px",
                height: "64.456px",
                borderRadius: "15px",
                padding: "5px",
                justifyContent: "center",
                display: role === "user" || role === "admin" ? "none" : "flex",
                alignItems: "center",
                // flexDirection: "column",
                // alignItems: "center",
                ":hover": {
                  backgroundColor: "#0C247B",
                  color: "#FFFFFF",
                  "& .MuiListItemIcon-root, & .MuiListItemText-root": {
                    color: "#FFFFFF",
                  },
                },
                ...(isItemSelected("/catalog-management") && {
                  backgroundColor: "#0C247B",
                  color: "#FFFFFF",
                }),
              }}
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ":hover": {
                    backgroundColor: "#0C247B",
                    color: "#FFFFFF",
                  },
                  ...(isItemSelected("/catalog-management") && {
                    backgroundColor: "#0C247B",
                    color: "#FFFFFF",
                  }),
                }}
              >
                <RoomPreferencesIcon fontSize="large" />
              </ListItemIcon>
              {sideBarOpen && <ListItemText primary={"Catalog Management"} />}
            </ListItem>
          </Tooltip>
          <Tooltip title={"Dashboard Management"}>
            <ListItem
              onClick={() => {
                navigate("/dashboard-management");
              }}
              sx={{
                my: 2,
                cursor: "pointer",
                // width: "72px",
                height: "64.456px",
                borderRadius: "15px",
                padding: "5px",
                justifyContent: "center",
                display: role === "user" || role === "admin" ? "none" : "flex",
                alignItems: "center",
                // flexDirection: "column",
                // alignItems: "center",
                ":hover": {
                  backgroundColor: "#0C247B",
                  color: "#FFFFFF",
                  "& .MuiListItemIcon-root, & .MuiListItemText-root": {
                    color: "#FFFFFF",
                  },
                },
                ...(isItemSelected("/dashboard-management") && {
                  backgroundColor: "#0C247B",
                  color: "#FFFFFF",
                }),
              }}
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ":hover": {
                    backgroundColor: "#0C247B",
                    color: "#FFFFFF",
                  },
                  ...(isItemSelected("/dashboard-management") && {
                    backgroundColor: "#0C247B",
                    color: "#FFFFFF",
                  }),
                }}
              >
                <DashboardIcon fontSize="large" />
              </ListItemIcon>
              {sideBarOpen && <ListItemText primary={"Dashboard Management"} />}
            </ListItem>
          </Tooltip>
          <Tooltip title={"User Management"}>
            <ListItem
              onClick={() => {
                navigate("/user-management");
              }}
              sx={{
                my: 2,
                cursor: "pointer",
                // width: "72px",
                height: "64.456px",
                borderRadius: "15px",
                padding: "5px",
                justifyContent: "center",
                display: role === "user" || role === "admin" ? "none" : "flex",
                alignItems: "center",
                // flexDirection: "column",
                // alignItems: "center",
                ":hover": {
                  backgroundColor: "#0C247B",
                  color: "#FFFFFF",
                  "& .MuiListItemIcon-root, & .MuiListItemText-root": {
                    color: "#FFFFFF",
                  },
                },
                ...(isItemSelected("/user-management") && {
                  backgroundColor: "#0C247B",
                  color: "#FFFFFF",
                }),
              }}
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ":hover": {
                    backgroundColor: "#0C247B",
                    color: "#FFFFFF",
                  },
                  ...(isItemSelected("/user-management") && {
                    backgroundColor: "#0C247B",
                    color: "#FFFFFF",
                  }),
                }}
              >
                <PersonIcon fontSize="large" />
              </ListItemIcon>
              {sideBarOpen && <ListItemText primary={"User Management"} />}
            </ListItem>
          </Tooltip>
          <Tooltip title={"Logout"}>
            <ListItem
              onClick={() => {
                navigate("/logout");
              }}
              sx={{
                my: 2,
                cursor: "pointer",
                // width: "72px",
                height: "64.456px",
                borderRadius: "15px",
                padding: "5px",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                // flexDirection: "column",
                // alignItems: "center",
                ":hover": {
                  backgroundColor: "#0C247B",
                  color: "#FFFFFF",
                  "& .MuiListItemIcon-root, & .MuiListItemText-root": {
                    color: "#FFFFFF",
                  },
                },
              }}
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ":hover": {
                    backgroundColor: "#0C247B",
                    color: "#FFFFFF",
                  },
                }}
              >
                <LoginOutlinedIcon fontSize="large" />
              </ListItemIcon>
              {sideBarOpen && <ListItemText primary={"Logout"} />}
            </ListItem>
          </Tooltip>
        </List>
      </Drawer>
      <Divider color="blue" />
    </Box>
  );
};
export default SideBar;
