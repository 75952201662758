import axios from "axios";
import { apiEndPointsConfig } from "../apiEndPointsConfig";
import { baseURL } from "../../configs/envConfig";
import { axiosInstance, axiosInstanceCore } from "../axiosInstance";

export const getClients = async (data) => {
  try {
    const res = await axios.post(
      `${baseURL}${apiEndPointsConfig.getClients}`,
      data
    );
    console.log("Get Client");
    return res;
  } catch (err) {
    return err;
  }
};

export const getToken = async (data) => {
  try {
    const res = await axios.post(
      `${baseURL}${apiEndPointsConfig.getToken}`,
      data
    );
    console.log("Get Token");
    return res;
  } catch (err) {
    return err;
  }
};

export const getUsers = async () => {
  try {
    const res = await axiosInstanceCore.get(`${apiEndPointsConfig.getUsers}`);
    console.log("All Users");
    return res;
  } catch (err) {
    return err;
  }
};

export const getUsersToken = async (token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_GATEWAY_URL}${apiEndPointsConfig.getUsers}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("All Users");
    return res;
  } catch (err) {
    return err;
  }
};
