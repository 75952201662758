import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import { setAskQuestion, setUpdateObjectData } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import GridOnIcon from "@mui/icons-material/GridOn";
import {
  selectAskQuestion,
  selectUpdateObjectData,
} from "../../redux/selectors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
const ChartType = ({
  numberOfProperties,
  update,
  resLength,
  setIsChartLoading,
}) => {
  debugger;
  const UpdateObjectData = useSelector(selectUpdateObjectData);

  const AskQuestion = useSelector(selectAskQuestion);
  const selectedChartType = update
    ? UpdateObjectData?.ObjectData?.data?.result?.visualization?.type
    : AskQuestion.chartType;
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const shouldDisableChartType = (chartType) => {
    if (numberOfProperties === 3) {
      return [
        "pie",
        "widget",
        "StackLine",
        "StackBar",
        "ClusterBar",
        "TreeMap",
      ].includes(chartType);
    } else if (numberOfProperties === 2) {
      return [
        "LineStackBar",
        "widget",
        "StackLine",
        "StackBar",
        "ClusterBar",
      ].includes(chartType);
    } else if (numberOfProperties === 1 && resLength > 1) {
      return [
        "LineStackBar",
        "bar",
        "line",
        "Aline",
        "pie",
        "Hbar",
        "StackLine",
        "StackBar",
        "widget",
        "ClusterBar",
        "TreeMap",
      ].includes(chartType);
    } else if (numberOfProperties === 1 && resLength === 1) {
      return [
        "LineStackBar",
        "bar",
        "line",
        "Aline",
        "pie",
        "Hbar",
        "StackLine",
        "StackBar",
        "ClusterBar",
        "TreeMap",
      ].includes(chartType);
    }
    return [
      "LineStackBar",
      "bar",
      "line",
      "Aline",
      "pie",
      "Hbar",
      "widget",
      "TreeMap",
    ].includes(chartType);
  };
  console.log(resLength);
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Box
        sx={{
          p: 1,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          width: "100%",
          gap: "10px",
          flexDirection: "column",
        }}
      >
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleAccordionChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>Bar</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "2px" }}>
              <Box
                onClick={() => {
                  if (selectedChartType !== "Hbar") {
                    setIsChartLoading(true);
                  }
                  update
                    ? dispatch(
                        setUpdateObjectData({
                          ObjectData: {
                            ...UpdateObjectData.ObjectData,
                            data: {
                              ...UpdateObjectData?.ObjectData.data,
                              result: {
                                ...UpdateObjectData?.ObjectData.data?.result,
                                visualization: {
                                  ...UpdateObjectData?.ObjectData.data?.result
                                    ?.visualization,
                                  type: "Hbar",
                                },
                              },
                            },
                          },
                        })
                      )
                    : dispatch(
                        setAskQuestion({
                          chartType: "Hbar",
                        })
                      );
                }}
                sx={{
                  width: "80px",
                  cursor: shouldDisableChartType("Hbar")
                    ? "not-allowed"
                    : "pointer",
                  pointerEvents: shouldDisableChartType("Hbar")
                    ? "none"
                    : "auto",
                  opacity: shouldDisableChartType("Hbar") ? "0.2" : "1",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/Bar/Bar.png"}
                  alt=""
                />

                <Typography
                  sx={{
                    mt: 0.5,
                    textAlign: "center",
                    color: "#4F4F4F",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Horizontal Bar
                </Typography>
              </Box>

              <Box
                onClick={() => {
                  if (selectedChartType !== "bar") {
                    setIsChartLoading(true);
                  }
                  update
                    ? dispatch(
                        setUpdateObjectData({
                          ObjectData: {
                            ...UpdateObjectData.ObjectData,
                            data: {
                              ...UpdateObjectData?.ObjectData.data,
                              result: {
                                ...UpdateObjectData?.ObjectData.data?.result,
                                visualization: {
                                  ...UpdateObjectData?.ObjectData.data?.result
                                    ?.visualization,
                                  type: "bar",
                                },
                              },
                            },
                          },
                        })
                      )
                    : dispatch(
                        setAskQuestion({
                          chartType: "bar",
                        })
                      );
                }}
                sx={{
                  width: "80px",
                  cursor: shouldDisableChartType("bar")
                    ? "not-allowed"
                    : "pointer",
                  pointerEvents: shouldDisableChartType("bar")
                    ? "none"
                    : "auto",
                  opacity: shouldDisableChartType("bar") ? "0.2" : "1",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/Bar/Colunm.png"}
                  alt=""
                />

                <Typography
                  sx={{
                    mt: 0.5,
                    textAlign: "center",
                    color: "#4F4F4F",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Bar
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  if (selectedChartType !== "StackBar") {
                    setIsChartLoading(true);
                  }
                  update
                    ? dispatch(
                        setUpdateObjectData({
                          ObjectData: {
                            ...UpdateObjectData.ObjectData,
                            data: {
                              ...UpdateObjectData?.ObjectData.data,
                              result: {
                                ...UpdateObjectData?.ObjectData.data?.result,
                                visualization: {
                                  ...UpdateObjectData?.ObjectData.data?.result
                                    ?.visualization,
                                  type: "StackBar",
                                },
                              },
                            },
                          },
                        })
                      )
                    : dispatch(
                        setAskQuestion({
                          chartType: "StackBar",
                        })
                      );
                }}
                sx={{
                  width: "80px",
                  cursor: shouldDisableChartType("StackBar")
                    ? "not-allowed"
                    : "pointer",
                  pointerEvents: shouldDisableChartType("StackBar")
                    ? "none"
                    : "auto",
                  opacity: shouldDisableChartType("StackBar") ? "0.2" : "1",
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/Bar/StackedBar.png"
                  }
                  alt=""
                />
                <Typography
                  sx={{
                    mt: 0.5,
                    textAlign: "center",
                    color: "#4F4F4F",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Stack Bar
                </Typography>
              </Box>

              <Box
                onClick={() => {
                  if (selectedChartType !== "ClusterBar") {
                    setIsChartLoading(true);
                  }
                  update
                    ? dispatch(
                        setUpdateObjectData({
                          ObjectData: {
                            ...UpdateObjectData.ObjectData,
                            data: {
                              ...UpdateObjectData?.ObjectData.data,
                              result: {
                                ...UpdateObjectData?.ObjectData.data?.result,
                                visualization: {
                                  ...UpdateObjectData?.ObjectData.data?.result
                                    ?.visualization,
                                  type: "ClusterBar",
                                },
                              },
                            },
                          },
                        })
                      )
                    : dispatch(
                        setAskQuestion({
                          chartType: "ClusterBar",
                        })
                      );
                }}
                sx={{
                  width: "80px",
                  cursor: shouldDisableChartType("ClusterBar")
                    ? "not-allowed"
                    : "pointer",
                  pointerEvents: shouldDisableChartType("ClusterBar")
                    ? "none"
                    : "auto",
                  opacity: shouldDisableChartType("ClusterBar") ? "0.2" : "1",
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/Bar/clusterBar.png"
                  }
                  alt=""
                />
                <Typography
                  sx={{
                    mt: 0.5,
                    textAlign: "center",
                    color: "#4F4F4F",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Cluster Bar
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  if (selectedChartType !== "LineStackBar") {
                    setIsChartLoading(true);
                  }
                  update
                    ? dispatch(
                        setUpdateObjectData({
                          ObjectData: {
                            ...UpdateObjectData.ObjectData,
                            data: {
                              ...UpdateObjectData?.ObjectData.data,
                              result: {
                                ...UpdateObjectData?.ObjectData.data?.result,
                                visualization: {
                                  ...UpdateObjectData?.ObjectData.data?.result
                                    ?.visualization,
                                  type: "LineStackBar",
                                },
                              },
                            },
                          },
                        })
                      )
                    : dispatch(
                        setAskQuestion({
                          chartType: "LineStackBar",
                        })
                      );
                }}
                sx={{
                  width: "80px",
                  cursor: shouldDisableChartType("LineStackBar")
                    ? "not-allowed"
                    : "pointer",
                  pointerEvents: shouldDisableChartType("LineStackBar")
                    ? "none"
                    : "auto",
                  opacity: shouldDisableChartType("LineStackBar") ? "0.2" : "1",
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/Bar/BarLine.png"
                  }
                  alt=""
                />

                <Typography
                  sx={{
                    mt: 0.5,
                    textAlign: "center",
                    color: "#4F4F4F",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Line Stack Bar
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        {/* <Divider /> */}
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleAccordionChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography>Line</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Box
                onClick={() => {
                  if (selectedChartType !== "line") {
                    setIsChartLoading(true);
                  }
                  update
                    ? dispatch(
                        setUpdateObjectData({
                          ObjectData: {
                            ...UpdateObjectData.ObjectData,
                            data: {
                              ...UpdateObjectData?.ObjectData.data,
                              result: {
                                ...UpdateObjectData?.ObjectData.data?.result,
                                visualization: {
                                  ...UpdateObjectData?.ObjectData.data?.result
                                    ?.visualization,
                                  type: "line",
                                },
                              },
                            },
                          },
                        })
                      )
                    : dispatch(
                        setAskQuestion({
                          chartType: "line",
                        })
                      );
                }}
                sx={{
                  width: "80px",
                  cursor: shouldDisableChartType("line")
                    ? "not-allowed"
                    : "pointer",
                  pointerEvents: shouldDisableChartType("line")
                    ? "none"
                    : "auto",
                  opacity: shouldDisableChartType("line") ? "0.2" : "1",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/Bar/line.png"}
                  alt=""
                />

                <Typography
                  sx={{
                    mt: 0.5,
                    textAlign: "center",
                    color: "#4F4F4F",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Line
                </Typography>
              </Box>

              <Box
                onClick={() => {
                  if (selectedChartType !== "Aline") {
                    setIsChartLoading(true);
                  }
                  update
                    ? dispatch(
                        setUpdateObjectData({
                          ObjectData: {
                            ...UpdateObjectData.ObjectData,
                            data: {
                              ...UpdateObjectData?.ObjectData.data,
                              result: {
                                ...UpdateObjectData?.ObjectData.data?.result,
                                visualization: {
                                  ...UpdateObjectData?.ObjectData.data?.result
                                    ?.visualization,
                                  type: "Aline",
                                },
                              },
                            },
                          },
                        })
                      )
                    : dispatch(
                        setAskQuestion({
                          chartType: "Aline",
                        })
                      );
                }}
                sx={{
                  width: "80px",
                  cursor: shouldDisableChartType("Aline")
                    ? "not-allowed"
                    : "pointer",
                  pointerEvents: shouldDisableChartType("Aline")
                    ? "none"
                    : "auto",
                  opacity: shouldDisableChartType("Aline") ? "0.2" : "1",
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/Bar/areaLine.png"
                  }
                  alt=""
                />

                <Typography
                  sx={{
                    mt: 0.5,
                    textAlign: "center",
                    color: "#4F4F4F",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Area Line
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  if (selectedChartType !== "StackLine") {
                    setIsChartLoading(true);
                  }
                  update
                    ? dispatch(
                        setUpdateObjectData({
                          ObjectData: {
                            ...UpdateObjectData.ObjectData,
                            data: {
                              ...UpdateObjectData?.ObjectData.data,
                              result: {
                                ...UpdateObjectData?.ObjectData.data?.result,
                                visualization: {
                                  ...UpdateObjectData?.ObjectData.data?.result
                                    ?.visualization,
                                  type: "StackLine",
                                },
                              },
                            },
                          },
                        })
                      )
                    : dispatch(
                        setAskQuestion({
                          chartType: "StackLine",
                        })
                      );
                }}
                sx={{
                  width: "80px",
                  cursor: shouldDisableChartType("StackLine")
                    ? "not-allowed"
                    : "pointer",
                  pointerEvents: shouldDisableChartType("StackLine")
                    ? "none"
                    : "auto",
                  opacity: shouldDisableChartType("StackLine") ? "0.2" : "1",
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/Bar/StackedLine.png"
                  }
                  alt=""
                />
                <Typography
                  sx={{
                    mt: 0.5,
                    textAlign: "center",
                    color: "#4F4F4F",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Stack Line
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        {/* <Divider /> */}
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleAccordionChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography>Treemap</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Box
                onClick={() => {
                  if (selectedChartType !== "TreeMap") {
                    setIsChartLoading(true);
                  }
                  update
                    ? dispatch(
                        setUpdateObjectData({
                          ObjectData: {
                            ...UpdateObjectData.ObjectData,
                            data: {
                              ...UpdateObjectData?.ObjectData.data,
                              result: {
                                ...UpdateObjectData?.ObjectData.data?.result,
                                visualization: {
                                  ...UpdateObjectData?.ObjectData.data?.result
                                    ?.visualization,
                                  type: "TreeMap",
                                },
                              },
                            },
                          },
                        })
                      )
                    : dispatch(
                        setAskQuestion({
                          chartType: "TreeMap",
                        })
                      );
                }}
                sx={{
                  width: "80px",
                  cursor: shouldDisableChartType("ATreeMapline")
                    ? "not-allowed"
                    : "pointer",
                  pointerEvents: shouldDisableChartType("TreeMap")
                    ? "none"
                    : "auto",
                  opacity: shouldDisableChartType("TreeMap") ? "0.2" : "1",
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/Bar/TreeMap.png"
                  }
                  alt=""
                />

                <Typography
                  sx={{
                    mt: 0.5,
                    textAlign: "center",
                    color: "#4F4F4F",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Tree Map
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        {/* <Divider /> */}
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleAccordionChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography>Pie</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Box
                onClick={() => {
                  if (selectedChartType !== "pie") {
                    setIsChartLoading(true);
                  }
                  update
                    ? dispatch(
                        setUpdateObjectData({
                          ObjectData: {
                            ...UpdateObjectData.ObjectData,
                            data: {
                              ...UpdateObjectData?.ObjectData.data,
                              result: {
                                ...UpdateObjectData?.ObjectData.data?.result,
                                visualization: {
                                  ...UpdateObjectData?.ObjectData.data?.result
                                    ?.visualization,
                                  type: "pie",
                                },
                              },
                            },
                          },
                        })
                      )
                    : dispatch(
                        setAskQuestion({
                          chartType: "pie",
                        })
                      );
                }}
                sx={{
                  width: "80px",
                  cursor: shouldDisableChartType("pie")
                    ? "not-allowed"
                    : "pointer",
                  pointerEvents: shouldDisableChartType("pie")
                    ? "none"
                    : "auto",
                  opacity: shouldDisableChartType("pie") ? "0.2" : "1",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/Bar/pie.png"}
                  alt=""
                />

                <Typography
                  sx={{
                    mt: 0.5,
                    textAlign: "center",
                    color: "#4F4F4F",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Pie
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        {/* <Divider /> */}
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleAccordionChange("panel5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
          >
            <Typography>Widget</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Box
                onClick={() =>
                  update
                    ? dispatch(
                        setUpdateObjectData({
                          ObjectData: {
                            ...UpdateObjectData.ObjectData,
                            data: {
                              ...UpdateObjectData?.ObjectData.data,
                              result: {
                                ...UpdateObjectData?.ObjectData.data?.result,
                                visualization: {
                                  ...UpdateObjectData?.ObjectData.data?.result
                                    ?.visualization,
                                  type: "widget",
                                },
                              },
                            },
                          },
                        })
                      )
                    : dispatch(
                        setAskQuestion({
                          chartType: "widget",
                        })
                      )
                }
                sx={{
                  width: "80px",
                  cursor: shouldDisableChartType("widget")
                    ? "not-allowed"
                    : "pointer",
                  pointerEvents: shouldDisableChartType("widget")
                    ? "none"
                    : "auto",
                  opacity: shouldDisableChartType("widget") ? "0.2" : "1",
                }}
              >
                <Box
                  sx={{
                    width: "71px",
                    height: "61px",
                    borderRadius: "2px",
                    border: " 1.5px solid #CCC",
                    background: " #F9F9F9",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/Bar/widget.png"
                    }
                    alt=""
                  />
                </Box>

                <Typography
                  sx={{
                    mt: 0.5,
                    textAlign: "center",
                    color: "#4F4F4F",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Widget
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        {/* <Divider /> */}
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleAccordionChange("panel6")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            <Typography>Table</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Box
                onClick={() =>
                  update
                    ? dispatch(
                        setUpdateObjectData({
                          ObjectData: {
                            ...UpdateObjectData.ObjectData,
                            data: {
                              ...UpdateObjectData?.ObjectData.data,
                              result: {
                                ...UpdateObjectData?.ObjectData.data?.result,
                                visualization: {
                                  ...UpdateObjectData?.ObjectData.data?.result
                                    ?.visualization,
                                  type: "grid",
                                },
                              },
                            },
                          },
                        })
                      )
                    : dispatch(
                        setAskQuestion({
                          chartType: "grid",
                        })
                      )
                }
                sx={{
                  width: "80px",
                  cursor: shouldDisableChartType("grid")
                    ? "not-allowed"
                    : "pointer",
                  pointerEvents: shouldDisableChartType("grid")
                    ? "none"
                    : "auto",
                  opacity: shouldDisableChartType("grid") ? "0.2" : "1",
                }}
              >
                <Box
                  sx={{
                    width: "71px",
                    height: "61px",
                    borderRadius: "2px",
                    border: " 1.5px solid #CCC",
                    background: " #F9F9F9",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <GridOnIcon sx={{ fontSize: "50px", color: "red" }} />
                </Box>
                <Typography
                  sx={{
                    mt: 0.5,
                    textAlign: "center",
                    color: "#4F4F4F",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Grid
                </Typography>
              </Box>

              <Box
                onClick={() =>
                  update
                    ? dispatch(
                        setUpdateObjectData({
                          ObjectData: {
                            ...UpdateObjectData.ObjectData,
                            data: {
                              ...UpdateObjectData?.ObjectData.data,
                              result: {
                                ...UpdateObjectData?.ObjectData.data?.result,
                                visualization: {
                                  ...UpdateObjectData?.ObjectData.data?.result
                                    ?.visualization,
                                  type: "Pivot",
                                },
                              },
                            },
                          },
                        })
                      )
                    : dispatch(
                        setAskQuestion({
                          chartType: "Pivot",
                        })
                      )
                }
                sx={{
                  width: "80px",
                  cursor: shouldDisableChartType("Pivot")
                    ? "not-allowed"
                    : "pointer",
                  pointerEvents: shouldDisableChartType("Pivot")
                    ? "none"
                    : "auto",
                  opacity: shouldDisableChartType("Pivot") ? "0.2" : "1",
                }}
              >
                <Box
                  sx={{
                    width: "71px",
                    height: "61px",
                    borderRadius: "2px",
                    border: " 1.5px solid #CCC",
                    background: " #F9F9F9",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/Bar/Pivot.png"
                    }
                    alt=""
                  />
                </Box>

                <Typography
                  sx={{
                    mt: 0.5,
                    textAlign: "center",
                    color: "#4F4F4F",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Pivot
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default ChartType;
