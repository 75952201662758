import { Avatar, Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ManagementButton from "../../utils/ManagementButton";
import UserManagementButton from "../../utils/UserManagementButton";

const UserCard = ({
  idpRole,
  name,
  email,
  profile,
  onClickDelete,
  role,
  board,
  showCreatorButton,
  dashboardUserRole,
  showDeleteIcon,
}) => {
  const [selectedRole, setSelectedRole] = useState({
    value: "viewer",
    index: "0",
  });
  console.log("showCreatorButton", showCreatorButton);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="7px"
      >
        <Box display="flex" alignItems="center">
          <Avatar src={profile ? profile : ""} alt="avatar">
            {profile
              ? ""
              : name
              ? name?.charAt(0).toUpperCase()
              : email?.charAt(0).toUpperCase()}
          </Avatar>
          <Box ml={1}>
            <Typography variant="subtitle1" component="div">
              {name ? name : ""}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {email ? email : ""}
            </Typography>
          </Box>
        </Box>

        {showCreatorButton === true ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {idpRole !== "Super Admin" && (
              <UserManagementButton
                idpRole={idpRole}
                selectedRole={selectedRole}
                setSelectedRole={setSelectedRole}
                role={role}
                email={email}
                board={board}
                dashboardUserRole={dashboardUserRole}
              />
            )}

            { onClickDelete ? (
              <IconButton onClick={onClickDelete}>
                <DeleteOutlineIcon />
              </IconButton>
            ) : (
              ""
            )}
          </Box>
        ) : (
          ""
        )}

        {showDeleteIcon === true && onClickDelete ? (
          <IconButton onClick={onClickDelete}>
            <DeleteOutlineIcon />
          </IconButton>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};

export default UserCard;
