import axios from "axios";
import { setToken } from "./jwtService";

const baseURL = `${process.env.REACT_APP_GATEWAY_URL}/qnai/api/v1`;
const publicBaseURL = `${process.env.REACT_APP_GATEWAY_URL}/qnai/public/api/v1`;

const getAccessTokenFromStorage = () => {
  const token = localStorage.getItem("token");
  try {
    const tokenObj = JSON.parse(token);
    if (tokenObj) {
      return JSON.parse(localStorage.getItem("token")).access_token;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const axiosInstanceCore = axios.create({
  baseURL: process.env.REACT_APP_GATEWAY_URL,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    Authorization: `Bearer ${getAccessTokenFromStorage()}`,
  },
});

export const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export const publicAxiosInstance = axios.create({
  baseURL: publicBaseURL,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    // Authorization: `Bearer ${getAccessTokenFromStorage()}`,
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("token"));
    const accessToken = user?.access_token;
    if (accessToken != undefined || accessToken != null) {
      config.headers["Authorization"] = `Bearer ${getAccessTokenFromStorage()}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (!error.response) {
      alert(
        "A server/network error occurred. " +
          "Looks like CORS might be the problem. " +
          "Sorry about this - we will get it fixed shortly."
      );
      return Promise.reject(error);
    }
    if (error.response.status === 401) {
      const refreshToken = localStorage.getItem("token")
        ? JSON.parse(localStorage.getItem("token")).refresh_token
        : null;
      if (refreshToken) {
        try {
          const refreshResponse = await axios.post(
            `${process.env.REACT_APP_GATEWAY_URL}/core/refresh-token`,
            {
              data: {
                clientName: "qnai",
                refreshToken: refreshToken,
              },
            }
          );
          localStorage.setItem(
            "token",
            JSON.stringify(refreshResponse?.data.data)
          );
          const newToken = refreshResponse.data.data.access_token;
          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${newToken}`;
          error.config.headers["Authorization"] = "Bearer " + `${newToken}`;
          return axiosInstance.request(error.config);
        } catch (refreshError) {
          setToken(null);
          window.location.href = "/";
          return Promise.reject(refreshError);
        }
      } else {
        localStorage.clear();
        window.location.href = "/login";
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);
