export const modifyDataType = (dataType) => {
  if (dataType?.includes("Date")) {
    return "Date";
  } else if (dataType?.includes("String")) {
    return "String";
  } else if (dataType?.includes("Int64")) {
    return "Number";
  } else if (dataType?.includes("Float64")) {
    return "Number";
  } else {
    return "Other";
  }
};
