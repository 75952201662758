import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Divider, Typography } from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getAllConnection, saveConnection } from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllConnection,
  setCatalogData,
  setConnectionData,
  setTestConnection,
} from "../../../redux/actions";
import {
  selectConnectionData,
  selectDataBase,
  selectTestConnection,
} from "../../../redux/selectors";
import CommonDialog from "../../../utils/Dialog";

const Connection = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const testConnection = useSelector(selectTestConnection);
  const connectionData = useSelector(selectConnectionData);
  const dataBase = useSelector(selectDataBase);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  useEffect(() => {
    getAllConnectionAsync();
  }, []);

  const getAllConnectionAsync = async () => {
    const res = await getAllConnection();
    dispatch(setAllConnection(res?.data?.data.result));
  };

  const ConnectionHandle = () => {
    dispatch(setTestConnection(false));
    if (location.pathname === "/catalog/connection/create-connection") {
      navigate("/catalog/connection/select-existing");
    } else {
      navigate("/catalog/connection/create-connection");
    }
  };

  const handleNext = async () => {
    if (location.pathname === "/catalog/connection/select-existing") {
      navigate("/catalog/select-table");
    } else {
      const data = {
        data: connectionData,
      };
      const res = await saveConnection(data);

      if (res?.data?.success === true) {
        dispatch(
          setCatalogData({
            connectionId: res?.data?.data?.result?.result?._id,
          })
        );
        navigate("/catalog/select-table");
      }
    }
  };

  // const handleCancel = () => {
  //   navigate("/");
  // };

  const handleCancel = () => {
    const isConnectionDataNotEmpty =
      Object.values(connectionData).some((value) => value && value !== "") ||
      (location.pathname === "/catalog/connection/select-existing" && dataBase);
    if (isConnectionDataNotEmpty) {
      setIsCancelDialogOpen(true);
    } else {
      navigate("/");
    }
    // setIsCancelDialogOpen(true);
  };
  const handleOpenCancelDialog = () => {
    setIsCancelDialogOpen(false);
    navigate("/");
    dispatch(
      setConnectionData({
        name: "",
        host: "",
        port: "",
        database: "",
        username: "",
        password: "",
        driver: "",
      })
    );
  };
  const handleCloseCancelDialog = () => {
    setIsCancelDialogOpen(false);
  };
  const dialogActions = [
    {
      label: "No",
      color: "primary",
      onClick: handleCloseCancelDialog,
    },
    {
      label: "Yes",
      color: "warning",
      onClick: handleOpenCancelDialog,
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          // position: "fixed",
          top: { xs: "30px", sm: "90px" },
          width: "100%",
          // height: { xs: "90vh", sm: "80vh" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "row" },
            px: 3,
            gap: "10px",
          }}
        >
          <Box sx={{ display: "flex", gap: "5px" }}>
            <Avatar
              src={
                process.env.PUBLIC_URL +
                "/assets/images/database-settings-outline.png"
              }
            />
            <Typography
              sx={{
                color: "#2C2C2C",
                fontWeight: "300",
                fontSize: { xs: "20px", md: "35px" },
                // color: "#2C2C2C",
              }}
            >
              Connect to data source
            </Typography>
          </Box>

          <Button
            onClick={ConnectionHandle}
            variant="contained"
            size="small"
            sx={{ backgroundColor: "#5D5FEF", textAlign: "center", py: 0.6 }}
          >
            {location.pathname === "/catalog/connection/create-connection"
              ? "Selection From Existing"
              : "Create New Connection"}
          </Button>
        </Box>
        <Box
          sx={{
            py: 2,
            px: { xs: 2, md: 0 },
            display: "flex",
            // height: "85%",
            // width:"100%",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: { sm: "space-around" },
          }}
        >
          <Box
            sx={{
              px: { xs: 1, sm: 3 },
              // pl: 4,
              width: { xs: "100%", md: "25%" },
              display: { xs: "flex" },
              flexDirection: { xs: "column" },
              justifyContent: { xs: "space-between", sm: "flex-start" },
              gap: "10px",
            }}
          >
            <img
              width={150}
              src={process.env.PUBLIC_URL + "/assets/images/clickHouse.png"}
            />
            <Box sx={{ mt: 1 }}>
              <Box display={"flex"}>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/database-settings-outline (1).png"
                  }
                />
                <Typography sx={{ color: "#000" }}>196.146.11.95</Typography>
              </Box>
            </Box>
          </Box>
          <Divider sx={{ backgroundColor: "#e8e8e8", width: "1.5px" }} />
          <Box
            sx={{
              // pl: 4,
              width: { xs: "100%", md: "75%" },
              //  height:"70%"
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          padding: "20px 22px",
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px",
        }}
      >
        <Button
          onClick={handleCancel}
          variant="outlined"
          size="small"
          sx={{ color: "#5D5FEF", borderColor: "#5D5FEF", py: 0.6 }}
        >
          Cancel&nbsp;
          <CancelRoundedIcon />
        </Button>
        <Button
          disabled={testConnection === true ? false : true}
          onClick={handleNext}
          variant="contained"
          size="small"
          sx={{ backgroundColor: "#5D5FEF", py: 0.6 }}
        >
          Save Next&nbsp;
          <CheckCircleRoundedIcon />
        </Button>
      </Box>
      <CommonDialog
        open={isCancelDialogOpen}
        onClose={handleCloseCancelDialog}
        title="Confirmation Dialog"
        content="Do you confirm your decision to cancel the connection?"
        actions={dialogActions}
      />
    </Box>
  );
};

export default Connection;
