import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const exportAsPDF = (objectId, textToAdd) => {
  const objectElement = document.getElementById(objectId);
  if (!objectElement) return;

  html2canvas(objectElement, {
    // scale: 8,
    logging: true,
    windowWidth: objectElement.scrollWidth,
    windowHeight: objectElement.scrollHeight,
    useCORS: true,
  }).then((canvas) => {
    const pdf = new jsPDF({
      unit: "px",
      format: [canvas.width, canvas.height],
    });

    const imgData = canvas.toDataURL("image/png",0.7);

    const imgWidth = pdf.internal.pageSize.getWidth();
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    // pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

    const textWidth =
      pdf.getStringUnitWidth(textToAdd) * pdf.internal.getFontSize();
    const textX = (imgWidth - textWidth) / 2;
    const textY = 100;
    pdf.setFontSize(20);
    pdf?.text(textToAdd, textX, textY, { align: "center" });
    pdf.addImage(imgData, "PNG", 0, textY  + 15, imgWidth, imgHeight);
    pdf.save(`${objectId}.pdf`);
  });
};
