import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { idpRoleAccess } from "../../utils/utils";
import { StarBorder } from "@mui/icons-material";
import { Star } from "@mui/icons-material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
const BoardCard = ({
  board,
  favouriteBoard,
  role,
  navigate,
  updateFavouriteBoardAsync,
  handleMenuOpen,
}) => {
  const favouriteBoardTooltip = favouriteBoard.includes(board._id)
    ? "Remove from favourite"
    : "Add to favourite";
  return (
    <Box>
      <Card>
        <CardMedia
          component="img"
          height="200"
          image={board.imageUrl}
          onClick={() => navigate(`/answer-board/${board?._id}`)}
          sx={{ cursor: "pointer" }}
        />
        <CardContent
          style={{
            paddingBottom: "0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              color: " #434343",
              fontSize: " 20px",
              fontWeight: "600",
            }}
          >
            {board?.name}
          </Typography>
          <Box sx={{display:"flex"}}>
          <Box>
            <Tooltip title={favouriteBoardTooltip}>
              <IconButton
                onClick={(event) => updateFavouriteBoardAsync(event, board)}
              >
                {favouriteBoard.includes(board._id) ? (
                  <Star sx={{ color: "#FFAF61" }} />
                ) : (
                  <StarBorder />
                )}
              </IconButton>
            </Tooltip>
          </Box>
          
          {idpRoleAccess(role) && board?.role !== "viewer" && (
            <Box>
              <IconButton onClick={(e) => handleMenuOpen(e, board)}>
                <MoreVertOutlinedIcon />
              </IconButton>
            </Box>
          )}
          </Box>
        </CardContent>
      </Card>

    </Box>
  );
};

export default BoardCard;
