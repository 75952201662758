import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { callBackURL, idpURL } from "../../../configs/envConfig";
import { getClients } from "../../../services/core";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoginIcon from "@mui/icons-material/Login";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      QnAI &nbsp;
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const defaultTheme = createTheme();
export default function SignInSide() {
  const [data, setData] = useState();

  useEffect(() => {
    getClientAsync();
  }, []);
  useEffect(() => {}, [data]);

  const getClientAsync = async () => {
    const data = {
      data: {},
    };
    const res = await getClients(data);
    if (res?.status === 200) {
      const data = res?.data?.data?.find((item) => item.clientId === "qnai");
      if (data) {
        setData(data);

      } else {
        alert("App is not there");
      }
    }
  };

  const getAuthorizationCode = () => {
    return `${idpURL}/oauth2/authorize?response_type=code&client_id=${data?.clientId}&scope=openid&redirect_uri=${callBackURL}`;
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/home-Page.jpg)`,
            backgroundRepeat: "no-repeat",
            backgroundColor: "white",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          square
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              sx={{
                color: "#180052",
                fontWeight: "bold",
                fontSize: "60px",
                marginRight: "25px",
              }}
            >
              QnAI
            </Typography>
            <Divider
              sx={{
                height: "25px",
              }}
            />
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <Typography
                component="h6"
                variant="h6"
                sx={{
                  color: "grey",
                  fontSize: "20px",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                We understand the importance of streamlining the login process
                for both businesses and users. Introducing our revolutionary
                One-Click Login button, designed to provide a seamless and
                secure authentication experience.
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Button
                  componet={Link}
                  href={getAuthorizationCode()}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 4,
                    mb: 2,
                    padding: "12px",
                    width: "10vw",
                    backgroundColor: "#180052",
                    color: "white",
                    borderRadius: "10vh",
                    whiteSpace: "nowrap",
                  }}
                >
                  Login &nbsp;
                  <LoginIcon />
                </Button>
              </Grid>
              <Copyright sx={{ mt: 1, mr: 3 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
