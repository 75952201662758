import React, { useCallback, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  UpdateBoard,
  getAllBoards,
  getDashboardsForUser,
  updateFavouriteBoard,
} from "../../services";
import {
  setAllBoards,
  setAllUsers,
  setUseFromRedux,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllBoards,
  selectAllCatlog,
  selectAllUsers,
} from "../../redux/selectors";
import Loader from "../../Component/Loader";
import UpdateName from "../../Component/UpdateName";
import CommonDialog from "../../utils/Dialog";
import ManagementButton from "../../utils/ManagementButton";
import {
  getUserRoleForDashboard,
  getUsersForDashboard,
  revokeDashboardAccess,
  shareBoardAccess,
} from "../../services/PermissionModule";
import { idpRoleAccess } from "../../utils/utils";
import CloseIcon from "@mui/icons-material/Close";
import UserCard from "../../Component/UserCard";
import { enqueueSnackbar } from "notistack";
import { getUsers } from "../../services/core";
import { StarBorder } from "@mui/icons-material";
import { Star } from "@mui/icons-material";
import BoardCard from "../../Component/BoardCard";
const AnswerBoard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCatalog, setSelectedCatalog] = useState();
  const [openUpdateName, setOpenUpdateName] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AllBoardsData = useSelector(selectAllBoards);
  const AllCatlogList = useSelector(selectAllCatlog);
  const [anchorEl, setAnchorEl] = useState(null);
  const [answerBoardId, setanswerBoardId] = useState("");
  const [shareDialog, setShareDialog] = useState(false);
  const [dashboardUserRole, setDashboardUserRole] = useState();
  const allUsersData = useSelector(selectAllUsers);
  const [selectedUser, setSelectedUser] = useState();
  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const [usersForDashboard, setUsersForDashboard] = useState();
  const [selectedRole, setSelectedRole] = useState(null);
  const [favouriteBoard, setFavouriteBoard] = useState([]);
  const [forceRerender, setForceRerender] = useState(false);
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);
  console.log(dashboardUserRole, "dashboardUserRole");
  const role = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).roles[0]
    : null;
  let boardMessages;
  const allUsers =
    role === "Super Admin"
      ? allUsersData
      : allUsersData.filter((user) => !user.roles.includes("Super Admin"));

  const answerBoardImage = [
    "/assets/images/AnswerBoard/answer1.png",
    "/assets/images/AnswerBoard/answerBoard.png",
    "/assets/images/AnswerBoard/answer3.png",
    "/assets/images/AnswerBoard/answer2.png",
  ];

  const AllBoardsDataWithImages = AllBoardsData.map((board, index) => {
    const imageIndex = index % answerBoardImage.length;
    return {
      ...board,
      imageUrl: `${process.env.PUBLIC_URL}${answerBoardImage[imageIndex]}`,
    };
  });
  const forceUpdate = useCallback(() => {
    setForceRerender((prev) => !prev);
  }, []);

  useEffect(() => {
    favouriteBoardFunction();
  }, [forceRerender]);
  const favouriteBoardFunction = async () => {
    const res = await getDashboardsForUser();
    setFavouriteBoard(res?.data?.data?.favouriteBoards);
    console.log(res, "dahdasuidoaudouasdou9");
  };
  const toggleShowFavorites = () => {
    setShowFavoritesOnly(!showFavoritesOnly);
  };
  useEffect(() => {
    dispatch(setUseFromRedux(false));
    getAllBoardsAsync();
    getUsersAsync();
  }, [forceRerender]);

  const getUsersAsync = async () => {
    if (allUsersData?.length === 0) {
      const res = await getUsers();
      dispatch(setAllUsers(res?.data?.data));
    }
  };

  const getAllBoardsAsync = async () => {
    setIsLoading(true);
    const res = await getAllBoards();
    dispatch(setAllBoards(res?.data?.data?.result));
    setIsLoading(false);
  };

  // const filteredBoards = selectedCatalog
  //   ? AllBoardsDataWithImages.filter(
  //       (board) => board.catalogId === selectedCatalog._id
  //     )
  //   : AllBoardsDataWithImages;
  const filteredBoards = AllBoardsDataWithImages?.filter((board) => {
    const { catalogId, _id, hide } = board;
    const matchesCatalog =
      !selectedCatalog || catalogId === selectedCatalog?._id;
    const isFavorite = !showFavoritesOnly || favouriteBoard?.includes(_id);
    return matchesCatalog && isFavorite && !hide;
  });

  const boardsExistForSelectedCatalog = selectedCatalog
    ? AllBoardsDataWithImages?.some(
        (board) => board.catalogId === selectedCatalog?._id
      )
    : true;

  if (AllBoardsDataWithImages?.length === 0) {
    if (selectedCatalog && favouriteBoard?.length === 0 && showFavoritesOnly) {
      boardMessages = "No dashboards with this catalog";
    } else if (selectedCatalog && !boardsExistForSelectedCatalog) {
      boardMessages = "No dashboards Available for this catalog";
    } else if (favouriteBoard?.length === 0 && showFavoritesOnly) {
      boardMessages = "No favorite dashboards";
    } else {
      boardMessages = "No dashboards Available";

    }
  } else if (
    selectedCatalog &&
    favouriteBoard?.length === 0 &&
    showFavoritesOnly
  ) {
    boardMessages = "No favorite dashboards with this catalog";
  } else if (selectedCatalog && !boardsExistForSelectedCatalog) {
    boardMessages = "No dashboards Available for this catalog";
  } else if (favouriteBoard?.length === 0 && showFavoritesOnly) {
    boardMessages = "No favorite dashboards";
  }

  const handleMenuOpen = async (event, id) => {
    setanswerBoardId(id);
    setAnchorEl(event.currentTarget);
    const data = {
      boardId: id?._id,
      email: JSON.parse(localStorage.getItem("user"))?.email,
    };
    if (JSON.parse(localStorage.getItem("user")).roles[0] !== "Super Admin") {
      const resRole = await getUserRoleForDashboard(data);
      setDashboardUserRole(resRole?.data?.data?.result?.role);
    }
  };

  const open = Boolean(anchorEl);

  const handleHideBoard = async (id) => {
    const data = {
      boardId: answerBoardId?._id,
      data: {
        hide: true,
      },
    };
    const apiResponse = await UpdateBoard(data);
    if (apiResponse.data.data.msg === "Board updated sucessfully") {
      console.log("here");
      const boardIndex = AllBoardsData.findIndex(
        (board) => board._id === answerBoardId?._id
      );
      console.log(boardIndex, "boardIndex");
      if (boardIndex !== -1) {
        const updatedBoardsData = [...AllBoardsData];
        updatedBoardsData[boardIndex] = {
          ...updatedBoardsData[boardIndex],
          hide: true,
        };
        console.log("updatedBoardsData", updatedBoardsData);
        dispatch(setAllBoards(updatedBoardsData));
      }
    }
    setAnchorEl(null);
    setOpenConfirmationDialog(false);
  };

  const handleCloseHideBoardDialog = () => {
    setAnchorEl(null);
    setOpenConfirmationDialog(false);
  };

  const hideBoardActions = [
    {
      label: "No",
      color: "warning",
      onClick: handleCloseHideBoardDialog,
    },
    {
      label: "Yes",
      color: "primary",
      onClick: () => handleHideBoard(answerBoardId?._id),
    },
  ];

  if (isLoading) {
    return <Loader />;
  }

  const handleShareClick = async (boardId) => {
    setShareDialog(true);
    setLoadingDashboard(true);
    const data = {
      boardId: boardId,
    };
    const res = await getUsersForDashboard(data);
    setUsersForDashboard(res?.data?.data);
    setLoadingDashboard(false);
  };

  const revokeDashboardAccessAsync = async (email, role) => {
    // setLoadingDashboard(true);

    const reqData = {
      boardId: answerBoardId?._id,
      data: {
        email: email,
        role: role,
      },
    };

    const res = await revokeDashboardAccess(reqData);
    if (res?.data?.success === true) {
      enqueueSnackbar(`Dashboard revoked successfully`, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(`Something went wrong Please Try  again later!`, {
        variant: "error",
      });
    }
    handleShareClick(answerBoardId?._id);
    // setLoadingDashboard(false);
  };

  const shareDashboardAccessAsync = async () => {
    if (!selectedUser || !selectedRole) {
      enqueueSnackbar(
        "Please select a user and role before sharing the dashboard.",
        {
          variant: "error",
        }
      );
      return;
    }
    const data = {
      boardId: answerBoardId?._id,
      data: {
        email: selectedUser?.email,
        role: selectedRole?.value,
        ownerEmail:
          selectedRole?.value === "creator"
            ? answerBoardId?.createdBy?.email
            : null,
      },
    };

    const res = await shareBoardAccess(data);
    if (res?.data?.success === true) {
      enqueueSnackbar(
        `Dashboard Shared Successfully to ${selectedUser?.name}`,
        {
          variant: "success",
        }
      );
      getAllBoardsAsync();
      setSelectedRole(null);
      setSelectedUser(null);

      setShareDialog(false);
      setAnchorEl(null);
    } else {
      enqueueSnackbar(`${res?.response?.data?.err?.message}`, {
        variant: "error",
      });
      setSelectedUser(null);

      setSelectedRole(null);
    }
  };
  const shareDialogContent = (
    <Box
      sx={{
        borderRadius: "9px",
        display: "flex",
        gap: "20px",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Share your dashboard with</Typography>
        <CloseIcon onClick={() => setShareDialog(false)} cursor="pointer" />
      </Box>
      <Box sx={{ display: "flex", gap: "30px", mt: 2 }}>
        <Autocomplete
          sx={{ width: "350px" }}
          limitTags={2}
          id="tags-standard"
          value={selectedUser}
          options={
            allUsers
              ? allUsers.map((user) => ({
                  id: user.id,
                  name: user.name,
                  email: user.email,
                  profilePic: user.profilePic,
                  role: user.roles[0],
                }))
              : []
          }
          getOptionLabel={(option) => option.name}
          onChange={(e, value) => {
            setSelectedUser(value);
            setSelectedRole({
              value: "viewer",
              index: "0",
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Add People"
            />
          )}
          renderOption={(props, option) => (
            <Box {...props} p={1}>
              <UserCard
                name={option.name}
                email={option?.email}
                profile={option?.profilePic}
              />
            </Box>
          )}
        />

        <ManagementButton
          setSelectedRole={setSelectedRole}
          selectedRole={selectedRole}
          idpRole={selectedUser?.role}
          dashboardUserRole={dashboardUserRole}
        />
      </Box>
      <Box>
        <Typography variant="subtitle2">Creator</Typography>
        <UserCard
          showCreatorButton={
            JSON.parse(localStorage.getItem("user")).roles[0] === "Super Admin"
              ? JSON.parse(localStorage.getItem("user")).email ===
                answerBoardId?.createdBy?.email
                ? false
                : true
              : false
          }
          idpRole={
            allUsersData?.find(
              (userData) => userData.email === answerBoardId?.createdBy?.email
            )?.roles[0]
          }
          showDeleteIcon={false}
          name={answerBoardId?.createdBy?.name}
          email={answerBoardId?.createdBy?.email}
          profile={answerBoardId?.createdBy?.profilePic}
          role={"creator"}
          board={answerBoardId}
          onClickDelete={() =>
            revokeDashboardAccessAsync(
              answerBoardId?.createdBy?.email,
              "creator"
            )
          }
        />
      </Box>
      <Box>
        {usersForDashboard?.result.length !== 0 ? (
          <Typography variant="subtitle2">Already Shared with</Typography>
        ) : (
          ""
        )}
      </Box>
      <Box
        sx={{
          maxHeight: "170px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "5px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        }}
      >
        {loadingDashboard ? (
          <Loader />
        ) : usersForDashboard?.result ? (
          usersForDashboard?.result.map((user, index) => (
            <UserCard
              idpRole={
                allUsersData?.find((userData) => userData.email === user.email)
                  .roles[0]
              }
              key={index}
              name={user?.name}
              email={user?.email}
              profile={user?.profilePic}
              role={user.role}
              board={answerBoardId}
              showCreatorButton={true}
              dashboardUserRole={dashboardUserRole}
              onClickDelete={() =>
                revokeDashboardAccessAsync(user.email, user.role)
              }
            />
          ))
        ) : (
          ""
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "24px",
          marginTop: "30px",
        }}
      >
        <Button
          sx={{
            color: "#343434",
            borderRadius: "20px",
            fontSize: "14px",
            padding: "6px 20px",
            backgroundColor: "#FFF",
            fontFamily: "Inter,sans-serif",
            textTransform: "capitalize",
            border: "1px solid #989898",
          }}
          onClick={() => setShareDialog(false)}
        >
          Cancel
        </Button>
        <Button
          sx={{
            color: "#FFF",
            borderRadius: "20px",
            fontSize: "14px",
            padding: "6px 20px",
            backgroundColor: "#3A5396",
            fontFamily: "Inter,sans-serif",
            textTransform: "capitalize",
            ":hover": {
              backgroundColor: "#3A5396",
              color: "#FFF",
            },
          }}
          onClick={shareDashboardAccessAsync}
        >
          Share
        </Button>
      </Box>
    </Box>
  );

  const handleCloseDialog = () => {
    setSelectedRole(null);
    setShareDialog(false);
  };

  const updateFavouriteBoardAsync = async (event, board) => {
    const res = await updateFavouriteBoard(board._id);
    if (res?.data?.success === true) {
      enqueueSnackbar(res?.data?.data?.result, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(res?.data?.data?.result, {
        variant: "error",
      });
    }
    forceUpdate();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              width: { xs: "100%", sm: "70%", md: "80%" },
            }}
          >
            <Typography
              sx={{ color: "#424242", fontSize: "20px", fontWeight: "500" }}
            >
              All Answer Board
            </Typography>
            <Tooltip title={"All the existing dashboards are displayed below"}>
              <ErrorOutlineOutlinedIcon sx={{ color: "#B6B6B6" }} />
            </Tooltip>
          </Box>
          <Box
            sx={{
              width: { xs: "60%", sm: "30%", md: "50%" },
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ width: "20%" }}>
              <Tooltip
                title={
                  showFavoritesOnly ? "Show all boards" : "Show favorite boards"
                }
                // arrow
              >
                <IconButton onClick={toggleShowFavorites}>
                  {showFavoritesOnly ? (
                    <Star sx={{ color: "#FFAF61" }} />
                  ) : (
                    <StarBorder />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ width: "30%", pb: 2 }}>
              <Autocomplete
                options={AllCatlogList ? [...AllCatlogList].reverse() : []}
                getOptionSelected={(option, value) => option._id === value._id}
                getOptionLabel={(option) => option?.name}
                value={selectedCatalog}
                onChange={(event, value) => setSelectedCatalog(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Catalog"
                    variant="standard"
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "100%", mt: 2 }}>
          <Box>
            <Typography
              sx={{
                color: "#424242",
                fontSize: "20px",
                fontWeight: " 400",
                my: 2,
              }}
            >
              All Dashboard
            </Typography>
            <Divider sx={{ width: "100%" }} />
            <Grid
              container
              spacing={2}
              sx={{
                mt: 2,
                mb: 5,
                display: "flex",
                alignItems: "start",
                justifyContent: "space-evenly",
              }}
            >
              {boardMessages
                ? boardMessages
                : filteredBoards?.filter((board) => !board.hide)
                    .map((board, i) => (
                      <Grid item xs={12} sm={6} md={3.5} key={i}>
                        <BoardCard
                          board={board}
                          handleMenuOpen={handleMenuOpen}
                          favouriteBoard={favouriteBoard}
                          role={role}
                          navigate={navigate}
                          updateFavouriteBoardAsync={updateFavouriteBoardAsync}
                        />
                      </Grid>
                    ))}
              {/* {filteredBoards
                .filter((board) => !board.hide)
                .map((board, i) => (
                  <Grid item xs={12} sm={6} md={3.5} key={i}>
                    <Card>
                      <CardMedia
                        component="img"
                        height="200"
                        image={board.imageUrl}
                        onClick={() => navigate(`/answer-board/${board?._id}`)}
                        sx={{ cursor: "pointer" }}
                      />
                      <CardContent
                        style={{
                          paddingBottom: "0px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          sx={{
                            color: " #434343",
                            fontSize: " 20px",
                            fontWeight: "600",
                          }}
                        >
                          {board?.name}
                        </Typography>
                        {idpRoleAccess(role) && board?.role !== "viewer" && (
                          <Box>
                            <IconButton
                              onClick={(event) =>
                                updateFavouriteBoardAsync(event, board)
                              }
                            >
                              {favouriteBoard.includes(board._id) ? (
                                <Star sx={{ color: "#FFAF61" }} />
                              ) : (
                                <StarBorder />
                              )}
                            </IconButton>
                            <IconButton
                              onClick={(e) => handleMenuOpen(e, board)}
                            >
                              <MoreVertOutlinedIcon />
                            </IconButton>
                          </Box>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                ))} */}
            </Grid>
          </Box>
        </Box>
      </Box>
      {idpRoleAccess(role) && (
        <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={() => setOpenUpdateName(true)}>Rename</MenuItem>
          <MenuItem onClick={() => setOpenConfirmationDialog(true)}>
            Trash
          </MenuItem>
          {(dashboardUserRole !== "viewer" && dashboardUserRole !== "editor") ||
          JSON.parse(localStorage.getItem("user"))?.roles[0] ===
            "Super Admin" ? (
            <MenuItem onClick={() => handleShareClick(answerBoardId?._id)}>
              Share
            </MenuItem>
          ) : (
            ""
          )}
        </Menu>
      )}
      <Dialog open={openUpdateName} onClose={() => setOpenUpdateName(false)}>
        <UpdateName
          setOpenUpdateName={setOpenUpdateName}
          setAnchorEl={setAnchorEl}
          answerBoardId={answerBoardId?._id}
        />
      </Dialog>
      <CommonDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        title="Confirmation dialog"
        content={"Do you confirm your intention to hide the Dashboard?"}
        actions={hideBoardActions}
      />
      <CommonDialog
        open={shareDialog}
        onClose={handleCloseDialog}
        content={shareDialogContent}
        actions={[]}
      />
    </>
  );
};

export default AnswerBoard;
