import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import SearchIcon from "@mui/icons-material/Search";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import GridOnIcon from "@mui/icons-material/GridOn";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AgGrid from "../../../utils/AgGrid";
import { useNavigate } from "react-router-dom";
import { getDataOfTable, getTables } from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCatalogData,
  selectConnectionData,
  selectDataBase,
} from "../../../redux/selectors";
import { setCatalogData } from "../../../redux/actions";
import Loader from "../../../utils/Loader";
const SelectTable = () => {
  const [allTables, setAllTables] = useState([]);
  const [originalTables, setOriginalTables] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState();
  const [columnDef, setColumnDef] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const catalogData = useSelector(selectCatalogData);
  const dataBase = useSelector(selectDataBase);
  const [searchInput, setSearchInput] = useState("");

  const handleSearchInputChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);
    if (input.trim() === "") {
      setAllTables(originalTables);
    } else {
      const filtered = originalTables.filter((table) =>
        table.name.toLowerCase().includes(input.toLowerCase())
      );
      setAllTables(filtered);
    }
  };

  useEffect(() => {
    if (catalogData.connectionId === "") {
      navigate("/catalog/connection/create-connection");
    } else if (catalogData.metaData.table) {
      getDataOfTableAsync(catalogData.metaData.table.name);
    }
  }, [catalogData.connectionId, catalogData.metaData.table]);

  const handleCheckboxChange = (table) => {
    const isSelected =
      catalogData.metaData.table &&
      catalogData.metaData.table.name === table.name;
    // if (catalogData.metaData.table === table) {
    if (isSelected) {
      dispatch(
        setCatalogData({
          ...catalogData,
          metaData: {
            ...catalogData.metaData,
            table: null,
          },
        })
      );
    } else {
      dispatch(
        setCatalogData({
          ...catalogData,
          metaData: {
            ...catalogData.metaData,
            table: table,
          },
        })
      );
    }
    getDataOfTableAsync(table.name);
  };

  useEffect(() => {
    getTablesAsync();
  }, []);

  const getTablesAsync = async () => {
    setIsLoading(true);
    const res = await getTables(catalogData?.connectionId);
    setAllTables(res?.data?.data?.result);
    setOriginalTables(res?.data?.data?.result);
    setIsLoading(false);
  };

  const getDataOfTableAsync = async (tableName) => {
    setIsLoadingTable(true);
    const data = {
      data: {
        table: tableName,
        connectionId: catalogData?.connectionId,
        limit: "10",
      },
    };
    const res = await getDataOfTable(data);
    const first10Rows = res?.data?.data?.result;
    setRowData(first10Rows);
    // setRowData(res?.data?.data?.result);
    if (first10Rows.length > 0) {
      const keys = Object.keys(first10Rows[0]);
      const column = keys?.map((item, index) => {
        return { field: item };
      });
      setColumnDef(column);
    } else {
      setColumnDef([]);
    }
    setIsLoadingTable(false);
  };

  const handleNext = () => {
    navigate("/catalog/prepare-metadata");
  };
  
  const handlePre = () => {
    navigate("/catalog/connection/create-connection");
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Box
        sx={{
          // position: "fixed",
          top: "90px",
          width: "100%",
          height: { xs: "90vh", sm: "80vh" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            px: 3,
            gap: "5px",
          }}
        >
          <GridOnIcon fontSize="large" sx={{ color: "#5D5FEF" }} />
          <Typography
            sx={{
              color: "#2C2C2C",
              fontWeight: "300",
              fontSize: "35px",
              color: "#2C2C2C",
            }}
          >
            Select Table
          </Typography>
        </Box>
        <Box sx={{ py: 2, display: "flex", height: "85%" }}>
          <Box sx={{ px: 4, width: "25%" }}>
            <FormControl sx={{ mt: 1 }} variant="standard" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                Table Name
              </InputLabel>
              <Input
                id="outlined-adornment-password"
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e)}
                endAdornment={
                  <InputAdornment position="end" sx={{ pr: 2 }}>
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <SearchIcon sx={{ color: "#5D5FEF" }} />
                    </IconButton>
                  </InputAdornment>
                }
                label="Connection Name"
              />
            </FormControl>
            {allTables.length === 0 && (
              <Typography variant="body1" sx={{ mt: 2, color: "grey" }}>
                No table found
              </Typography>
            )}
            <Box
              sx={{
                mt: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Accordion
                sx={{ boxShadow: "none", width: "100%" }}
                defaultExpanded
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  sx={{
                    width: "130px",
                    p: 0,
                    pl: 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/database.png"}
                    alt="Database"
                  />
                  <Typography
                    sx={{
                      ml: 1,
                      color: "#000",
                      fontWeight: 400,
                      fontSize: "15px",
                    }}
                  >
                    {dataBase}
                  </Typography>
                </AccordionSummary>
                <Box
                  sx={{
                    maxHeight: "50vh",
                    overflow: "auto",
                  }}
                >
                  {allTables?.map((table) => (
                    <AccordionDetails key={table.name} sx={{ p: 0 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#F0F0F0",
                          px: 3,
                          py: 0.5,
                        }}
                      >
                        <Checkbox
                          // checked={catalogData.metaData.table === table}
                          checked={
                            catalogData.metaData.table &&
                            catalogData.metaData.table.name === table.name
                          }
                          onChange={() => handleCheckboxChange(table)}
                        />
                        <GridOnIcon
                          fontSize="small"
                          sx={{ color: "#5D5FEF" }}
                        />
                        <InputLabel
                          sx={{ ml: 1, color: "#000", cursor: "pointer" }}
                          onClick={() => handleCheckboxChange(table)}
                          // onClick={() => {
                          //   getDataOfTableAsync(table.name);
                          // }}
                        >
                          {table.name}
                        </InputLabel>
                      </Box>
                    </AccordionDetails>
                  ))}
                </Box>
              </Accordion>
            </Box>
          </Box>
          <Divider sx={{ backgroundColor: "#E8E8E8", width: "1.5px" }} />
          <Box sx={{ px: 4, width: "75%" }}>
            {isLoadingTable ? (
              <Loader />
            ) : rowData && catalogData.metaData.table !== null ? (
              <AgGrid rowData={rowData} columnDef={columnDef} />
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          padding: "20px 22px",
          display: "flex",
          justifyContent: "end",
          gap: "10px",
        }}
      >
        <Button
          onClick={handlePre}
          variant="outlined"
          size="small"
          sx={{ color: "#5D5FEF", borderColor: "#5D5FEF", py: 0.6 }}
        >
          Previous&nbsp;
          <CancelRoundedIcon />
        </Button>
        <Button
          disabled={catalogData.metaData.table ? false : true}
          onClick={handleNext}
          variant="contained"
          size="small"
          sx={{ backgroundColor: "#5D5FEF", py: 0.6 }}
        >
          Save Next&nbsp;
          <CheckCircleRoundedIcon />
        </Button>
      </Box>
    </>
  );
};
export default SelectTable;
