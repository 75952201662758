import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllBoards,
  selectAskQuestion,
  selectChartProperties,
  selectTitleProperties,
  selectWidget,
} from "../../redux/selectors";
import { createBoard, createObject, getAllBoards } from "../../services";
import { setAllBoards } from "../../redux/actions";
import { enqueueSnackbar } from "notistack";
import { apiEndPointsConfig } from "../../services/apiEndPointsConfig";
import { axiosInstance } from "../../services/axiosInstance";

const SaveToAnswerBoard = ({ setOpenSave, pivotState }) => {
  const AllBoardsData = useSelector(selectAllBoards);
  const widgetData = useSelector(selectWidget);
  const [save, setSave] = useState(
    AllBoardsData.length > 0 ? "Existing" : "New"
  );
  const AskQuestionData = useSelector(selectAskQuestion);
  const TitleProperties = useSelector(selectTitleProperties);
  const chartProperties = useSelector(selectChartProperties);
  const handleCloseSave = () => setOpenSave(false);
  const [selectedBoard, setSelectedBoard] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [allAnswerBoards, setAllAnswerBoards] = useState([]);
  const [answerBoardName, setAnswerBoardName] = useState("");
  const [isDuplicateBoardName, setIsDuplicateBoardName] = useState(false);
  const [boardNameError, setBoardNameError] = useState("")
  // const [title, setTitle] = useState();
  const dispatch = useDispatch();
  console.log("isDuplicateBoardName", isDuplicateBoardName);
  useEffect(() => {
    getAllBoardsAsync();
  }, []);
  const saveObjectHandler = async (key) => {
    setIsLoading(true);
    if (key === "new") {
      const data = {
        data: {
          connectionId: AskQuestionData?.catalog?.connectionId,
          catalogId: AskQuestionData?.catalog?._id,
          name: answerBoardName,
          createdBy: "",
          createdAt: "",
          updatedAt: "",
        },
      };
      const res = await createBoard(data);
      const req = {
        data: {
          question:
            AskQuestionData.askFromQuery === true
              ? "Question is Asked using Query"
              : AskQuestionData?.question,
          catalogId: AskQuestionData?.catalog?._id,
          connectionId: AskQuestionData?.catalog?.connectionId,
          query: AskQuestionData?.sql,
          boardId: res?.data?.data?.result?._id,
          title: TitleProperties?.text,
          widgetProp: widgetData,
          titleProperties: TitleProperties,
          chartProperties: chartProperties,
          visualization:
            AskQuestionData?.chartType === "Pivot"
              ? {
                  type: AskQuestionData?.chartType,
                  pivot: pivotState,
                }
              : {
                  type: AskQuestionData?.chartType,
                },
        },
      };

      const exRes = await createObject(req);
      console.log(exRes);
      if (exRes?.data?.success) {
        enqueueSnackbar("Object Saved successfully...", {
          variant: "success",
        });
      }
      setIsLoading(false);
      handleCloseSave();

      return;
    }

    const req = {
      data: {
        question:
          AskQuestionData.askFromQuery === true
            ? "Question is Asked using Query"
            : AskQuestionData?.question,
        catalogId: AskQuestionData?.catalog?._id,
        connectionId: AskQuestionData?.catalog?.connectionId,
        query: AskQuestionData?.sql,
        boardId: selectedBoard,
        title: TitleProperties?.text,
        widgetProp: widgetData,
        titleProperties: TitleProperties,
        chartProperties: chartProperties,
        visualization:
          AskQuestionData?.chartType === "Pivot"
            ? {
                type: AskQuestionData?.chartType,
                pivot: pivotState,
              }
            : {
                type: AskQuestionData?.chartType,
              },
      },
    };

    const exRes = await createObject(req);
    if (exRes?.data?.success) {
      enqueueSnackbar("Object Saved successfully...", {
        variant: "success",
      });
    }
    if (key !== "new") {
      handleCloseSave();
    }

    setIsLoading(false);
  };

  const getAllBoardsAsync = async () => {
    const res = await getAllBoards();
    dispatch(setAllBoards(res?.data?.data?.result));

    const resBoard = await axiosInstance.get(apiEndPointsConfig.getAllBoards);
    setAllAnswerBoards(resBoard?.data?.data?.result);
    console.log("resBoard?.data?.data?.result", resBoard?.data?.data?.result);
  };

  const filteredBoards = AllBoardsData.filter((board) => {
    return board?.catalogId === AskQuestionData.catalog?._id;
  });

  useEffect(() => {
    if (filteredBoards.length > 0) {
      setSave("Existing");
    } else {
      setSave("New");
    }
  }, []);

  const handleInputAnswerBoardName = async (e) => {
    const isDuplicate = allAnswerBoards?.some(
      (board) => board.name.toLowerCase() === e.target.value.toLowerCase()
    );
    setIsDuplicateBoardName(isDuplicate);
  
    if (!e.target.value.trim()) {
      setBoardNameError("Board name cannot be empty");
    } else if (!/^\S(?:.*\S)?$/.test(e.target.value)) {
      setBoardNameError("Board name cannot have spaces at the beginning or end");
    } else {
      setBoardNameError(""); 
    }
  
    setAnswerBoardName(e.target.value);
  };
  
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "25vw",
        height: "55vh",
        // bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
          borderBottom: "1px solid #E7ECEF",
          backgroundColor: "white",
        }}
      >
        <Typography> Save to Answer board</Typography>
      </Box>
      {save === "Existing" ? (
        <Box
          sx={{
            bgcolor: "#FAFBFC",
            paddingTop: "10px",
            height: "49vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ padding: "20px" }}>
            <Typography sx={{ marginBottom: "10px" }}>
              Select Answer board
            </Typography>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Answer Board
              </InputLabel>
              <Select
                onChange={(e) => {
                  setSelectedBoard(e.target.value);
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: "200px",
                    },
                  },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Answer Board"
              >
                {filteredBoards.length > 0 ? (
                  filteredBoards.map((val) => (
                    <MenuItem key={val?._id} value={val?._id}>
                      {val?.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>There is no board</MenuItem>
                )}
              </Select>
            </FormControl>

            {/* {AskQuestionData?.chartType === "widget" ? (
              <FormControl fullWidth sx={{ mt: "20px" }}>
                <TextField
                  onChange={(e) => setTitle(e.target.value)}
                  label="Object Title"
                  size="small"
                />
              </FormControl>
            ) : (
              ""
            )} */}

            <Button
              onClick={() => setSave("New")}
              sx={{
                textTransform: "none",
                marginTop: "30px",
                color: "#507D9B",
              }}
            >
              <AddIcon sx={{ marginRight: "10px" }} /> Create new Answer board
            </Button>
          </Box>
          <Box
            sx={{
              bgcolor: "white",
              display: "flex",
              justifyContent: "flex-end",
              padding: "15px",
              borderTop: "1px solid #E7ECEF",
            }}
          >
            <>
              <Button variant="outlined" onClick={handleCloseSave}>
                Cancel
              </Button>
              <Button
                sx={{ marginLeft: "10px" }}
                variant="contained"
                onClick={() => saveObjectHandler()}
                disabled={!selectedBoard}
              >
                Save
              </Button>
            </>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            bgcolor: "#FAFBFC",
            paddingTop: "10px",
            height: "49vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ padding: "20px" }}>
            <Typography sx={{ marginBottom: "10px" }}>
              New Answer board
            </Typography>
            <FormControl fullWidth>
              <TextField
                onChange={handleInputAnswerBoardName}
                label="Answer board"
                size="small"
                inputProps={{ maxLength: 40 }}
                value={answerBoardName}
                error={isDuplicateBoardName || boardNameError}
                helperText={
                  isDuplicateBoardName
                    ? "Board name already exists. Please choose a different name."
                    : boardNameError
                }
              />
            </FormControl>

            <Button
              disabled={!filteredBoards.length > 0}
              onClick={() => setSave("Existing")}
              sx={{
                textTransform: "none",
                marginTop: "30px",
                color: "#507D9B",
              }}
            >
              <ArrowBackIcon sx={{ marginRight: "10px" }} /> Save to Existing
            </Button>
          </Box>
          <Box
            sx={{
              bgcolor: "white",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "15px",
              borderTop: "1px solid #E7ECEF",
            }}
          >
            <>
              <Button variant="outlined" onClick={handleCloseSave}>
                Cancel
              </Button>
              <Button
                onClick={() => saveObjectHandler("new")}
                sx={{ marginLeft: "10px" }}
                variant="contained"
                // disabled={isDuplicateBoardName || !answerBoardName}
                disabled={
                  isDuplicateBoardName ||
                  !answerBoardName ||
                  !/^\S(?:.*\S)?$/.test(answerBoardName) 
                }
              >
                Create & Save
              </Button>
            </>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SaveToAnswerBoard;
