export const apiEndPointsConfig = {
  //Core
  getToken: "/core/get-token",
  refreshToke: "/core/refresh-token",
  getClients: "/core/get-clients",
  getUsers: "/secured/get-users",

  // Connection
  testConnection: "/connection/test-connection",
  getAllConnection: "/connection/get-all-connections",
  saveConnection: "/connection/save-connection",
  getTables: "/connection/get-tables",
  getColumns: "/connection/get-columns",
  getDatabases: "/get-databases",
  generateSynonyms: "/generate-synonyms",
  deleteConnection: "/connection/delete-connection",
  getDataOfTable: "/connection/get-data-of-table",
  getDrivers: "/connection/get-drivers",

  //Boards
  getAllBoards: "/boards/get-boards",
  createBoard: "/boards/add-board",
  deleteBoard: "boards/delete-board",
  updateBoard: "/boards/update-board",

  getBoardsById: "/boards/get-boards-by-id",
  updateFavouriteBoard: "/boards/update-favourite",

  //Catalogue
  getAllCataloge: "/catalog/get-all-catalogs",
  saveCatalogue: "/catalog/save-catalog",
  getCatalogDetails: "/catalog/get-catalog-details",

  //Object
  getAllObject: "/object/get-business-object",
  createObject: "/objects/add-business-object",
  deleteObject: "/objects/delete-business-object",
  updateObject: "/objects/update-business-object",

  //Data
  runQuery: "/data/run-query",
  getQuery: "/data/generate-query",
  filterCategory: "/data/filter-category",
  columnValue: "data/column-value",

  //Embed
  saveEmbed: "/embed/save",
  getEmbed: "/embed/get-details",

  //permission module
  getDetails: "/user/get-details",
  getUserRoleForDashboard: "/user/get-user-role-for-dashboard",
  addUser: "/user/add-User",

  //Catalog permission
  getUsersForCatalog: "/user/get-users-for-catalog",
  revokeCatalogAccess: "/user/revoke-catalog-access",
  shareCatalogAccess: "/catalog/share-catalog",
  getCatalogForUser: "/user/get-catalog-for-user",

  // Dashboard permission
  getUsersForDashboard: "/user/get-users-for-dashboard",
  shareBoardAccess: "/boards/share-board",
  revokeDashboardAccess: "/user/revoke-dashboard-access",
  getDashboardsForUser: "/user/get-dashboards-for-user",
  updateDashboardRole: "/user/update-dashboard-role",
};
