// import React, { useEffect, useState } from "react";
// import {
//   Alert,
//   Box,
//   Button,
//   Checkbox,
//   Divider,
//   FormControl,
//   IconButton,
//   Input,
//   InputAdornment,
//   InputLabel,
//   Snackbar,
//   TextField,
//   Typography,
// } from "@mui/material";
// import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
// import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
// import SearchIcon from "@mui/icons-material/Search";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import GridOnIcon from "@mui/icons-material/GridOn";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import { useNavigate } from "react-router-dom";
// import { selectCatalogData } from "../../../redux/selectors";
// import { useDispatch, useSelector } from "react-redux";
// import { setCatalogData } from "../../../redux/actions";
// import { saveCatalogue } from "../../../services";
// import { useFormik } from "formik";
// import * as Yup from "yup";

// const SaveCatalog = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const catalogData = useSelector(selectCatalogData);
//   const [open, setOpen] = useState(false);
//   useEffect(() => {
//     if (catalogData.connectionId === "") {
//       navigate("/catalog/connection/create-connection");
//     }
//   }, []);
//   const handleNext = async () => {
//     const data = {
//       data: catalogData,
//     };
//     const res = await saveCatalogue(data);
//     if (res?.data?.success === true) {
//       dispatch(
//         setCatalogData({
//           connectionId: "",
//           metaData: {
//             table: null,
//             columns: [],
//           },
//           name: "",
//         })
//       );
//       setOpen(true);
//       navigate("/");
//     }
//   };

//   const handlePre = () => {
//     navigate("/catalog/prepare-metadata");
//   };

//   const formik = useFormik({
//     initialValues: {
//       name: catalogData.name || "",
//     },
//     validationSchema: Yup.object({
//       name: Yup.string().required("Catalog Name is required"),
//     }),
//     onSubmit: handleNext,
//   });

//   return (
//     <>
//       <form onSubmit={formik.handleSubmit}>
//         <Box
//           sx={{ position: "fixed", top: "90px", width: "100%", height: "80vh" }}
//         >
//           <Box
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               px: 3,
//             }}
//           >
//             <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//               <GridOnIcon fontSize="large" sx={{ color: "#0071E1" }} />
//               <Typography
//                 sx={{
//                   color: "#2C2C2C",
//                   fontWeight: "300",
//                   fontSize: "35px",
//                   color: "#2C2C2C",
//                 }}
//               >
//                 Catlog Name
//               </Typography>
//             </Box>
//           </Box>
//           <Box sx={{ py: 2, display: "flex", height: "85%" }}>
//             {/* <Box sx={{ px: 4, width: "25%" }}>
//               <FormControl sx={{ mt: 1 }} variant="standard" fullWidth>
//                 <InputLabel htmlFor="outlined-adornment-password">
//                   Catlog Name
//                 </InputLabel>
//                 <Input
//                   id="outlined-adornment-password"
//                   endAdornment={
//                     <InputAdornment position="end" sx={{ pr: 2 }}>
//                       <IconButton
//                         aria-label="toggle password visibility"
//                         edge="end"
//                       >
//                         <SearchIcon sx={{ color: "#4487BD" }} />
//                       </IconButton>
//                     </InputAdornment>
//                   }
//                   label="Connection Name"
//                 />
//               </FormControl>

//               <Box
//                 sx={{
//                   mt: 3,
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                 }}
//               >
//                 <Accordion sx={{ boxShadow: "none" }}>
//                   <AccordionSummary
//                     sx={{
//                       width: "130px",
//                       p: 0,
//                       pl: 2,
//                       display: "flex",
//                       alignItems: "center",
//                     }}
//                     expandIcon={<ArrowDropDownIcon />}
//                     aria-controls="panel1a-content"
//                     id="panel1a-header"
//                   >
//                     <img
//                       src={
//                         process.env.PUBLIC_URL +
//                         "/assets/images/database_blue.png"
//                       }
//                       alt="Database"
//                     />
//                     <Typography
//                       sx={{
//                         ml: 1,
//                         color: "#000",
//                         fontWeight: 400,
//                         fontSize: "15px",
//                       }}
//                     >
//                       credmudra_stg
//                     </Typography>
//                   </AccordionSummary>
//                   <AccordionDetails sx={{ p: 0 }}>
//                     <Box
//                       sx={{
//                         display: "flex",
//                         alignItems: "center",
//                         backgroundColor: "#F0F0F0",
//                         px: 3,
//                         py: 0.5,
//                       }}
//                     >
//                       <Checkbox
//                         id="checkbox-adornment"
//                         inputProps={{
//                           "aria-label": "primary checkbox",
//                         }}
//                       />
//                       <GridOnIcon fontSize="small" sx={{ color: "#0071E1" }} />
//                       <InputLabel
//                         htmlFor="checkbox-adornment"
//                         sx={{ ml: 1, color: "#000" }}
//                       >
//                         EMP
//                       </InputLabel>
//                     </Box>
//                   </AccordionDetails>
//                 </Accordion>
//               </Box>
//             </Box> */}

//             <Divider sx={{ backgroundColor: "#e8e8e8", width: "1.5px" }} />
//             <Box sx={{ pl: 4, width: "75%" }}>
//               <Typography
//                 sx={{
//                   fontSize: "23px",
//                   color: "#2C2C2C",
//                   fontWeight: "300",
//                 }}
//               >
//                 Enter Catalog Name
//               </Typography>
//               <Box sx={{ maxWidth: "25vmax", marginTop: "10px" }}>
//                 <TextField
//                   variant="standard"
//                   label="Enter Catalog Name"
//                   sx={{ fontSize: "20px" }}
//                   name="name"
//                   onChange={(e) => {
//                     dispatch(
//                       setCatalogData({
//                         ...catalogData,
//                         name: e.target.value,
//                       })
//                     );
//                     formik.handleChange(e);
//                   }}
//                   value={formik.values.name}
//                   fullWidth
//                   error={formik.touched.name && Boolean(formik.errors.name)}
//                   helperText={formik.touched.name && formik.errors.name}
//                 />
//               </Box>
//             </Box>
//           </Box>
//         </Box>
//         <Box
//           sx={{
//             backgroundColor: "#F4F4F4",
//             padding: "20px 22px",
//             display: "flex",
//             justifyContent: "end",
//             gap: "10px",
//           }}
//         >
//           <Button
//             onClick={handlePre}
//             variant="outlined"
//             size="small"
//             sx={{ color: "#5D5FEF", borderColor: "#5D5FEF", py: 0.6 }}
//           >
//             Previous&nbsp;
//             <CancelRoundedIcon />
//           </Button>
//           <Button
//             type="submit"
//             variant="contained"
//             size="small"
//             sx={{ backgroundColor: "#5D5FEF", py: 0.6 }}
//           >
//             Save &nbsp;
//             <CheckCircleRoundedIcon />
//           </Button>
//         </Box>
//       </form>

//       <Snackbar open={open} autoHideDuration={6000}>
//         <Alert severity="success" sx={{ width: "100%" }}>
//           Catlog Saved successfully...
//         </Alert>
//       </Snackbar>
//     </>
//   );
// };

// export default SaveCatalog;

import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import SearchIcon from "@mui/icons-material/Search";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import GridOnIcon from "@mui/icons-material/GridOn";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import { selectAllCatlog, selectCatalogData } from "../../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { setCatalogData } from "../../../redux/actions";
import { saveCatalogue } from "../../../services";
import { useFormik } from "formik";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";

const SaveCatalog = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const catalogData = useSelector(selectCatalogData);
  const allCatalogs = useSelector(selectAllCatlog);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (catalogData.connectionId === "") {
      navigate("/catalog/connection/create-connection");
    }
  }, []);

  const handleNext = async () => {
    const data = {
      data: catalogData,
    };
    const res = await saveCatalogue(data);
    if (res?.data?.success === true) {
      dispatch(
        setCatalogData({
          connectionId: "",
          metaData: {
            table: null,
            columns: [],
            selectedFilters: [],
          },
          name: "",
        })
      );
      setOpen(true);
    }
  };

  useEffect(() => {
    if (open) {
      enqueueSnackbar("Catalog Saved successfully...", {
        variant: "success",
      });
      navigate("/");
    }
  }, [open]);

  const handlePre = () => {
    navigate("/catalog/prepare-metadata");
  };
  const checkDuplicateName = (name) => {
    return allCatalogs.some(
      (catalog) => catalog.name.toLowerCase() === name.toLowerCase()
    );
  };

  const formik = useFormik({
    initialValues: {
      name: catalogData.name || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Catalog Name is required")
        .test(
          "spaces",
          "Catalog Name cannot have spaces at the beginning or end",
          (value) => {
            return value.trim() === value && value.length > 0;
          }
        )
        .test(
          "duplicate",
          "Catalog Name already exists. Please choose a different name.",
          (value) => !checkDuplicateName(value)
        ),
    }),
    onSubmit: handleNext,
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            // position: "fixed",
            top: "90px",
            width: "100%",
            height: { xs: "90vh", sm: "80vh" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <GridOnIcon fontSize="large" sx={{ color: "#0071E1" }} />
              <Typography
                sx={{
                  color: "#2C2C2C",
                  fontWeight: "300",
                  fontSize: "35px",
                  color: "#2C2C2C",
                }}
              >
                Catalog Name
              </Typography>
            </Box>
          </Box>
          <Box sx={{ py: 2, display: "flex", height: "85%" }}>
            <Divider sx={{ backgroundColor: "#e8e8e8", width: "1.5px" }} />
            <Box sx={{ pl: 4, width: "75%" }}>
              <Typography
                sx={{
                  fontSize: "23px",
                  color: "#2C2C2C",
                  fontWeight: "300",
                }}
              >
                Enter Catalog Name
              </Typography>
              <Box sx={{ maxWidth: "25vmax", marginTop: "10px" }}>
                <TextField
                  variant="standard"
                  label="Enter Catalog Name"
                  sx={{ fontSize: "20px" }}
                  name="name"
                  onChange={(e) => {
                    dispatch(
                      setCatalogData({
                        ...catalogData,
                        name: e.target.value,
                      })
                    );
                    formik.handleChange(e);
                  }}
                  value={formik.values.name}
                  fullWidth
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  inputProps={{ maxLength: 40 }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#F4F4F4",
            padding: "20px 22px",
            display: "flex",
            justifyContent: "end",
            gap: "10px",
          }}
        >
          <Button
            onClick={handlePre}
            variant="outlined"
            size="small"
            sx={{ color: "#5D5FEF", borderColor: "#5D5FEF", py: 0.6 }}
          >
            Previous&nbsp;
            <CancelRoundedIcon />
          </Button>
          <Button
            disabled={catalogData?.name === ""}
            type="submit"
            variant="contained"
            size="small"
            sx={{ backgroundColor: "#5D5FEF", py: 0.6 }}
          >
            Save &nbsp;
            <CheckCircleRoundedIcon />
          </Button>
        </Box>
      </form>
    </>
  );
};

export default SaveCatalog;
