import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

const CommonDialog = (props) => {
  const { open, onClose, title, content, actions, createUrl } = props;

  return (
    <Dialog open={open}>
      <DialogTitle
        sx={{
          width:"26vw ",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "50px",
        }}
      >
        <Typography sx={{ fontSize: "22px", fontWeight: "500" }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {title !== "Embedded Dashboard" &&
          actions.map((action, index) => (
            <Button key={index} onClick={action.onClick} color={action.color}>
              {action.label}
            </Button>
          ))}
      </DialogActions>
    </Dialog>
  );
};

export default CommonDialog;
