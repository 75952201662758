import { Box, Dialog, TextField } from "@mui/material";
import React, { useState } from "react";
import * as faIcons from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setUpdateObjectData, setWidget } from "../../redux/actions";
import { selectUpdateObjectData, selectWidget } from "../../redux/selectors";
const getAllIcons = () => {
  const icons = [];
  for (const iconName in faIcons) {
    const icon = faIcons[iconName];
    if (typeof icon === "function") {
      icons.push({
        label: iconName,
        value: iconName,
        icon: React.createElement(icon),
      });
    }
  }
  return icons;
};
const IconMenu = ({ update }) => {
  const options = getAllIcons();
  const [searchInput, setSearchInput] = useState("");
  const UpdateObjectData = useSelector(selectUpdateObjectData);

  const [filteredOptions, setFilteredOptions] = useState(options);
  const dispatch = useDispatch();
  const widgetData = useSelector(selectWidget);
  const handleSearchInputChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);
    const filtered = options.filter(
      (option) =>
        option.label.toLowerCase().includes(input.toLowerCase()) ||
        option.value.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleIconsValue = (option) => {
    const convertedString = option
      .replace(/([a-z0-9])([A-Z])/g, "$1-$2")
      .toLowerCase();
    const finalString = `fas ${convertedString}`;
    dispatch(
      update
        ? setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  widgetProp: {
                    ...UpdateObjectData?.ObjectData.data?.result?.widgetProp,
                    icon: finalString,
                  },
                },
              },
            },
          })
        : setWidget({ ...widgetData, icon: finalString })
    );
  };

  return (
    <>
      <Box>
        <TextField
          fullWidth
          size="small"
          variant="standard"
          placeholder="Search"
          value={searchInput}
          onChange={handleSearchInputChange}
          sx={{ m: 2 }}
        />
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(50px, 1fr))",
            gap: 2,
          }}
        >
          {filteredOptions.map((option) => (
            <Box
              key={option.value}
              sx={{
                textAlign: "center",
                svg: {
                  fontSize: "30px",
                },
              }}
            >
              <div onClick={() => handleIconsValue(option.value)}>
                {option.icon}
              </div>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};
export default IconMenu;
