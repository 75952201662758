import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputBase,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AgGrid from "../../utils/AgGrid";
import AddIcon from "@mui/icons-material/Add";
import ReplyAllSharpIcon from "@mui/icons-material/ReplyAllSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import NotInterestedSharpIcon from "@mui/icons-material/NotInterestedSharp";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import CommonDialog from "../../utils/Dialog";
import ManagementButton from "../../utils/ManagementButton";
import { setAllBoards, setAllUsers } from "../../redux/actions";
import { getAllBoards } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllBoards,
  selectAllCatlog,
  selectAllUsers,
} from "../../redux/selectors";
import {
  getUsersForDashboard,
  revokeDashboardAccess,
  shareBoardAccess,
} from "../../services/PermissionModule";
import UserCard from "../../Component/UserCard";
import { enqueueSnackbar } from "notistack";
import Loader from "../../utils/Loader";
import { getUsers } from "../../services/core";

const DashboardManagement = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [usersForDashboard, setUsersForDashboard] = useState();
  const AllCatlogList = useSelector(selectAllCatlog);
  const AllBoardsData = useSelector(selectAllBoards);
  const [searchValue, setSearchValue] = useState("");
  const allUsersData = useSelector(selectAllUsers);
  const [selectedUser, setSelectedUser] = useState();
  const [actionDashboard, setActionDashboard] = useState();
  const [selectedRole, setSelectedRole] = useState(null);
  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const currentUserRole = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).roles[0]
    : null;
  const allUsers =
    currentUserRole === "Super Admin"
      ? allUsersData
      : allUsersData.filter((user) => !user.roles.includes("Super Admin"));
  const dispatch = useDispatch();

  useEffect(() => {
    getAllBoardsAsync();
    getUsersAsync();
  }, []);

  const getUsersAsync = async () => {
    setIsLoading(true);
    if (allUsersData?.length === 0) {
      const res = await getUsers();
      dispatch(setAllUsers(res?.data?.data));
    }
    setIsLoading(false);
  };

  const getAllBoardsAsync = async () => {
    setIsLoading(true);

    const res = await getAllBoards();
    dispatch(setAllBoards(res?.data?.data?.result));
    setIsLoading(false);
  };

  const handleShareIconClick = async (boardId) => {
    setLoadingDashboard(true);
    setActionDashboard(boardId);

    setOpenShareDialog(true);
    const data = {
      boardId: boardId._id,
    };
    const res = await getUsersForDashboard(data);
    setUsersForDashboard(res?.data?.data);
    setLoadingDashboard(false);
  };

  const handleCloseDialog = () => {
    setSelectedRole(null);
    setSelectedUser(null);
    setOpenShareDialog(false);
  };

  const shareDashboardAccessAsync = async () => {
    if (!selectedUser || !selectedRole) {
      enqueueSnackbar(
        "Please select a user and role before sharing the dashboard.",
        {
          variant: "error",
        }
      );
      return;
    }

    const data = {
      boardId: actionDashboard._id,
      data: {
        email: selectedUser?.email,
        role: selectedRole?.value,
        ownerEmail:
          selectedRole?.value === "creator"
            ? actionDashboard?.createdBy?.email
            : null,
      },
    };

    const res = await shareBoardAccess(data);
    if (res?.data?.success === true) {
      enqueueSnackbar(
        `Dashboard Shared Successfully to ${selectedUser?.name}`,
        {
          variant: "success",
        }
      );
      getAllBoardsAsync();

      setSelectedRole(null);
      setSelectedUser(null);
      setOpenShareDialog(false);
    } else {
      setSelectedRole(null);
      setSelectedUser(null);

      enqueueSnackbar(`${res?.response.data?.err?.message}`, {
        variant: "error",
      });
    }
  };

  const revokeDashboardAccessAsync = async (email, role) => {
    setLoadingDashboard(true);

    const reqData = {
      boardId: actionDashboard._id,
      data: {
        email: email,
        role: role,
      },
    };

    const res = await revokeDashboardAccess(reqData);
    if (res?.data?.success === true) {
      enqueueSnackbar(`Dashboard revoked successfully`, {
        variant: "success",
      });
      getAllBoardsAsync();
    } else {
      enqueueSnackbar(`Something went wrong Please Try  again later!`, {
        variant: "error",
      });
    }
    setLoadingDashboard(false);
    handleShareIconClick(actionDashboard);
  };
  const dialogContent = (
    <Box
      sx={{
        borderRadius: "9px",
        display: "flex",
        gap: "20px",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Share your dashboard with</Typography>
        <CloseIcon onClick={handleCloseDialog} cursor="pointer" />
      </Box>
      <Box sx={{ display: "flex", gap: "30px", mt: 2 }}>
        <Autocomplete
          sx={{ width: "350px" }}
          limitTags={2}
          id="tags-standard"
          options={
            allUsers
              ? allUsers.map((user) => ({
                  id: user.id,
                  name: user.name,
                  email: user.email,
                  profilePic: user.profilePic,
                  role: user.roles[0],
                }))
              : []
          }
          value={selectedUser}
          getOptionLabel={(option) => option.name}
          onChange={(e, value) => {
            setSelectedUser(value);
            setSelectedRole({
              value: "viewer",
              index: "0",
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Add People"
            />
          )}
          renderOption={(props, option) => (
            <Box {...props} p={1}>
              <UserCard
                name={option.name}
                email={option?.email}
                profile={option?.profilePic}
              />
            </Box>
          )}
        />

        <ManagementButton
          setSelectedRole={setSelectedRole}
          selectedRole={selectedRole}
          idpRole={selectedUser?.role}
        />
      </Box>
      <Box>
        <Typography variant="subtitle2">Creator</Typography>
        <UserCard
          idpRole={
            allUsersData?.find(
              (userData) =>
                userData?.email === actionDashboard?.createdBy?.email
            )?.roles[0]
          }
          name={actionDashboard?.createdBy?.name}
          email={actionDashboard?.createdBy?.email}
          profile={actionDashboard?.createdBy?.profilePic}
          showCreatorButton={
            JSON.parse(localStorage.getItem("user")).email ===
            actionDashboard?.createdBy?.email
              ? false
              : true
          }
          role={"creator"}
          board={actionDashboard}
          onClickDelete={() =>
            revokeDashboardAccessAsync(
              actionDashboard?.createdBy?.email,
              "creator"
            )
          }
        />
      </Box>
      <Box>
        {usersForDashboard?.result.length !== 0 ? (
          <Typography variant="subtitle2">Shared with</Typography>
        ) : (
          ""
        )}
      </Box>
      <Box
        sx={{
          maxHeight: "170px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "5px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        }}
      >
        {loadingDashboard ? (
          <Loader />
        ) : usersForDashboard?.result ? (
          usersForDashboard?.result.map((user, index) => (
            <UserCard
              idpRole={
                allUsersData.find((userData) => userData.email === user.email)
                  .roles[0]
              }
              key={index}
              name={user?.name}
              email={user?.email}
              profile={user?.profilePic}
              role={user.role}
              board={actionDashboard}
              showCreatorButton={true}
              onClickDelete={() =>
                revokeDashboardAccessAsync(user.email, user.role)
              }
            />
          ))
        ) : (
          ""
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "24px",
          marginTop: "30px",
        }}
      >
        <Button
          sx={{
            color: "#343434",
            borderRadius: "20px",
            fontSize: "14px",
            padding: "6px 20px",
            backgroundColor: "#FFF",
            fontFamily: "Inter,sans-serif",
            textTransform: "capitalize",
            border: "1px solid #989898",
          }}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          sx={{
            color: "#FFF",
            borderRadius: "20px",
            fontSize: "14px",
            padding: "6px 20px",
            backgroundColor: "#3A5396",
            fontFamily: "Inter,sans-serif",
            textTransform: "capitalize",
            ":hover": {
              backgroundColor: "#3A5396",
              color: "#FFF",
            },
          }}
          onClick={shareDashboardAccessAsync}
        >
          Share
        </Button>
      </Box>
    </Box>
  );

  const filteredData = AllBoardsData?.filter((board) =>
    board.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const columnDefs = [
    { headerName: "Dashboard", field: "name" },
    { headerName: "Catalog", field: "catalog" },
    { headerName: "Creator", field: "createdBy.name" },
    {
      headerName: "Action",
      field: "action",
      cellRenderer: (params) => (
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            mt: "7px",
            cursor: "pointer",
            alignItems: "center",
          }}
        >
          <ReplyAllSharpIcon
            sx={{ transform: "scaleX(-1)" }}
            onClick={() => handleShareIconClick(params.data)}
          />
          {/* <EditSharpIcon />

          <NotInterestedSharpIcon /> */}
        </Box>
      ),
    },
  ];

  const rowData = filteredData?.map((board) => {
    const matchedConnection = AllCatlogList?.find(
      (catalog) => catalog._id === board.catalogId
    );

    return {
      _id: board._id,
      createdBy: board.createdBy,
      name: board.name,
      catalog: matchedConnection && matchedConnection?.name,
      action: "",
    };
  });

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#202223",
              fontSize: "24px",
              fontWeight: "600",
              fontFamily: "Inter,sans-serif",
            }}
          >
            Dashboard Management
          </Typography>
          <Tooltip
            title={"All the existing dashboard details are displayed below"}
          >
            <ErrorOutlineOutlinedIcon sx={{ color: "#B6B6B6" }} />
          </Tooltip>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 2,
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: "15px",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "100%" },
            height: "50px",
            borderRadius: "50px",
            border: "1px solid #C3D1FF",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#FFF",
          }}
        >
          <IconButton sx={{ p: "10px", color: "#6D80C3" }} aria-label="menu">
            <SearchIcon fontSize="large" />
          </IconButton>
          <InputBase
            id="searchInput"
            autoComplete="off"
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Dashboard"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Button
            sx={{
              color: "#FFF",
              borderRadius: "20px",
              fontSize: "14px",
              padding: "6px 20px",
              margin: "10px",
              backgroundColor: "#3A5396",
              fontFamily: "Inter,sans-serif",
              textTransform: "capitalize",
              ":hover": {
                backgroundColor: "#3A5396",
                color: "#FFF",
              },
            }}
          >
            Search
          </Button>
        </Box>
        {/* <Button
          sx={{
            borderRadius: "25px",
            fontSize: "14px",
            padding: "6.5px 20px",
            fontFamily: "Inter,sans-serif",
            textTransform: "capitalize",
            backgroundColor: "#FFF",
            border: "1px solid #C3D1FF",
            gap: "8px",
          }}
        >
          <AddIcon sx={{ color: "#3A5396" }} />
          <Typography color="#3A5396">Add</Typography>
        </Button> */}
      </Box>
      <Box sx={{ mt: 5 }}>
        {isLoading ? (
          <Loader height={"60vh"} />
        ) : (
          <AgGrid rowData={rowData} columnDef={columnDefs} />
        )}
      </Box>
      <CommonDialog
        open={openShareDialog}
        onClose={handleCloseDialog}
        content={dialogContent}
        actions={[]}
      />
    </Box>
  );
};

export default DashboardManagement;
