export const transformDataForPieChart = (xAxis, yAxis) => {
  let data = [];
  xAxis.forEach((x, index) => {
    data.push({
      name: xAxis[index],
      value: yAxis[index],
    });
  });
  return data;
};

export const idpRoleAccess = async (role) => {
  if (role === "admin" || role === "Super Admin") return true;
  else return false;
};

// export const dashBoardMenuAccess = (role) =>{
//   if(role === "creator" || role === "co-creator")
// }