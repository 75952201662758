import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import DatePicker from "../../../utils/DataPicker";
import { useDispatch, useSelector } from "react-redux";
import { selectFilter } from "../../../redux/selectors";
import { setFilter } from "../../../redux/actions";
import { columnValue } from "../../../services";

const TypeofFilter = ({ elm, catalogId }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const dispatch = useDispatch();
  const FilterData = useSelector(selectFilter);
  const listboxRef = useRef(null); 
  const scrollPositionRef = useRef(0); 

  const modifyDataType = (dataType) => {
    if (dataType?.includes("Date")) {
      return "Date";
    } else if (dataType?.includes("String")) {
      return "String";
    } else if (dataType?.includes("Int64")) {
      return "Number";
    } else if (dataType?.includes("Float64")) {
      return "Number";
    } else {
      return "Other";
    }
  };

  const selectedValues =
    FilterData.find((filter) => filter.colName === elm.column)?.val || [];

  const handleChange = (event, newValues) => {
    const existingFilterIndex = FilterData.findIndex(
      (filter) => filter.colName === elm.column
    );

    if (existingFilterIndex !== -1) {
      if (newValues.length === 0) {
        const updatedFilterData = [...FilterData];
        updatedFilterData.splice(existingFilterIndex, 1);
        dispatch(setFilter(updatedFilterData));
      } else {
        const updatedFilterData = [...FilterData];
        updatedFilterData[existingFilterIndex].val = newValues;
        dispatch(setFilter(updatedFilterData));
      }
    } else {
      if (newValues.length > 0) {
        dispatch(
          setFilter([
            ...FilterData,
            {
              colName: elm.column,
              type: modifyDataType(elm.dataType) === "Date" ? "range" : "",
              val: newValues,
            },
          ])
        );
      }
    }
  };

  const isEmptyOptions = !elm.values || elm.values[0] === null;

  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight ===
      event.target.clientHeight + event.target.scrollTop;
    if (bottom && hasMore && !loading) {
      scrollPositionRef.current = event.target.scrollTop;
      setPage((prevPage) => prevPage + 1);
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const data = await columnValue({
        catalogId: catalogId,
        data: {
          columnName: elm.column,
          offset: page,
        },
      });
      const newOptions = data.data.data.result;
      setOptions((prev) => [...prev, ...newOptions]);
      setHasMore(newOptions.length > 0);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      setLoading(false);
      setTimeout(() => {
        if (listboxRef.current) {
          listboxRef.current.scrollTop = scrollPositionRef.current;
        }
      }, 100);
    }
  };

  useEffect(() => {
    getData();
  }, [page]);
  return (
    <Box>
      {modifyDataType(elm?.dataType) === "Date" ? (
        <DatePicker elm={elm} />
      ) : (
        <Autocomplete
          multiple
          id="combo-box-demo"
          options={options}
          disableCloseOnSelect
          getOptionLabel={(option) => option || ""}
          value={selectedValues}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Values"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          renderOption={(props, option, { selected }) => {
            if (isEmptyOptions) {
              return (
                <li {...props} style={{ pointerEvents: "none" }}>
                  <Typography variant="body2" color="textSecondary">
                    No data
                  </Typography>
                </li>
              );
            } else {
              return (
                <li {...props}>
                  <Checkbox
                    icon={<span />}
                    checkedIcon={<span />}
                    checked={selected}
                  />
                  <Typography variant="body2">{option}</Typography>
                </li>
              );
            }
          }}
          ListboxProps={{
            onScroll: handleScroll,
            ref: listboxRef,
          }}
        />
      )}
    </Box>
  );
};

export default TypeofFilter;
