import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  IconButton,
  InputBase,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import AgGrid from "../../utils/AgGrid";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import NotInterestedSharpIcon from "@mui/icons-material/NotInterestedSharp";
import ReplyAllSharpIcon from "@mui/icons-material/ReplyAllSharp";
import CloseIcon from "@mui/icons-material/Close";
import CommonDialog from "../../utils/Dialog";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllCatlog,
  selectAllConnection,
  selectAllUsers,
} from "../../redux/selectors";
import { getAllConnection } from "../../services";
import { setAllConnection, setAllUsers } from "../../redux/actions";
import {
  getUsersForCatalog,
  revokeCatalogAccess,
  shareCatalogAccess,
} from "../../services/PermissionModule";
import UserCard from "../../Component/UserCard";
import { enqueueSnackbar } from "notistack";
import Loader from "../../utils/Loader";
import { getUsers } from "../../services/core";
import { useNavigate } from "react-router-dom";

const CatalogManagement = () => {
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [usersForCatalog, setUsersForCatalog] = useState();
  const [searchValue, setSearchValue] = useState("");
  const AllCatlogList = useSelector(selectAllCatlog);
  const allConnections = useSelector(selectAllConnection);
  const allUsersData = useSelector(selectAllUsers);
  const dispatch = useDispatch();
  const [adminUser, setAdminUser] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [actionCatalog, setActionCatalog] = useState();
  const [loadingCatalog, setLoadingCatalog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getAllConnectionAsync();
    getUsersAsync();
  }, []);
  const getUsersAsync = async () => {
    setIsLoading(true);
    if (allUsersData?.length === 0) {
      const res = await getUsers();
      dispatch(setAllUsers(res?.data?.data));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (allUsersData.length > 0) {
      getAdminUsers();
    }
  }, [allUsersData]);

  const getAdminUsers = () => {
    const adminUsers = allUsersData.filter((user) =>
      user.roles.includes("admin")
    );
    setAdminUser(adminUsers);
  };

  const getAllConnectionAsync = async () => {
    setIsLoading(true);

    const res = await getAllConnection();
    dispatch(setAllConnection(res?.data?.data.result));
    setIsLoading(false);
  };

  const handleShareIconClick = async (catalogId) => {
    setLoadingCatalog(true);
    setActionCatalog(catalogId);
    setOpenShareDialog(true);
    const data = {
      catalogId: catalogId,
    };
    const res = await getUsersForCatalog(data);
    setUsersForCatalog(res?.data?.data);
    setLoadingCatalog(false);
  };

  const handleCloseDialog = () => {
    setOpenShareDialog(false);
  };

  const revokeCatalogAccessAsync = async (catalogId, email) => {
    setLoadingCatalog(true);
    const reqData = {
      catalogId: catalogId,
      data: {
        email: email,
      },
    };

    const res = await revokeCatalogAccess(reqData);
    handleShareIconClick(catalogId);
    if (res?.data?.success === true) {
      enqueueSnackbar(`Catalog Access Revoked Successfully `, {
        variant: "success",
      });
    }
    setLoadingCatalog(false);

    console.log(res);
  };

  const shareCatalogAccessAsync = async () => {
    const modifiedselectedUser = selectedUser.map((user) => ({
      email: user.email,
      role: user.roles[0],
    }));
    const data = {
      catalogId: actionCatalog,
      data: modifiedselectedUser,
    };

    const res = await shareCatalogAccess(data);
    console.log(res?.response?.data?.err?.failedShares, "response");

    if (res?.data?.success === true) {
      selectedUser?.map((user) =>
        enqueueSnackbar(`Catalog Shared Successfully to ${user?.name}`, {
          variant: "success",
        })
      );

      setOpenShareDialog(false);
    } else {
      enqueueSnackbar(`${res?.response.data?.message}`, {
        variant: "error",
      });
    }
  };

  const dialogContent = (
    <Box
      sx={{
        borderRadius: "9px",
        display: "flex",
        gap: "20px",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Inter,sans-serif",
            fontWeight: "500",
          }}
        >
          Share your catalog with
        </Typography>
        <CloseIcon onClick={handleCloseDialog} cursor="pointer" />
      </Box>
      <Box sx={{ display: "flex", gap: "30px", mt: 2 }}>
        <Autocomplete
          multiple
          sx={{ width: "400px" }}
          limitTags={2}
          id="tags-standard"
          options={
            adminUser
            // ? adminUser.map((user) => ({
            //     id: user.id,
            //     name: user.name,
            //     email: user.email,
            //     profilePic: user.profilePic,
            //     role:user.roles[0]
            //   }))
            // : []
          }
          getOptionLabel={(option) => option.name}
          onChange={(e, value) => setSelectedUser(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Add People"
            />
          )}
          renderOption={(props, option) => (
            <Box {...props} p={1}>
              <UserCard
                name={option.name}
                email={option.email}
                profile={option?.profilePic}
              />
            </Box>
          )}
        />
      </Box>
      <Box>
        <Typography variant="subtitle2">Creator</Typography>
        <UserCard
          name={usersForCatalog?.createdBy?.name}
          email={usersForCatalog?.createdBy?.email}
          profile={usersForCatalog?.createdBy?.profilePic}
          showDeleteIcon={
            JSON.parse(localStorage.getItem("user")).email ===
            usersForCatalog?.createdBy?.email
              ? false
              : true
          }
          onClickDelete={() =>
            revokeCatalogAccessAsync(
              usersForCatalog.catalogId,
              usersForCatalog?.createdBy?.email
            )
          }
        />
      </Box>
      <Box>
        {usersForCatalog?.result.length !== 0 ? (
          <Typography>Already Shared with</Typography>
        ) : (
          ""
        )}
      </Box>
      <Box
        sx={{
          maxHeight: "170px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "5px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        }}
      >
        {loadingCatalog ? (
          <Loader />
        ) : usersForCatalog?.result ? (
          usersForCatalog.result.map((user, index) => (
            <UserCard
              key={index}
              name={user?.name}
              email={user?.email}
              profile={user?.profilePic}
              showDeleteIcon={true}
              onClickDelete={() =>
                revokeCatalogAccessAsync(usersForCatalog.catalogId, user?.email)
              }
            />
          ))
        ) : (
          ""
        )}
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", gap: "24px" }}
      >
        <Button
          sx={{
            color: "#343434",
            borderRadius: "20px",
            fontSize: "14px",
            padding: "6px 20px",
            backgroundColor: "#FFF",
            fontFamily: "Inter,sans-serif",
            textTransform: "capitalize",
            border: "1px solid #989898",
          }}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          sx={{
            color: "#FFF",
            borderRadius: "20px",
            fontSize: "14px",
            padding: "6px 20px",
            backgroundColor: "#3A5396",
            fontFamily: "Inter,sans-serif",
            textTransform: "capitalize",
            ":hover": {
              backgroundColor: "#3A5396",
              color: "#FFF",
            },
          }}
          onClick={shareCatalogAccessAsync}
        >
          Share
        </Button>
      </Box>
    </Box>
  );

  const filteredData = AllCatlogList?.filter((catalog) =>
    catalog.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const columnDefs = [
    { headerName: "Catalog", field: "name" },
    { headerName: "Connection", field: "connection" },
    { headerName: "Table", field: "metaData.table.name" },
    {
      headerName: "Action",
      field: "action",
      cellRenderer: (params) => (
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            cursor: "pointer",
            alignItems: "center",
          }}
        >
          <ReplyAllSharpIcon
            sx={{ transform: "scaleX(-1)" }}
            onClick={() => handleShareIconClick(params.data._id)}
          />
          <IconButton disabled>
            <EditSharpIcon />
          </IconButton>
          <IconButton disabled>
            <NotInterestedSharpIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const rowData = filteredData?.map((catalog) => {
    const matchedConnection = allConnections?.find(
      (connection) => connection._id === catalog.connectionId
    );

    return {
      _id: catalog._id,
      name: catalog.name,
      connection: matchedConnection && matchedConnection?.name,
      metaData: catalog.metaData,
      action: "",
    };
  });

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#202223",
              fontSize: "24px",
              fontWeight: "600",
              fontFamily: "Inter,sans-serif",
            }}
          >
            Catalog Management
          </Typography>
          <Tooltip
            title={"All the existing catalog details are displayed below"}
          >
            <ErrorOutlineOutlinedIcon sx={{ color: "#B6B6B6" }} />
          </Tooltip>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 2,
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: "15px",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "90%" },
            height: "50px",
            borderRadius: "50px",
            border: "1px solid #C3D1FF",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#FFF",
          }}
        >
          <IconButton sx={{ p: "10px", color: "#6D80C3" }} aria-label="menu">
            <SearchIcon fontSize="large" />
          </IconButton>
          <InputBase
            id="searchInput"
            autoComplete="off"
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Catalog"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Button
            sx={{
              color: "#FFF",
              borderRadius: "20px",
              fontSize: "14px",
              padding: "6px 20px",
              margin: "10px",
              backgroundColor: "#3A5396",
              fontFamily: "Inter,sans-serif",
              textTransform: "capitalize",
              ":hover": {
                backgroundColor: "#3A5396",
                color: "#FFF",
              },
            }}
          >
            Search
          </Button>
        </Box>
        <Button
          sx={{
            borderRadius: "25px",
            fontSize: "14px",
            padding: "6.5px 20px",
            fontFamily: "Inter,sans-serif",
            textTransform: "capitalize",
            backgroundColor: "#FFF",
            border: "1px solid #C3D1FF",
            gap: "8px",
          }}
          onClick={() => navigate("/catalog/connection/create-connection")}
        >
          <AddIcon sx={{ color: "#3A5396" }} />
          <Typography color="#3A5396">Add</Typography>
        </Button>
      </Box>
      <Box sx={{ mt: 5 }}>
      {isLoading ? (
          <Loader height={"60vh"}/>
        ) : (
          <AgGrid rowData={rowData} columnDef={columnDefs} />
        )}
      </Box>
      <CommonDialog
        open={openShareDialog}
        onClose={handleCloseDialog}
        content={dialogContent}
        actions={[]}
      />
    </Box>
  );
};

export default CatalogManagement;
