import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCatalogData, setConnectionData, setDataBase, setTestConnection } from "../../../redux/actions";
import CommonDialog from "../../../utils/Dialog";
import { selectCatalogData, selectConnectionData, selectDataBase } from "../../../redux/selectors";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const catalogData = useSelector(selectCatalogData);
  const dataBase = useSelector(selectDataBase);
  const connectionData = useSelector(selectConnectionData)

  // const handleCancel = () => {
  //   const userConfirmed = window.confirm("Do you want to exit?");
  //   if (userConfirmed) {
  //     navigate("/");
  //     dispatch(
  //       setConnectionData({
  //         name: "",
  //         host: "",
  //         port: "",
  //         database: "",
  //         username: "",
  //         password: "",
  //         driver: "",
  //       })
  //     );
  //   }
  // };

  const crossClick = () => {
    if (location.pathname === "/catalog/connection/create-connection") {
      const isConnectionDataNotEmpty = Object.values(connectionData).some((value) => value && value !== "");
      if (isConnectionDataNotEmpty) {
        setCancelDialogOpen(true);
      } else {
        handleConfirmCancel(); 
      }
    } else if (location.pathname === "/catalog/connection/select-existing") {
      if (dataBase !== "") {
        setCancelDialogOpen(true);
      } else {
        handleConfirmCancel(); 
      }
    } else if (location.pathname === "/catalog/select-table") {
      if (catalogData.metaData.table !== null) {
        setCancelDialogOpen(true);
      } else {
        handleConfirmCancel(); 
      }
    } else if (location.pathname === "/catalog/prepare-metadata") {
      if (catalogData.metaData.selectedFilters.length > 0) {
        setCancelDialogOpen(true); 
      } else {
        handleConfirmCancel()
      }
    } else if (location.pathname === "/catalog/save") {
      if (catalogData.name !== "") {
        setCancelDialogOpen(true);
      } else {
        handleConfirmCancel(); 
      }
    }
  };
  

  const handleConfirmCancel = () => {
    navigate("/");
    dispatch(
      setConnectionData({
        name: "",
        host: "",
        port: "",
        database: "",
        username: "",
        password: "",
        driver: "",
      })
    );
    dispatch(setDataBase(""));
    dispatch(
      setCatalogData({
        connectionId: "",
        metaData: {
          table: null,
          columns: [],
          selectedFilters: [],
        },
        name: "",
      })
    );
    dispatch(setTestConnection(false))
    setCancelDialogOpen(false); 
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = "Changes you made may not be saved.";
      event.returnValue = message;
      return message;
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          padding: "15px 22px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: {xs:"16px",md:"24px"},
            fontWeight: "500",
            fontStyle: "Medium",
          }}
        >
          Data source Connection
        </Typography>
        <Button onClick={crossClick}>
          <ClearIcon />
        </Button>
      </Box>
      <CommonDialog
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
        title="Confirmation Dialog"
        content="Are you sure you want to cancel? Any unsaved changes will be lost."
        actions={[
          {
            label: "No",
            color: "primary",
            onClick: () => setCancelDialogOpen(false),
          },
          {
            label: "Yes",
            color: "warning",
            onClick: handleConfirmCancel,
          },
        ]}
      />
    </>
  );
};

export default Header;
