import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { getAllDrivers, testConnection } from "../../../../../services";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllConnection,
  selectConnectionData,
} from "../../../../../redux/selectors";
import {
  setConnectionData,
  setDataBase,
  setTestConnection,
} from "../../../../../redux/actions";

const CreateConnection = () => {
  const [test, setTest] = useState(null);
  const [error, setError] = useState(null);
  const [testing, setTesting] = useState(false);
  const dispatch = useDispatch();
  const allConnections = useSelector(selectAllConnection);
  const connectionData = useSelector(selectConnectionData);

  const validationSchema = Yup.object({
    // name: Yup.string().required("Connection Name is required"),
    name: Yup.string()
      .required("Connection Name is required")
      .matches(/^\S.*\S$/, "Spaces is not allowed at the beginning or end"),
    host: Yup.string()
      .required("Host Name is required")
      .matches(/^\S.*\S$/, "Spaces is not allowed at the beginning or end"),
    port: Yup.string()
      .required("Port is required")
      .matches(
        /^[0-9!@#$%^&*(),.?":{}|<>]+$/,
        "Port section should only consider the numerical and special characters."
      )
      .matches(/^\S.*\S$/, "Spaces is not allowed at the beginning or end"),
    database: Yup.string()
      .required("Database is required")
      .matches(/^\S.*\S$/, "Spaces is not allowed at the beginning or end"),
    username: Yup.string()
      .required("User Name is required")
      .matches(/^\S.*\S$/, "Spaces is not allowed at the beginning or end"),
    password: Yup.string()
      .required("Password is required")
      .matches(/^\S.*\S$/, "Spaces is not allowed at the beginning or end"),
  });
  const formik = useFormik({
    initialValues: {
      name: connectionData.name || "",
      host: connectionData.host || "",
      port: connectionData.port || "",
      database: connectionData.database || "",
      username: connectionData.username || "",
      password: connectionData.password || "",
      driver: connectionData.driver || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => testHandler(values),
  });
  useEffect(() => {
    dispatch(setTestConnection(false));
  }, []);
  const testHandler = async (values) => {
    setTesting(true);
    const data = {
      data: connectionData,
    };
    const res = await testConnection(data);

    if (res?.data?.success === true) {
      setTest(true);
      dispatch(setTestConnection(true));
      setTesting(false);
    } else {
      setTest(false);
      dispatch(setTestConnection(false));
      setTesting(false);
    }
  };
  const [driver, setDriver] = useState([]);
  const handleDriver = async () => {
    const data = await getAllDrivers();
    setDriver(data?.data?.data?.result);
  };
  useEffect(() => {
    handleDriver();
  }, []);

  const handleClickShowPassword = () =>
    formik.setFieldValue("showPassword", !formik.values.showPassword);

  return (
    <Box sx={{
        // bgcolor:"white",
        width:{xs: "100%", md: "70%", lg: "50%" },
        // padding:"10px 10px",
        mt: 2,
      }}
    >
      <Typography
        sx={{ fontSize: "23px", color: "#2C2C2C", fontWeight: "300" }}
      >
        Create New Connection
      </Typography>

      <Box>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            variant="standard"
            label="Connection Name"
            fullWidth
            onChange={(e) => {
              setTest(null);
              dispatch(setTestConnection(false));
              const isConnectionNameExists = allConnections.some(
                (connection) =>
                  connection.name.toLowerCase() === e.target.value.toLowerCase()
              );
              if (isConnectionNameExists) {
                setError("Name is Already Taken");
              } else {
                setError(null);
              }

              dispatch(
                setConnectionData({
                  ...connectionData,
                  name: e.target.value,
                })
              );
              formik.handleChange(e);
            }}
            value={formik.values.name}
            name="name"
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          {error && (
            <Typography sx={{ color: "#EA364D", mt: 1, fontSize: "13px" }}>
              {error}
            </Typography>
          )}

          <TextField
            variant="standard"
            label="Port"
            fullWidth
            name="port"
            sx={{ mt: { md: 1.5 } }}
            onChange={(e) => {
              setTest(null);
              dispatch(setTestConnection(false));
              dispatch(
                setConnectionData({
                  ...connectionData,
                  port: e.target.value,
                })
              );
              formik.handleChange(e);
            }}
            value={formik.values.port}
            error={formik.touched.port && Boolean(formik.errors.port)}
            helperText={formik.touched.port && formik.errors.port}
          />
          <TextField
            variant="standard"
            label="Host Name"
            fullWidth
            sx={{ mt: { md: 1.5 } }}
            name="host"
            onChange={(e) => {
              setTest(null);
              dispatch(setTestConnection(false));
              dispatch(
                setConnectionData({
                  ...connectionData,
                  host: e.target.value,
                })
              );
              formik.handleChange(e);
            }}
            value={formik.values.host}
            error={formik.touched.host && Boolean(formik.errors.host)}
            helperText={formik.touched.host && formik.errors.host}
          />
          <TextField
            variant="standard"
            label="Database"
            fullWidth
            name="database"
            sx={{ mt: { md: 1.5 } }}
            onChange={(e) => {
              setTest(null);
              dispatch(setTestConnection(false));
              dispatch(
                setConnectionData({
                  ...connectionData,
                  database: e.target.value,
                })
              );
              dispatch(setDataBase(e.target.value));
              formik.handleChange(e);
            }}
            value={formik.values.database}
            error={formik.touched.database && Boolean(formik.errors.database)}
            helperText={formik.touched.database && formik.errors.database}
          />
          <TextField
            variant="standard"
            label="User Name"
            fullWidth
            name="username"
            sx={{ mt: { md: 1.5 } }}
            onChange={(e) => {
              setTest(null);
              dispatch(setTestConnection(false));
              dispatch(
                setConnectionData({
                  ...connectionData,
                  username: e.target.value,
                })
              );
              formik.handleChange(e);
            }}
            value={formik.values.username}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
          <TextField
            variant="standard"
            label="Password"
            fullWidth
            id="outlined-adornment-password"
            sx={{ mt: { md: 1.5 } }}
            type={formik.values.showPassword ? "text" : "password"}
            onChange={(e) => {
              setTest(null);
              dispatch(setTestConnection(false));
              dispatch(
                setConnectionData({
                  ...connectionData,
                  password: e.target.value,
                })
              );
              formik.handleChange(e);
            }}
            value={formik.values.password}
            name="password"
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ pr: 2 }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {formik.values.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <TextField
            variant="standard"
            label="Driver"
            fullWidth
            name="driver"
            sx={{ mt:1.5 }}
            onChange={(e) => {
              setTest(null);
              dispatch(setTestConnection(false));
              dispatch(
                setConnectionData({
                  ...connectionData,
                  driver: e.target.value,
                })
              );
              formik.handleChange(e);
            }}
            value={formik.values.driver}
            error={formik.touched.driver && Boolean(formik.errors.driver)}
            helperText={formik.touched.driver && formik.errors.driver}
          /> */}

          <Autocomplete
            style={{
              width: "100%",
            }}
            size="small"
            disablePortal
            sx={{ width: "250px" }}
            options={driver || []}
            getOptionLabel={(option) => option || ""}
            onChange={(event, newValue) => {
              setTest(null);
              dispatch(setTestConnection(false));
              dispatch(
                setConnectionData({
                  ...connectionData,
                  driver: newValue,
                })
              );
              formik.setFieldValue("driver", newValue);
            }}
            value={formik.values.driver}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Driver"
                fullWidth
                name="driver"
                variant="standard"
                sx={{ mt: { md: 1.5 } }}
                error={formik.touched.driver && Boolean(formik.errors.driver)}
                helperText={formik.touched.driver && formik.errors.driver}
              />
            )}
          />

          <Box
            sx={{
              width: "100%",
              mt: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <LoadingButton
              disabled={error === null ? false : true}
              type="submit"
              loading={testing}
              variant="outlined"
              size="medium"
            >
              Test Connection
            </LoadingButton>
            {test === true ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  bgcolor: "#41B45A",
                  padding: "7px 10px",
                  borderRadius: "8px",
                  // width: "10vw",
                }}
              >
                <CheckCircleRoundedIcon sx={{ marginRight: "10px" }} />
                <Typography>Connection is valid</Typography>
              </Box>
            ) : test === false ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  bgcolor: "#EA364D",
                  padding: "7px 10px",
                  borderRadius: "8px",
                  // width: "10vw",
                }}
              >
                <CancelRoundedIcon sx={{ marginRight: "10px" }} />
                <Typography>Connection is Invalid</Typography>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default CreateConnection;