import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { callBackURL } from "../../../configs/envConfig";
import { getToken, getUsersToken } from "../../../services/core";
import { setToken } from "../../../services/jwtService";
import Loader from "../../../Component/Loader";
import { setAllUsers } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { addUser } from "../../../services";

const CallbackHandler = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const code = searchParams.get("code");

  const handleCore = async () => {
    const data = {
      data: {
        redirectUri: callBackURL,
        clientName: "qnai",
        code: code,
      },
    };

    const res = await getToken(data);

    if (res?.data?.status?.code === 200) {
      setToken(res?.data?.data);

      const userRes = await getUsersToken(res?.data?.data.token.access_token);
      const addUserRes = await addUser();
      dispatch(setAllUsers(userRes?.data?.data));
      console.log("addUserRes", addUserRes);
      return navigate("/");
    }
  };

  useEffect(() => {
    handleCore();
  });

  // useEffect(() => {
  //   if(JSON.parse(localStorage.getItem("user"))?.roles[0] === "admin"){
  //     navigate("/answer-board")
  //   }
  // }, [])

  return (
    <div>
      <Loader />
    </div>
  );
};

export default CallbackHandler;
