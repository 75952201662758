import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { UpdateBoard } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { selectAllBoards } from "../../redux/selectors";
import { setAllBoards } from "../../redux/actions";

const UpdateName = ({ setOpenUpdateName, setAnchorEl, answerBoardId }) => {
  const AllBoardsData = useSelector(selectAllBoards);
  const dispatch = useDispatch();
  const board = AllBoardsData.find((board) => board._id === answerBoardId);
  const initialBoardName = board ? board.name : "";
  const [answerBoardName, setAnswerBoardName] = useState(initialBoardName);
  const [textFieldError, setTextFieldError] = useState("");
  const [existingNameError, setExistingNameError] = useState("");
  const validateInput = (value) => {
    if (!value.trim()) {
      return "Board name cannot be empty";
    }
    if (!/^\S(?:.*\S)?$/.test(value)) {
      return "Board name cannot have spaces at the beginning or end";
    }
    return "";
  };
  const handleUpdate = async () => {
    const errorMessage = validateInput(answerBoardName);
    if (errorMessage) {
      setTextFieldError(errorMessage);
      return;
    }
    const isExistingName = AllBoardsData.some(
      (board) => board.name.toLowerCase() === answerBoardName.toLowerCase()
    );
    if (isExistingName) {
      setExistingNameError(
        "Board name already exists. Please choose a different name."
      );
      return;
    }
    const data = {
      boardId: answerBoardId,
      data: {
        name: answerBoardName,
      },
    };
    const apiResponse = await UpdateBoard(data);
    if (apiResponse.data.data.msg === "Board updated sucessfully") {
      console.log("here");
      const boardIndex = AllBoardsData.findIndex(
        (board) => board._id === answerBoardId
      );
      console.log(boardIndex, "boardIndex");
      if (boardIndex !== -1) {
        const updatedBoardsData = [...AllBoardsData];
        updatedBoardsData[boardIndex] = {
          ...updatedBoardsData[boardIndex],
          name: answerBoardName,
        };
        console.log("updatedBoardsData", updatedBoardsData);
        dispatch(setAllBoards(updatedBoardsData));
      }
    }
    setOpenUpdateName(false);
    setAnchorEl(null);
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setAnswerBoardName(value);
    setTextFieldError(validateInput(value));
    setExistingNameError("");
  };

  return (
    <Box sx={{ padding: "10px" }}>
      <DialogTitle
        sx={{
          marginBottom: "10px",
        }}
      >
        Enter Update Name
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the value in the field below
        </DialogContentText>
        <TextField
          sx={{ width: "400px", height: "60px" }}
          autoFocus
          placeholder="Name"
          type="text"
          fullWidth
          size="small"
          margin="dense"
          value={answerBoardName}
          // onChange={(e) => setAnswerBoardName(e.target.value)}
          onChange={handleChange}
          inputProps={{ maxLength: 40 }}
          error={textFieldError || existingNameError}
          helperText={textFieldError || existingNameError}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpenUpdateName(false);
            setAnchorEl(null);
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleUpdate();
          }}
          color="primary"
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Box>
  );
};

export default UpdateName;
