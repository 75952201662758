import React from "react";

const FAQ = () => {
  const users = [
    {
      "Row ID": 9989,
      "Order ID": "CA-2017-163629",
      "Order Date": "11/17/2017",
      "Ship Date": "11/21/2017",
      "Ship Mode": "Standard Class",
      "Customer ID": "RA-19885",
      "Customer Name": "Ruben Ausman",
      Segment: "Corporate",
      Country: "United States",
      City: "Athens",
      State: "Georgia",
      "Postal Code": 30605,
      Region: "South",
      "Product ID": "TEC-PH-10004006",
      Category: "Technology",
      "Sub-Category": "Phones",
      "Product Name": "Panasonic KX - TS880B Telephone",
      Sales: "206.1",
      Quantity: "5",
      Discount: "0",
      Profit: "55.647",
    },
    {
      "Row ID": 9990,
      "Order ID": "CA-2014-110422",
      "Order Date": "1/21/2014",
      "Ship Date": "1/23/2014",
      "Ship Mode": "Second Class",
      "Customer ID": "TB-21400",
      "Customer Name": "Tom Boeckenhauer",
      Segment: "Consumer",
      Country: "United States",
      City: "Miami",
      State: "Florida",
      "Postal Code": 33180,
      Region: "South",
      "Product ID": "FUR-FU-10001889",
      Category: "Furniture",
      "Sub-Category": "Furnishings",
      "Product Name": "Ultra Door Pull Handle",
      Sales: "25.248",
      Quantity: "3",
      Discount: "0",
      Profit: "4.1028",
    },
    {
      "Row ID": 9991,
      "Order ID": "CA-2017-121258",
      "Order Date": "2/26/2017",
      "Ship Date": "03-03-2017",
      "Ship Mode": "Standard Class",
      "Customer ID": "DB-13060",
      "Customer Name": "Dave Brooks",
      Segment: "Consumer",
      Country: "United States",
      City: "Costa Mesa",
      State: "California",
      "Postal Code": 92627,
      Region: "West",
      "Product ID": "FUR-FU-10000747",
      Category: "Furniture",
      "Sub-Category": "Furnishings",
      "Product Name": "Tenex B1-RE Series Chair Mats for Low Pile Carpets",
      Sales: "91.96",
      Quantity: "2",
      Discount: "0",
      Profit: "15.6332",
    },
    {
      "Row ID": 9992,
      "Order ID": "CA-2017-121258",
      "Order Date": "2/26/2017",
      "Ship Date": "03-03-2017",
      "Ship Mode": "Standard Class",
      "Customer ID": "DB-13060",
      "Customer Name": "Dave Brooks",
      Segment: "Consumer",
      Country: "United States",
      City: "Costa Mesa",
      State: "California",
      "Postal Code": 92627,
      Region: "West",
      "Product ID": "TEC-PH-10003645",
      Category: "Technology",
      "Sub-Category": "Phones",
      "Product Name": "Aastra 57i VoIP phone",
      Sales: "258.576",
      Quantity: "2",
      Discount: "0",
      Profit: "19.3932",
    },
    {
      "Row ID": 9993,
      "Order ID": "CA-2017-121258",
      "Order Date": "2/26/2017",
      "Ship Date": "03-03-2017",
      "Ship Mode": "Standard Class",
      "Customer ID": "DB-13060",
      "Customer Name": "Dave Brooks",
      Segment: "Consumer",
      Country: "United States",
      City: "Costa Mesa",
      State: "California",
      "Postal Code": 92627,
      Region: "West",
      "Product ID": "OFF-PA-10004041",
      Category: "Office Supplies",
      "Sub-Category": "Paper",
      "Product Name": 'It\'s Hot Message Books with Stickers, 2 3/4" x 5"',
      Sales: "29.6",
      Quantity: "4",
      Discount: "0",
      Profit: "13.32",
    },
    {
      "Row ID": 9994,
      "Order ID": "CA-2017-119914",
      "Order Date": "05-04-2017",
      "Ship Date": "05-09-2017",
      "Ship Mode": "Second Class",
      "Customer ID": "CC-12220",
      "Customer Name": "Chris Cortes",
      Segment: "Consumer",
      Country: "United States",
      City: "Westminster",
      State: "California",
      "Postal Code": 92683,
      Region: "West",
      "Product ID": "OFF-AP-10002684",
      Category: "Office Supplies",
      "Sub-Category": "Appliances",
      "Product Name":
        "Acco 7-Outlet Masterpiece Power Center, Wihtout Fax/Phone Line Protection",
      Sales: "243.16",
      Quantity: "2",
      Discount: "0",
      Profit: "72.948",
    },
  ];
  const header = Object.keys(users[0]);
  const list_of_lists = users.map((user) => header.map((key) => user[key]));

  return (
    <p>
      
    </p>
  );
};

export default FAQ;
