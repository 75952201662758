import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const user = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")).access_token : null;

  return user ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" replace />
  );

};

export default ProtectedRoute;
