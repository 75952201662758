import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Tooltip,
  Typography,
} from "@mui/material";
import AgGrid from "../../utils/AgGrid";
import AddIcon from "@mui/icons-material/Add";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import NotInterestedSharpIcon from "@mui/icons-material/NotInterestedSharp";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ManagementButton from "../../utils/ManagementButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CommonDialog from "../../utils/Dialog";
import {
  getCatalogForUser,
  getDashboardsForUser,
  getDetails,
  revokeCatalogAccess,
  revokeDashboardAccess,
} from "../../services/PermissionModule";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../redux/actions";
import {
  selectAllBoards,
  selectAllCatlog,
  selectUserDetails,
} from "../../redux/selectors";
import { enqueueSnackbar } from "notistack";
import Loader from "../../utils/Loader";

const DialogContent = ({ title, onClose, children }) => (
  <Box
    sx={{
      borderRadius: "25px",
      display: "flex",
      flexDirection: "column",
    }}
  >
    {children}
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: "24px",
        mt: "50px",
      }}
    >
      <Button
        sx={{
          color: "#343434",
          borderRadius: "20px",
          fontSize: "14px",
          padding: "6px 20px",
          backgroundColor: "#FFF",
          fontFamily: "Inter,sans-serif",
          textTransform: "capitalize",
          border: "1px solid #989898",
        }}
        onClick={onClose}
      >
        Cancel
      </Button>
      <Button
        sx={{
          color: "#FFF",
          borderRadius: "20px",
          fontSize: "14px",
          padding: "6px 20px",
          backgroundColor: "#3A5396",
          fontFamily: "Inter,sans-serif",
          textTransform: "capitalize",
          ":hover": {
            backgroundColor: "#3A5396",
            color: "#FFF",
          },
        }}
      >
        Share
      </Button>
    </Box>
  </Box>
);

const UserManagement = () => {
  const [catalog, setCatalog] = useState(false);
  const [dashboard, setDashboard] = useState(false);
  const dispatch = useDispatch();
  const UserDetailsData = useSelector(selectUserDetails);
  const [selectedUserCatalog, setSelectedUserCatalog] = useState(null);
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const AllBoardsData = useSelector(selectAllBoards);
  const [searchValue, setSearchValue] = useState("");
  const [loadingCatalog, setLoadingCatalog] = useState(false);

  useEffect(() => {
    getDetailsAsync();
  }, []);

  const getDetailsAsync = async () => {
    const res = await getDetails();
    console.log(res);
    dispatch(setUserDetails(res.data.data.result));
  };

  const filteredData = UserDetailsData?.filter((user) =>
    user.email.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleShareCatalog = async (email) => {
    setLoadingCatalog(true);
    setCatalog(true);
    const reqData = {
      data: {
        email: email,
      },
    };
    const res = await getCatalogForUser(reqData);
    setSelectedUserCatalog({
      email: email,
      data: res?.data?.data?.result,
    });
    setLoadingCatalog(false);
  };

  const handleShareDashboard = async (email) => {
    setLoadingCatalog(true);
    setDashboard(true);

    const reqData = {
      data: {
        email: email,
      },
    };

    const res = await getDashboardsForUser(reqData);
    setSelectedDashboard({
      email: email,
      data: res?.data?.data?.result,
    });
    setLoadingCatalog(false);
  };

  const column = [
    { headerName: "Email", field: "email" },

    {
      headerName: "Catalog",
      field: "catalogu",
      cellRenderer: (params) => (
        <Button
          onClick={() => handleShareCatalog(params.data.email)}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          Catalog
        </Button>
      ),
    },
    {
      headerName: "Dashboard",
      field: "dashboardv",
      cellRenderer: (params) => (
        <Button
          onClick={() => handleShareDashboard(params.data.email)}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          Dashboard
        </Button>
      ),
    },
    {
      headerName: "Action",
      field: "action",
      cellRenderer: (params) => (
        <Box sx={{ display: "flex", gap: "5px" }}>
          <IconButton disabled>
            <EditSharpIcon />
          </IconButton>
          <IconButton disabled>
            <NotInterestedSharpIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const revokeCatalogAccessAsync = async (catalogId, email) => {
    setLoadingCatalog(true);

    const reqData = {
      catalogId: catalogId,
      data: {
        email: email,
      },
    };

    const res = await revokeCatalogAccess(reqData);
    if (res?.data?.success === true) {
      enqueueSnackbar(`Catalog Access Revoked Successfully `, {
        variant: "success",
      });
    }
    handleShareCatalog(selectedUserCatalog.email);
    setLoadingCatalog(false);
  };

  const revokeDashboardAccessAsync = async (boardId, email, role) => {
    setLoadingCatalog(true);

    const reqData = {
      boardId: boardId,
      data: {
        email: email,
        role: role,
      },
    };

    const res = await revokeDashboardAccess(reqData);
    if (res?.data?.success === true) {
      enqueueSnackbar(`Dashboard revoked successfully`, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(`Something went wrong Please Try  again later!`, {
        variant: "error",
      });
    }
    handleShareDashboard(email);
    setLoadingCatalog(false);
  };

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#202223",
              fontSize: "24px",
              fontWeight: "600",
              fontFamily: "Inter,sans-serif",
            }}
          >
            User Management
          </Typography>
          <Tooltip title={"All the existing user details are displayed below"}>
            <ErrorOutlineOutlinedIcon sx={{ color: "#B6B6B6" }} />
          </Tooltip>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 2,
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: "15px",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "100%" },
            height: "50px",
            borderRadius: "50px",
            border: "1px solid #C3D1FF",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#FFF",
          }}
        >
          <IconButton sx={{ p: "10px", color: "#6D80C3" }} aria-label="menu">
            <SearchIcon fontSize="large" />
          </IconButton>
          <InputBase
            id="searchInput"
            autoComplete="off"
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search User"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Button
            sx={{
              color: "#FFF",
              borderRadius: "20px",
              fontSize: "14px",
              padding: "6px 20px",
              margin: "10px",
              backgroundColor: "#3A5396",
              fontFamily: "Inter,sans-serif",
              textTransform: "capitalize",
            }}
          >
            Search
          </Button>
        </Box>
        {/* <Button
          sx={{
            borderRadius: "25px",
            fontSize: "14px",
            padding: "6.5px 20px",
            fontFamily: "Inter,sans-serif",
            textTransform: "capitalize",
            backgroundColor: "#FFF",
            border: "1px solid #C3D1FF",
            gap: "8px",
          }}
        >
          <AddIcon sx={{ color: "#3A5396" }} />
          <Typography color="#3A5396">Add</Typography>
        </Button> */}
      </Box>
      <Box sx={{ mt: 5 }}>
        <AgGrid rowData={filteredData} columnDef={column} />
      </Box>

      <CommonDialog
        open={catalog}
        onClose={() => setCatalog(false)}
        title="Catalog"
        content={
          <DialogContent onClose={() => setCatalog(false)}>
            <Box
              sx={{
                maxHeight: "170px",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "5px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#555",
                },
              }}
            >
              {loadingCatalog ? (
                <Loader />
              ) : selectedUserCatalog?.data?.length === 0 ? (
                "No Catalog Share With This User"
              ) : (
                selectedUserCatalog?.data.map((item) => {
                  return (
                    <Box
                      key={item._id}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        // gap: "20px",
                        alignItems: "center",
                      }}
                    >
                      <Typography>{item?.name}</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {selectedUserCatalog?.email ===
                        item?.createdBy?.email ? (
                          "Owner"
                        ) : (
                          <IconButton
                            onClick={() =>
                              revokeCatalogAccessAsync(
                                item._id,
                                selectedUserCatalog.email
                              )
                            }
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  );
                })
              )}
            </Box>
          </DialogContent>
        }
        actions={[]}
      />
      <CommonDialog
        open={dashboard}
        onClose={() => setDashboard(false)}
        title="Dashboard"
        content={
          <DialogContent onClose={() => setDashboard(false)}>
            <Box
              sx={{
                maxHeight: "170px",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "5px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#555",
                },
              }}
            >
              {loadingCatalog ? (
                <Loader />
              ) : selectedDashboard?.data?.length === 0 ? (
                "No Board Share With This User"
              ) : (
                selectedDashboard?.data.map((item) => {
                  return (
                    <Box
                      key={item._id}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "100px",
                        alignItems: "center",
                      }}
                    >
                      <Typography>{item?.name}</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {selectedDashboard?.email === item?.createdBy?.email ? (
                          "Owner"
                        ) : (
                          <IconButton
                            onClick={() =>
                              revokeDashboardAccessAsync(
                                item._id,
                                selectedDashboard?.email,
                                item.role
                              )
                            }
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  );
                })
              )}
            </Box>
          </DialogContent>
        }
        actions={[]}
      />
    </Box>
  );
};

export default UserManagement;
