import { apiEndPointsConfig } from "./apiEndPointsConfig";
import { axiosInstance, publicAxiosInstance } from "./axiosInstance";

const role = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))?.roles[0]
  : "";

const userEmail = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))?.email
  : "";

export const getAllConnection = async () => {
  try {
    const res = await axiosInstance.get(apiEndPointsConfig.getAllConnection);
    console.log("Get All Connection");
    return res;
  } catch (err) {
    return err;
  }
};

export const testConnection = async (data) => {
  try {
    const res = await axiosInstance.post(
      apiEndPointsConfig.testConnection,
      data
    );
    console.log("Test Connection");
    return res;
  } catch (err) {
    return err;
  }
};

export const saveConnection = async (data) => {
  try {
    const res = await axiosInstance.post(
      apiEndPointsConfig.saveConnection,
      data
    );
    console.log("Save Connection");
    return res;
  } catch (err) {
    return err;
  }
};

export const getDatabases = async (data) => {
  try {
    const res = await axiosInstance.post(apiEndPointsConfig.getDatabases, data);
    console.log("Get Databases");
    return res;
  } catch (err) {
    return err;
  }
};

export const getTables = async (id) => {
  try {
    const res = await axiosInstance.get(
      `${apiEndPointsConfig.getTables}?connectionId=${id}`
    );
    console.log("Get Tables");
    console.log(res);
    return res;
  } catch (err) {
    return err;
  }
};

export const getColumns = async (data) => {
  try {
    const res = await axiosInstance.get(
      `${apiEndPointsConfig.getColumns}?connectionId=${data.id}&table=${data.tablename}`
    );
    console.log("Get Columns");
    return res;
  } catch (err) {
    return err;
  }
};

export const saveCatalogue = async (data) => {
  try {
    const res = await axiosInstance.post(
      apiEndPointsConfig.saveCatalogue,
      data
    );
    console.log("Save Catalogue");
    return res;
  } catch (err) {
    return err;
  }
};

export const getCatalogDetails = async (data) => {
  try {
    const res = await axiosInstance.post(
      apiEndPointsConfig.getCatalogDetails,
      data
    );
    console.log("Catalog Details");
    return res;
  } catch (err) {
    return err;
  }
};

export const getAllCataloge = async () => {
  try {
    if (JSON.parse(localStorage.getItem("user")).roles[0] === "Super Admin") {
      const res = await axiosInstance.get(apiEndPointsConfig.getAllCataloge);
      console.log("All Catalogue");
      return res;
    } else {
      const reqData = {
        data: {
          email: JSON.parse(localStorage.getItem("user"))?.email,
        },
      };
      const res = await axiosInstance.post(
        `${apiEndPointsConfig.getCatalogForUser}`,
        reqData
      );
      return res;
    }
  } catch (err) {
    return err;
  }
};

export const getAllBoards = async () => {
  console.log(userEmail, "email");
  try {
    if (JSON.parse(localStorage.getItem("user")).roles[0] === "Super Admin") {
      const res = await axiosInstance.get(apiEndPointsConfig.getAllBoards);
      console.log("All Boards");
      return res;
    } else {
      const reqData = {
        data: {
          email: JSON.parse(localStorage.getItem("user"))?.email,
        },
      };

      const res = await axiosInstance.post(
        apiEndPointsConfig.getDashboardsForUser,
        reqData
      );
      console.log("All Boards for user");
      return res;
    }
  } catch (err) {
    return err;
  }
};
export const getDashboardsForUser = async () => {
  const reqData = {
    data: {
      email: JSON.parse(localStorage.getItem("user"))?.email,
    },
  };

  const res = await axiosInstance.post(
    apiEndPointsConfig.getDashboardsForUser,
    reqData
  );
  // console.log(res, "All Boards for user");
  return res
};
export const getBoardsById = async (id) => {
  try {
    const res = await axiosInstance.get(
      `${apiEndPointsConfig.getBoardsById}?boardId=${id}`
    );
    console.log("Get Query ");
    return res;
  } catch (err) {
    return err;
  }
};

export const getQuery = async (data) => {
  try {
    const res = await axiosInstance.post(apiEndPointsConfig.getQuery, data);
    console.log("Get Query ");
    return res;
  } catch (err) {
    return err;
  }
};

export const runQuery = async (data, signal) => {
  try {
    const res = await axiosInstance.post(
      apiEndPointsConfig.runQuery,
      { data },
      { signal }
    );
    console.log("Query Run");
    return res;
  } catch (err) {
    return err;
  }
};

export const createBoard = async (data) => {
  try {
    const res = await axiosInstance.post(apiEndPointsConfig.createBoard, data);
    console.log("Create Board");
    return res;
  } catch (err) {
    return err;
  }
};

export const createObject = async (data) => {
  try {
    const res = await axiosInstance.post(apiEndPointsConfig.createObject, data);
    console.log("Create Object");
    return res;
  } catch (err) {
    return err;
  }
};

export const getAllObject = async (id) => {
  try {
    const res = await axiosInstance.get(
      `${apiEndPointsConfig.getAllObject}?boardId=${id}`
    );
    console.log("All Boards");
    return res;
  } catch (err) {
    return err;
  }
};

export const deleteBoard = async (id) => {
  try {
    const res = await axiosInstance.delete(
      `${apiEndPointsConfig.deleteBoard}?boardId=${id}`
    );
    console.log("Delete Board");
    return res;
  } catch (err) {
    return err;
  }
};

export const deleteObject = async (id) => {
  try {
    const res = await axiosInstance.delete(
      `${apiEndPointsConfig.deleteObject}?businessId=${id}`
    );
    console.log("Delete Object");
    return res;
  } catch (err) {
    return err;
  }
};

export const deleteConnection = async (id) => {
  try {
    const res = await axiosInstance.delete(
      `${apiEndPointsConfig.deleteConnection}?connectionId=${id}`
    );
    console.log("Delete Connection");
    return res;
  } catch (err) {
    return err;
  }
};

export const filterCategory = async (data) => {
  try {
    const res = await axiosInstance.post(
      apiEndPointsConfig.filterCategory,
      data
    );
    console.log("Filter Data");
    return res;
  } catch (err) {
    return err;
  }
};

export const columnValue = async (data) => {
  try {
    const res = await axiosInstance.post(apiEndPointsConfig.columnValue, data);
    console.log("Filter Data");
    return res;
  } catch (err) {
    throw err;
  }
};
export const getDataOfTable = async (data) => {
  try {
    const res = await axiosInstance.post(
      apiEndPointsConfig.getDataOfTable,
      data
    );
    console.log("Filter Data");
    return res;
  } catch (err) {
    return err;
  }
};

export const updateObject = async (data) => {
  try {
    const res = await axiosInstance.put(apiEndPointsConfig.updateObject, data);
    console.log("Update Object");
    return res;
  } catch (err) {
    return err;
  }
};

export const UpdateBoard = async (data) => {
  try {
    const res = await axiosInstance.put(apiEndPointsConfig.updateBoard, data);
    console.log("Update Object");
    return res;
  } catch (err) {
    return err;
  }
};

export const getAllDrivers = async () => {
  try {
    const res = await axiosInstance.get(`${apiEndPointsConfig.getDrivers}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getSingleEmbed = async (id) => {
  try {
    const res = await axiosInstance.get(
      `${apiEndPointsConfig.getDrivers}/${id}`
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const saveEmbed = async (data) => {
  try {
    const res = await axiosInstance.post(apiEndPointsConfig.saveEmbed, data);
    console.log("save Embed");
    return res;
  } catch (err) {
    return err;
  }
};

export const getEmbed = async (id) => {
  try {
    const res = await publicAxiosInstance.get(
      `${apiEndPointsConfig.getEmbed}?id=${id}`
    );
    console.log("Get Embed");
    return res;
  } catch (err) {
    return err;
  }
};

export const getSingleObject = async (id) => {
  try {
    const res = await axiosInstance.get(
      `${apiEndPointsConfig.getAllObject}?businessId=${id}`
    );
    console.log("All Boards");
    return res;
  } catch (err) {
    return err;
  }
};

export const addUser = async (id) => {
  try {
    const res = await axiosInstance.post(
      `${apiEndPointsConfig.addUser}`
    );
    console.log("User ADD");
    return res;
  } catch (err) {
    return err;
  }
};

export const updateFavouriteBoard = async (id) => {
  try {
    const res = await axiosInstance.post(
      apiEndPointsConfig.updateFavouriteBoard,
      {boardId:id}
    );
    console.log("Add Board to Favourite");
    return res;
  } catch (err) {
    return err;
  }
};