import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Input,
  Menu,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import ColorPicker from "../../utils/ColorPicker";
import {
  setAskQuestion,
  setChartProperties,
  setQueryResponse,
  setUpdateObjectData,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAskQuestion,
  selectChartProperties,
  selectQueryResponse,
  selectUpdateObjectData,
} from "../../redux/selectors";

function transformData(data) {
  const result = {};

  if (data.length === 0) {
    return result;
  }

  // Get all keys from the first object
  const keys = Object.keys(data[0]);

  // Filter keys that have string values in the first object
  const stringKeys = keys.filter((key) => typeof data[0][key] === "string");

  // Initialize result object with keys and empty arrays
  stringKeys.forEach((key) => {
    result[key] = [];
  });

  // Populate the arrays with corresponding values
  data.forEach((item) => {
    stringKeys.forEach((key) => {
      result[key].push(item[key]);
    });
  });
  return result;
}

const ChartProperties = ({
  numberOfProperties,
  update,
  queryResponse,
  updateQueryResponse,
  selectedXkey,
  setSelectedXkey,
}) => {
  const chartProperties = useSelector(selectChartProperties);
  const UpdateObjectData = useSelector(selectUpdateObjectData);
  const QueryResponseData = useSelector(selectQueryResponse);
  const AskQuestionData = useSelector(selectAskQuestion);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSecond, setAnchorElSecond] = React.useState(null);
  const [isItalic, setIsItalic] = useState(false);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const openSecond = Boolean(anchorElSecond);

  // useEffect(() => {
  //   if (chartProperties.xAxis) {
  //     setSelectedXkey(chartProperties.xAxis);
  //   }
  // }, [chartProperties.xAxis, chartProperties.yAxis]);

  const fontOptions = [
    { label: "Roboto", value: "Roboto, sans-serif" },
    { label: "Times New Roman", value: "Times New Roman, serif" },
    { label: "Arial Black", value: "Arial Black, sans-serif" },
    { label: "Courier New", value: "Courier New, monospace" },
    { label: "Georgia", value: "Georgia, serif" },
    { label: "Verdana", value: "Verdana, sans-serif" },
    { label: "Cambria", value: "Cambria, serif" },
    { label: "Comic Sans MS", value: "Comic Sans MS, cursive" },
    { label: "Trebuchet MS", value: "Trebuchet MS, sans-serif" },
    { label: "Impact", value: "Impact, sans-serif" },
    { label: "Open Sans", value: "Open Sans, sans-serif" },
    { label: "Brush Script", value: "Brush Script, cursive" },
    { label: "Consolas", value: "Consolas, monospace" },
    { label: "Futura", value: "Futura, sans-serif" },
    { label: "Lato", value: "Lato, sans-serif" },
    { label: "Raleway", value: "Raleway, sans-serif" },
  ];
  const alignmentOptions = [
    { label: "Left", value: "Left" },
    { label: "Right", value: "Right" },
  ];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSecond = (event) => {
    setAnchorElSecond(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSecond = () => {
    setAnchorElSecond(null);
  };

  const handleColorChange = (newColor) => {
    dispatch(
      update
        ? setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  chartProperties: {
                    ...UpdateObjectData?.ObjectData.data?.result
                      ?.chartProperties,
                    chartColor: newColor.hex,
                  },
                },
              },
            },
          })
        : setChartProperties({ ...chartProperties, chartColor: newColor.hex })
    );
  };

  const handleColorChangeSecond = (newColor) => {
    dispatch(
      update
        ? setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  chartProperties: {
                    ...UpdateObjectData?.ObjectData.data?.result
                      ?.chartProperties,
                    chartColorSecond: newColor.hex,
                  },
                },
              },
            },
          })
        : setChartProperties({
            ...chartProperties,
            chartColorSecond: newColor.hex,
          })
    );
  };
  const handleSliderChange = (event, newValue) => {
    let adjustedValue = newValue;
    if (newValue > 40) {
      adjustedValue = 40;
    }
    const updatedLegendProperties = {
      ...chartProperties,
      fontSize: adjustedValue,
    };

    update
      ? dispatch(
          setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  chartProperties: {
                    ...UpdateObjectData?.ObjectData.data?.result
                      ?.chartProperties,
                    fontSize: newValue,
                  },
                },
              },
            },
          })
        )
      : dispatch(setChartProperties(updatedLegendProperties));
  };

  const handleBlur = () => {
    let fontSize = chartProperties.fontSize;
    if (chartProperties.fontSize < 0) {
      fontSize = 0;
    } else if (chartProperties.fontSize > 40) {
      fontSize = 40;
    }

    const updatedLegendProperties = {
      ...chartProperties,
      fontSize: fontSize,
    };
    update
      ? dispatch(
          setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  chartProperties: {
                    ...UpdateObjectData?.ObjectData.data?.result
                      ?.chartProperties,
                    fontSize: fontSize,
                  },
                },
              },
            },
          })
        )
      : dispatch(setChartProperties(updatedLegendProperties));
  };

  const handleAlignmentChange = (newValue) => {
    const updatedLegendProperties = {
      ...chartProperties,
      textAlignment: newValue || "",
    };
    update
      ? dispatch(
          setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  chartProperties: {
                    ...UpdateObjectData?.ObjectData.data?.result
                      ?.chartProperties,
                    textAlignment: newValue || "",
                  },
                },
              },
            },
          })
        )
      : dispatch(setChartProperties(updatedLegendProperties));
  };
  const handleBoldChange = (newValue) => {
    // setIsBold(newValue);
    const updatedLegendProperties = {
      ...chartProperties,
      bold: newValue === true ? "bold" : "",
    };
    update
      ? dispatch(
          setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  chartProperties: {
                    ...UpdateObjectData?.ObjectData.data?.result
                      ?.chartProperties,
                    bold: newValue === true ? "bold" : "",
                  },
                },
              },
            },
          })
        )
      : dispatch(setChartProperties(updatedLegendProperties));
  };
  const handleItallicChange = (newValue) => {
    setIsItalic(newValue);
    const updatedLegendProperties = {
      ...chartProperties,
      italic: newValue === true ? "italic" : "",
    };
    update
      ? dispatch(
          setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  chartProperties: {
                    ...UpdateObjectData?.ObjectData.data?.result
                      ?.chartProperties,
                    italic: newValue === true ? "italic" : "",
                  },
                },
              },
            },
          })
        )
      : dispatch(setChartProperties(updatedLegendProperties));
  };
  const handleFontChange = (newValue) => {
    const updatedLegendProperties = {
      ...chartProperties,
      font: newValue || "Arial,sans-serif",
    };
    update
      ? dispatch(
          setUpdateObjectData({
            ObjectData: {
              ...UpdateObjectData.ObjectData,
              data: {
                ...UpdateObjectData?.ObjectData.data,
                result: {
                  ...UpdateObjectData?.ObjectData.data?.result,
                  chartProperties: {
                    ...UpdateObjectData?.ObjectData.data?.result
                      ?.chartProperties,
                    font: newValue || "Arial,sans-serif",
                  },
                },
              },
            },
          })
        )
      : dispatch(setChartProperties(updatedLegendProperties));
  };
  useEffect(() => {
    if (update !== true) {
      dispatch(
        setChartProperties({
          ...chartProperties,
          chartColor: "#5470C6",
        })
      );
    }
  }, []);

  const RoundOffOption = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const data = update
    ? UpdateObjectData?.ObjectResponseData?.rowData
    : //  updateQueryResponse?.Response?.data?.data?.result || []
      // : queryResponse?.data?.data?.result || [];
      QueryResponseData?.rowData;
  // const updatdeoption=UpdateObjectData
  // console.log(updatdeoption.ObjectResponseData.rowData,"mohannnnnnnnn");
  console.log(updateQueryResponse, "updateQueryResponseupdateQueryResponse");
  console.log(
    QueryResponseData?.rowData,
    "queryResponsequeryResponsequeryResponsequeryResponsequeryResponse"
  );
  // const keys = data.length > 0 ? Object.keys(data[0]) : [];
  //   const stringKeys = keys.filter(key => typeof data[0][key] === 'string');
  // console.log(stringKeys,'stringKeys');
  const transformedData = transformData(data);
  console.log(transformedData, "transformedDatatransformedDatatransformedData");
  const xAxisOptions = Object.keys(transformedData).map((key) => ({
    key,
    values: transformedData[key],
  }));
  console.log(xAxisOptions, "xAxisOptionsxAxisOptionsxAxisOptions");
  const currentXAxisValue = update
    ? xAxisOptions.find(
        (option) =>
          option.key ===
          UpdateObjectData?.ObjectData?.data?.result?.chartProperties?.xAxis
            ?.key
      ) || null
    : xAxisOptions.find(
        (option) => option.key === chartProperties?.xAxis?.key
      ) || null;

  return (
    <Box
      sx={{
        p: 1,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        width: "100%",
        gap: "20px",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <FormControlLabel
          disabled={
            AskQuestionData.chartType === "pie" ||
            AskQuestionData.chartType === "TreeMap"
          }
          control={
            <Checkbox
              checked={
                update
                  ? UpdateObjectData?.ObjectData?.data.result.chartProperties
                      .VerticalScroll
                  : chartProperties?.VerticalScroll
              }
              onChange={(e) =>
                update
                  ? dispatch(
                      setUpdateObjectData({
                        ObjectData: {
                          ...UpdateObjectData.ObjectData,
                          data: {
                            ...UpdateObjectData?.ObjectData.data,
                            result: {
                              ...UpdateObjectData?.ObjectData.data?.result,
                              chartProperties: {
                                ...UpdateObjectData?.ObjectData.data?.result
                                  ?.chartProperties,
                                VerticalScroll: e.target.checked,
                              },
                            },
                          },
                        },
                      })
                    )
                  : dispatch(
                      setChartProperties({
                        ...chartProperties,
                        VerticalScroll: e.target.checked,
                      })
                    )
              }
            />
          }
          label="Vertical Scroll"
        />
        <br />
        <FormControlLabel
          disabled={
            AskQuestionData.chartType === "pie" ||
            AskQuestionData.chartType === "TreeMap"
          }
          control={
            <Checkbox
              checked={
                update
                  ? UpdateObjectData?.ObjectData?.data.result.chartProperties
                      .HorizontalScroll
                  : chartProperties?.HorizontalScroll
              }
              onChange={(e) =>
                dispatch(
                  update
                    ? setUpdateObjectData({
                        ObjectData: {
                          ...UpdateObjectData.ObjectData,
                          data: {
                            ...UpdateObjectData?.ObjectData.data,
                            result: {
                              ...UpdateObjectData?.ObjectData.data?.result,
                              chartProperties: {
                                ...UpdateObjectData?.ObjectData.data?.result
                                  ?.chartProperties,
                                HorizontalScroll: e.target.checked,
                              },
                            },
                          },
                        },
                      })
                    : setChartProperties({
                        ...chartProperties,
                        HorizontalScroll: e.target.checked,
                      })
                )
              }
            />
          }
          label="Horizontal Scroll"
        />
        <br />
        <Box sx={{ width: "100%" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  update
                    ? UpdateObjectData?.ObjectData?.data.result.chartProperties
                        .DataLabel
                    : chartProperties?.DataLabel
                }
                onChange={(e) =>
                  dispatch(
                    update
                      ? setUpdateObjectData({
                          ObjectData: {
                            ...UpdateObjectData.ObjectData,
                            data: {
                              ...UpdateObjectData?.ObjectData.data,
                              result: {
                                ...UpdateObjectData?.ObjectData.data?.result,
                                chartProperties: {
                                  ...UpdateObjectData?.ObjectData.data?.result
                                    ?.chartProperties,
                                  DataLabel: e.target.checked,
                                },
                              },
                            },
                          },
                        })
                      : setChartProperties({
                          ...chartProperties,
                          DataLabel: e.target.checked,
                        })
                  )
                }
              />
            }
            label="Data Label"
          />
        </Box>
        <br />
        {/* <Box
          sx={{
            display:
              AskQuestionData.chartType === "pie" ||
              AskQuestionData.chartType === "TreeMap" ||
              AskQuestionData.chartType === "StackLine" ||
              AskQuestionData.chartType === "StackBar" ||
              AskQuestionData.chartType === "ClusterBar"
                ? "none"
                : "flex",
            alignItems: "center",
            // justifyContent: "space-around",
          }}
        >
          <Typography>Chart Color</Typography>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <ColorPicker
              color={
                update
                  ? UpdateObjectData?.ObjectData?.data.result.chartProperties
                      .chartColor
                  : chartProperties.chartColor
              }
              handleColorChange={handleColorChange}
            />
          </Menu>
          <Menu
            anchorEl={anchorElSecond}
            open={openSecond}
            onClose={handleCloseSecond}
          >
            <ColorPicker
              color={
                update
                  ? UpdateObjectData?.ObjectData?.data.result.chartProperties
                      .chartColorSecond
                  : chartProperties.chartColorSecond
              }
              handleColorChange={handleColorChangeSecond}
            />
          </Menu>
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: update
                ? UpdateObjectData?.ObjectData?.data.result.chartProperties
                    .chartColor
                : chartProperties.chartColor,
              marginLeft: "20px",
            }}
            onClick={handleClick}
          ></Box>
          {numberOfProperties === 3 ? (
            <Box
              sx={{
                width: 20,
                height: 20,
                backgroundColor: update
                  ? UpdateObjectData?.ObjectData?.data.result.chartProperties
                      .chartColorSecond
                  : chartProperties.chartColorSecond,
                marginLeft: "20px",
              }}
              onClick={handleClickSecond}
            ></Box>
          ) : (
            ""
          )}
        </Box> */}
        {AskQuestionData.chartType === "TreeMap" ||
        AskQuestionData.chartType === "pie" ? (
          ""
        ) : (
          <br />
        )}
        <Box
          sx={{
            width: "100%",
            display:
              AskQuestionData.chartType === "TreeMap" ||
              AskQuestionData.chartType === "pie"
                ? "none"
                : "block",
          }}
        >
          <Typography>X Label</Typography>
          <TextField
            disabled={
              AskQuestionData.chartType === "pie" ||
              AskQuestionData.chartType === "TreeMap"
            }
            id="outlined-basic"
            variant="outlined"
            size="small"
            placeholder="X Label"
            value={
              update
                ? UpdateObjectData?.ObjectData?.data.result.chartProperties
                    .xLable
                : chartProperties.xLable
            }
            onChange={(e) => {
              dispatch(
                update
                  ? setUpdateObjectData({
                      ObjectData: {
                        ...UpdateObjectData.ObjectData,
                        data: {
                          ...UpdateObjectData?.ObjectData.data,
                          result: {
                            ...UpdateObjectData?.ObjectData.data?.result,
                            chartProperties: {
                              ...UpdateObjectData?.ObjectData.data?.result
                                ?.chartProperties,
                              xLable: e.target.value,
                            },
                          },
                        },
                      },
                    })
                  : setChartProperties({
                      ...chartProperties,
                      xLable: e.target.value,
                    })
              );
            }}
            inputProps={{
              maxLength: 30,
            }}
          />
        </Box>
        {AskQuestionData.chartType === "TreeMap" ||
        AskQuestionData.chartType === "pie" ? (
          ""
        ) : (
          <br />
        )}
        <Box
          sx={{
            width: "100%",
            display:
              AskQuestionData.chartType === "TreeMap" ||
              AskQuestionData.chartType === "pie"
                ? "none"
                : "block",
          }}
        >
          <Typography>Y Label</Typography>
          <TextField
            disabled={
              AskQuestionData.chartType === "pie" ||
              AskQuestionData.chartType === "TreeMap"
            }
            id="outlined-basic"
            variant="outlined"
            size="small"
            placeholder="Y Label"
            value={
              update
                ? UpdateObjectData?.ObjectData?.data.result.chartProperties
                    .yLable
                : chartProperties.yLable
            }
            onChange={(e) => {
              dispatch(
                update
                  ? setUpdateObjectData({
                      ObjectData: {
                        ...UpdateObjectData.ObjectData,
                        data: {
                          ...UpdateObjectData?.ObjectData.data,
                          result: {
                            ...UpdateObjectData?.ObjectData.data?.result,
                            chartProperties: {
                              ...UpdateObjectData?.ObjectData.data?.result
                                ?.chartProperties,
                              yLable: e.target.value,
                            },
                          },
                        },
                      },
                    })
                  : setChartProperties({
                      ...chartProperties,
                      yLable: e.target.value,
                    })
              );
            }}
            inputProps={{
              maxLength: 30,
            }}
          />
        </Box>
        {AskQuestionData.chartType === "TreeMap" ||
        AskQuestionData.chartType === "pie" ? (
          ""
        ) : (
          <br />
        )}
        <Box
          sx={{
            width: "100%",
            display:
              AskQuestionData.chartType === "TreeMap" ||
              AskQuestionData.chartType === "pie"
                ? "none"
                : "block",
          }}
        >
          <Typography>X Axis Value</Typography>
          {/* {console.log(update, "update")}
          {console.log( UpdateObjectData.ObjectData.data.result.chartProperties.xAxis,"UpdateObjectDataUpdateObjectData")} */}
          {/* 
          alignmentOptions.find(
                    (option) =>
                      option.value ===
                      UpdateObjectData?.ObjectData?.data.result.chartProperties
                        .textAlignment
                  )
           */}
          <Autocomplete
            // value={
            //   update
            //     ? UpdateObjectData?.ObjectData?.data?.result?.chartProperties
            //         ?.xAxis?.key
            //     : chartProperties?.xAxis?.values
            // }
            // value={
            //   update
            //     ? UpdateObjectData?.ObjectData?.data?.result?.chartProperties?.xAxis?.key || ''
            //     : chartProperties?.xAxis?.key || ''
            // }f
            value={currentXAxisValue}
            disabled={
              AskQuestionData.chartType === "pie" ||
              AskQuestionData.chartType === "TreeMap"
            }
            // options={Object.keys(transformedData).map((key) => ({
            //   key,
            //   values: transformedData[key],
            // }))}
            options={xAxisOptions}
            getOptionLabel={(option) => `${option.key}`}
            // value={selectedXkey}
            onChange={(e, newValue) => {
              // setSelectedXkey(newValue);
              // debugger;

              dispatch(
                update
                  ? setUpdateObjectData({
                      ObjectData: {
                        ...UpdateObjectData.ObjectData,
                        data: {
                          ...UpdateObjectData?.ObjectData.data,
                          result: {
                            ...UpdateObjectData?.ObjectData.data?.result,
                            chartProperties: {
                              ...UpdateObjectData?.ObjectData.data?.result
                                ?.chartProperties,
                              xAxis: newValue,
                            },
                          },
                        },
                      },
                    })
                  : setChartProperties({
                      ...chartProperties,
                      xAxis: newValue,
                    })
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                placeholder="X Axis Value"
              />
            )}
          />
        </Box>

        <br />
        {/* {numberOfProperties !== 3 && ( */}
        {/* <Box
              sx={{
                width: "100%",
                display:
                  AskQuestionData.chartType === "TreeMap" ||
                  AskQuestionData.chartType === "pie"
                    ? "none"
                    : "block",
              }}
            >
              <Typography>Y Axis Value</Typography>
              <Autocomplete
                disabled={
                  AskQuestionData.chartType === "pie" ||
                  AskQuestionData.chartType === "TreeMap"
                }
                options={keys}
                getOptionLabel={(option) => option}
                value={selectedYkey}
                onChange={(event, newValue) => {
                  setSelectedYkey(newValue);
                  dispatch(
                    update
                      ? setUpdateObjectData({
                          ObjectData: {
                            ...UpdateObjectData.ObjectData,
                            data: {
                              ...UpdateObjectData.ObjectData.data,
                              result: {
                                ...UpdateObjectData.ObjectData.data.result,
                                chartProperties: {
                                  ...UpdateObjectData.ObjectData.data.result
                                    .chartProperties,
                                  yAxis: newValue,
                                },
                              },
                            },
                          },
                        })
                      : setChartProperties({
                          ...chartProperties,
                          yAxis: newValue,
                        })
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    placeholder="Y Axis Value"
                  />
                )}
              />
            </Box> */}
        {/* )} */}

        <br />
        <Box
          sx={{
            width: "100%",
            display:
              AskQuestionData.chartType === "TreeMap" ||
              AskQuestionData.chartType === "pie"
                ? "none"
                : "block",
          }}
        >
          <Typography>Round Off</Typography>
          <Autocomplete
            disabled={
              AskQuestionData.chartType === "pie" ||
              AskQuestionData.chartType === "TreeMap"
            }
            size="small"
            options={RoundOffOption}
            getOptionLabel={(option) => option.label}
            value={
              update
                ? RoundOffOption.find(
                    (option) =>
                      option.value ===
                      UpdateObjectData?.ObjectData?.data.result.chartProperties
                        .roundOff
                  )
                : RoundOffOption.find(
                    (option) => option.value === chartProperties.roundOff
                  ) || null
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Value" />
            )}
            onChange={(event, newValue) => {
              debugger;
              if (newValue) {
                dispatch(
                  update
                    ? setUpdateObjectData({
                        ObjectData: {
                          ...UpdateObjectData.ObjectData,
                          data: {
                            ...UpdateObjectData?.ObjectData.data,
                            result: {
                              ...UpdateObjectData?.ObjectData.data?.result,
                              chartProperties: {
                                ...UpdateObjectData?.ObjectData.data?.result
                                  ?.chartProperties,
                                roundOff: newValue.value,
                              },
                            },
                          },
                        },
                      })
                    : setChartProperties({
                        ...chartProperties,
                        roundOff: newValue.value,
                      })
                );
              } else {
                dispatch(
                  update
                    ? setUpdateObjectData({
                        ObjectData: {
                          ...UpdateObjectData.ObjectData,
                          data: {
                            ...UpdateObjectData?.ObjectData.data,
                            result: {
                              ...UpdateObjectData?.ObjectData.data?.result,
                              chartProperties: {
                                ...UpdateObjectData?.ObjectData.data?.result
                                  ?.chartProperties,
                                roundOff: null,
                              },
                            },
                          },
                        },
                      })
                    : setChartProperties({
                        ...chartProperties,
                        roundOff: null,
                      })
                );
              }
            }}
          />
        </Box>
      </Box>
      <Box sx={{ width: "100%", borderTop: "1px solid gray" }}>
        <Box
          sx={{
            border: "1px solid #DADADA",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Typography
            sx={{
              color: "#606060",
              fontSize: "16px",
              fontWeight: "600",
              p: 1,
            }}
          >
            Legend Properties
          </Typography>
        </Box>
        <Box sx={{ width: "100%", mt: 2 }}>
          <Typography>Legend Font Size</Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Slider
                max={"30"}
                value={
                  update
                    ? UpdateObjectData?.ObjectData?.data.result.chartProperties
                        .fontSize
                    : chartProperties.fontSize
                }
                onChange={handleSliderChange}
                aria-labelledby="input-slider"
              />
            </Grid>
            <Grid item>
              <Input
                value={
                  update
                    ? UpdateObjectData?.ObjectData?.data.result.chartProperties
                        .fontSize
                    : chartProperties.fontSize
                }
                size="small"
                onChange={(e) => {
                  console.log(e.target.value);
                  update
                    ? dispatch(
                        setUpdateObjectData({
                          ObjectData: {
                            ...UpdateObjectData.ObjectData,
                            data: {
                              ...UpdateObjectData?.ObjectData.data,
                              result: {
                                ...UpdateObjectData?.ObjectData.data?.result,
                                chartProperties: {
                                  ...UpdateObjectData?.ObjectData.data?.result
                                    ?.chartProperties,
                                  fontSize: e.target.value,
                                },
                              },
                            },
                          },
                        })
                      )
                    : dispatch(
                        setChartProperties({
                          ...chartProperties,
                          fontSize: e.target.value,
                        })
                      );
                }}
                onBlur={handleBlur}
                inputProps={{
                  step: 2,
                  min: 0,
                  max: 30,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "100%", mt: 2 }}>
          <Typography>Legend Alignment</Typography>
          <Autocomplete
            size="small"
            options={alignmentOptions}
            value={
              update
                ? alignmentOptions.find(
                    (option) =>
                      option.value ===
                      UpdateObjectData?.ObjectData?.data.result.chartProperties
                        .textAlignment
                  )
                : alignmentOptions.find(
                    (option) => option.label === chartProperties.textAlignment
                  )
            }
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} />}
            onChange={(event, newValue) => {
              if (newValue) {
                handleAlignmentChange(newValue.value);
              } else {
                handleAlignmentChange(null);
              }
            }}
          />
        </Box>
        {update ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "8px",
              flexWrap: "wrap",
              mt: 2,
            }}
          >
            <Box>
              <Button
                variant={
                  UpdateObjectData?.ObjectData?.data.result.chartProperties
                    ?.bold
                    ? "contained"
                    : "outlined"
                }
                onClick={() =>
                  handleBoldChange(
                    !UpdateObjectData?.ObjectData?.data.result.chartProperties
                      ?.bold
                  )
                }
              >
                B
              </Button>
            </Box>
            <Box>
              <Button
                variant={
                  UpdateObjectData?.ObjectData?.data.result.chartProperties
                    ?.italic
                    ? "contained"
                    : "outlined"
                }
                onClick={() =>
                  handleItallicChange(
                    !UpdateObjectData?.ObjectData?.data.result.chartProperties
                      .italic
                  )
                }
              >
                I
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "8px",
              flexWrap: "wrap",
              mt: 2,
            }}
          >
            <Box>
              <Button
                variant={chartProperties?.bold ? "contained" : "outlined"}
                onClick={() => handleBoldChange(!chartProperties?.bold)}
              >
                B
              </Button>
            </Box>
            <Box>
              <Button
                variant={
                  isItalic || chartProperties?.italic ? "contained" : "outlined"
                }
                onClick={() => handleItallicChange(!isItalic)}
              >
                I
              </Button>
            </Box>
          </Box>
        )}
        <Box sx={{ width: "100%", mt: 2 }}>
          <Typography>Font</Typography>
          <Autocomplete
            size="small"
            options={fontOptions}
            value={
              update
                ? fontOptions.find(
                    (option) =>
                      option.value ===
                      UpdateObjectData?.ObjectData?.data.result.chartProperties
                        .font
                  )
                : fontOptions.find(
                    (option) => option.value === chartProperties.font
                  )
            }
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} />}
            onChange={(event, newValue) => {
              if (newValue) {
                handleFontChange(newValue.value);
              } else {
                handleFontChange(null);
              }
            }}
          />
        </Box>
        <br />
        {/* {chartType !== "StackBar" && chartType !== "StackLine" && !update ? (
          <> */}
        <Box
          sx={{
            display:
              AskQuestionData.chartType === "pie" ||
              AskQuestionData.chartType === "TreeMap" ||
              AskQuestionData.chartType === "StackLine" ||
              AskQuestionData.chartType === "StackBar" ||
              AskQuestionData.chartType === "ClusterBar"
                ? "none"
                : "flex",
            alignItems: "center",
            // justifyContent: "space-around",
          }}
        >
          <Typography>Legend Color</Typography>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <ColorPicker
              color={
                update
                  ? UpdateObjectData?.ObjectData?.data.result.chartProperties
                      .chartColor
                  : chartProperties.chartColor
              }
              handleColorChange={handleColorChange}
            />
          </Menu>
          <Menu
            anchorEl={anchorElSecond}
            open={openSecond}
            onClose={handleCloseSecond}
          >
            <ColorPicker
              color={
                update
                  ? UpdateObjectData?.ObjectData?.data.result.chartProperties
                      .chartColorSecond
                  : chartProperties.chartColorSecond
              }
              handleColorChange={handleColorChangeSecond}
            />
          </Menu>
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: update
                ? UpdateObjectData?.ObjectData?.data.result.chartProperties
                    .chartColor
                : chartProperties.chartColor,
              marginLeft: "20px",
            }}
            onClick={handleClick}
          ></Box>
          {numberOfProperties === 3 ? (
            <Box
              sx={{
                width: 20,
                height: 20,
                backgroundColor: update
                  ? UpdateObjectData?.ObjectData?.data.result.chartProperties
                      .chartColorSecond
                  : chartProperties.chartColorSecond,
                marginLeft: "20px",
              }}
              onClick={handleClickSecond}
            ></Box>
          ) : (
            ""
          )}
        </Box>
        {/* </>
        ) : (
          ""
        )} */}
      </Box>
    </Box>
  );
};

export default ChartProperties;
