import React, { useEffect } from "react";
import SideBar from "./sideBar";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Header from "./Header";
import { setAllCatalog, setAllUsers, setDataBase } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { selectAllUsers, selectSideBarOpen } from "../../redux/selectors";
import { getAllCataloge } from "../../services";
import { getUsers } from "../../services/core";
const MainLayout = () => {
  const sideBarOpen = useSelector(selectSideBarOpen);
  const allUsersData = useSelector(selectAllUsers);

  const dispatch = useDispatch();
  dispatch(setDataBase(""));

  useEffect(() => {
    getAllCatalogeAsync();
    // getUsersAsync();
  }, []);

  const getAllCatalogeAsync = async () => {
    const res = await getAllCataloge();
    dispatch(setAllCatalog(res?.data?.data?.result));
  };

  const getUsersAsync = async () => {
    if (allUsersData?.length === 0) {
      const res = await getUsers();
      dispatch(setAllUsers(res?.data?.data));
    }
  };

  return (
    <Box>
      <Header />
      <SideBar />
      <Box
        sx={{
          marginLeft: { xs: 0, md: sideBarOpen ? "200px" : "100px" },
          marginTop: "80px",
          padding: { xs: "5px 10px", sm: "5px 15px", md: "5px 30px" },
          overflow: "auto",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
export default MainLayout;
